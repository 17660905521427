import React, { createRef, useEffect, useRef, useState } from "react";
import IDialog from "../dialogs";
import Chat from "../../../entities/chat";
import { DialogProps, open } from "../dialogHelpers";
import strings from "../../../services/localizationService";
import User from "../../../entities/user";
import viewModels from "../../../viewModels";
import './index.css'
import HorizontalScrollView from "../../../widgets/horizontal-scroll-view";
import { Close } from "@mui/icons-material";
import { InputWithValidation } from "../../../shared/ui/AppFormHelper";
import FileUploader from "../../../widgets/file-uploader";
import DialogHeader from "../dialog-header";
import ChatSettings from "../../../entities/chatSettings";
import { TextField } from "@mui/material";
let selectedFile: File | undefined = undefined; 
const AddChatDialig : IDialog<Chat | undefined> = {
    show:() => {
        selectedFile = undefined;
        return open((resolve) => <ChatDialogView resolve={resolve.resolve} />);
    }
}
function EditChatDialig(chatId:number):IDialog<Chat | undefined>{
    return {
        show:async () => {
            selectedFile = undefined;
            const settings = await viewModels.chat.chatSettings(chatId);
            return open((resolve) => <ChatDialogView resolve={resolve.resolve} initialState={settings} />);
        }
    }
}

enum Steps{
    AddParticipants,
    CreateChat
}
interface ChatDialogProps extends DialogProps<Chat | undefined>{
    initialState?:ChatSettings
}
function ChatDialogView({resolve, initialState}:ChatDialogProps){
    const [participants, setParticipants] = useState<User[]>([]);
    const [addedParticipants, setAddedParticipants] = useState<User[]>(initialState?.members ?? []);
    const [step, setStep] = useState<Steps>(Steps.AddParticipants);
    const chatImage = useRef<HTMLImageElement>(null);
    const [chatName, setChatName] = useState(initialState?.name ?? '');
    useEffect(() => {
        viewModels.chat.getToAdd(setParticipants);
    }, []);

    return <div className="dialog-view add-chat-dialog">
        <DialogHeader 
        resolve={resolve}
        steps={[{
            canGoFoward:() => step === Steps.AddParticipants && addedParticipants.length > 0,
            onForward:() => setStep(Steps.CreateChat)
        },
    {
        onBackward:() => setStep(Steps.AddParticipants),
        canGoFoward:() => !!chatName && step === Steps.CreateChat,
        onForward:() => {
            viewModels.chat.create({
                name:chatName,
                file:selectedFile,
                chatId:initialState?.id,
                participantsIds:addedParticipants.map(x => x.id)
            }, resolve);
        }
    }]}
        />
        {step === Steps.AddParticipants && <AddParticipantsView
        participants={participants}
        addedParticipants={addedParticipants}
        onAddedParticipentChanged={(item, added) => {
            if(added)
            return setAddedParticipants([...addedParticipants, item]);
            setAddedParticipants(addedParticipants.filter(x => x.id !== item.id));
        }}
         />}
         
         <img className={step !== Steps.CreateChat ? "chat-image" : "chat-image visable"} src={initialState?.picture} ref={chatImage} />
         {step === Steps.CreateChat && <>
         <FileUploader 
         onImageSelected={(image, path) => {
            chatImage.current!.src = path;
            selectedFile = image;
         }}
         title="Chat Image"
         />
         <InputWithValidation 
         header="Chat Name"
         errorMessage=""
         textFieldProps={{value:chatName, onChange:(e) => {setChatName(e.currentTarget.value)} }}
         />
         </>}
    </div>
}

function AddParticipantsView({participants, addedParticipants, onAddedParticipentChanged}:{participants:User[], addedParticipants:User[], onAddedParticipentChanged:(affect:User, added:boolean) => void}){
    
    const [name, setName] = useState('');

    const notAdded = participants.filter(x => !addedParticipants.find(y => y.id === x.id) && (!name || x.name.toLowerCase().includes(name.toLowerCase())));

    return <>
    <TextField value={name} onChange={(e) => setName(e.currentTarget.value)}  variant='outlined' placeholder={strings.SearchMembers}></TextField>
            {addedParticipants.length > 0 && !name && <div>
                <p>{strings.Placeholders.WillParticipate}</p>
                <HorizontalScrollView
            items={addedParticipants.map(x => <div className="added-participent">
                <img src={x.picture ?? require('../../../assets/avatar.png')}  />
                <div className="remove-participant" onClick={() => onAddedParticipentChanged(x, false)}><Close /></div>
            </div>
            )}
            />
                </div>}
            <table>
                <tbody>
                    {notAdded.map(x => <tr key={x.id}><td><div className="chat-participant" onClick={() => onAddedParticipentChanged(x, true)}>
                        <img src={x.picture ?? require('../../../assets/avatar.png')} />
                        <p>{x.name}</p>
                        </div></td></tr>)}
                </tbody>
            </table>
    </>
}

export default {AddChatDialig, EditChatDialig};