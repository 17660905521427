export default{
    en:{
        AddClub: "Add Club",
        noClubs: "There are no clubs that you are administering",
        DayOfWeek: "Day",
        daysOfWeek: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        GamesAgenda: "Events Schedule",
        Type:'Type',
        Scope:'Scope',
        GameType:['Classic', 'Beach'],
        ClubScopes:['Game', 'Training', 'Training and Games'],
        AddGame:"Add Game",
        MemberCode:"Member Code",
        GuestCode:"Guest Code",
        ViewMembers:'View Members',
        CreateGame:"Create Game",
        NewAgenda:"Add new schedule",
        Club:"Club",
        IsPublic:"is public",
        ClubType:"Club Type",
        ClubScope:"Club Scope",
        GuestRegistrationStrategy:"When Guests Can Register?",
        GuestRegistrationStrategies:['By Hours before Start', 'I will decide'],
        FeePerPlayer:"Fee per player",
        FeePerGame:"Fee per game",
        FeeIsFixed:"Fee is fixed",
        SeatsLimited:"Seats for Members are limited?",
        ClubName:"Club Name",
        MaxPlayers:"Max Players:",
        MinPlayers:"Min Players",
        AdministratedClubs:"Administrated Clubs",
        PrivateClubs:"Private Clubs",
        NoPrivateClubs:"You do not belong to any private clubs as member or guest yet",
        RegisterToDefault:"Add Me to Default list for this games",
        RemoveFromDefault:"Remove Me from Default list for this games",
        NoAgenda:"There is no schedule for games",
        GameHaveOtherScope:"Some Games will have other scope(either was modified manually, before changing at club level or other members registered manually when club were set to other scope). You Should update them manually",
        SearchMembers:"Search for participants",
        ClubAccesability:"Events from this club should be visible to everyone?",
        PaymentTypes:["Free", "Fee splitted by registered players", "Fixed fee per player"],
        Fee:"Fee",
        PlayersMinLevel:"Players Min Level",
        OtherGames:"Other Events",
        FeeType:"Fee Type",
        FeeTypes:["By Event", "By hour"],
        WhenRegularsCanRegister:"When Regulars can register?",
        RegularsRegistrationTypes:["Anytime", "By Hours before Start"],
        FeeVariates:"At the maximum number of participants the fee will be",
        TelegramFilterOptions:["Show All Profiles", "Show only Telegram Profiles"],
        CanOtherInviteGuests:"Can others beside admins to register guests?",
        InviteLimit:"Limit of invitations",
        InviteLimitTitle:"How many can invite?",
        GamesFilterOptions:["Show All Events", "Show only Events in which I can register"],
    },
    ro:{
        AddClub: "Adaugă club",
        noClubs: "Nu administrați nici un club",
        DayOfWeek: "Ziua",
        daysOfWeek: ["Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sîmbătă"],
        GamesAgenda: "Programul evenimentelor",
        Type:'Tipul',
        Scope:'Scopul',
        GameType:['Classic', 'Plaja'],
        ClubScopes:['Joc', 'Antranament', 'Antranament și Joc'],
        MaxPlayers:"Maximum de jucători",
        MinPlayers:"Minimum de jucători",
        AddGame:"Aduagă Joc",
        MemberCode:"Cod Membru",
        GuestCode:"Cod Invitat",
        ViewMembers:'Vezi Membri',
        CreateGame:"Crează Joc",
        NewAgenda:"Adăugați un program nou",
        Club:"Club",
        IsPublic:"este public",
        ClubType:"Tipul Clubului",
        ClubScope:"Scopul Clubului",
        GuestRegistrationStrategy:"Când invitații se pot înregistra?",
        GuestRegistrationStrategies:['Cu ore înainte de începere', 'Eu voi decide'],
        FeePerPlayer:"Taxa per jucător",
        FeePerGame:"Taxa per joc",
        FeeIsFixed:"Taxa este fixă",
        SeatsLimited:"Locurile pentru membri sunt limitate?",
        ClubName:"Denumirea clubului",
        AdministratedClubs:"Cluburi administrate",
         PrivateClubs: "Cluburi private",
         NoPrivateClubs: "Încă nu aparţine niciunui club privat ca membru ori invitat",
         RegisterToDefault: "Adăugați-mă la lista implicită pentru aceste jocuri",
         RemoveFromDefault:"Eliminați-mă din lista implicită pentru aceste jocuri",
         NoAgenda:"Nu există un program pentru jocuri",
         GameHaveOtherScope:"Unele jocuri vor avea alt scop (fie au fost modificate manual, înainte de a fi modificate la nivel de club sau alți membri s-au înregistrat manual când clubul a fost setat cu alt scop). Trebuie să le actualizați manual.",
         SearchMembers:"Căutați participanți",
         ClubAccesability:"Evenimentele din acest club ar trebui să fie vizibile pentru toată lumea?",
         PaymentTypes:["Grătuit", "Taxa împărțită de jucătorii înregistrați", "Taxă fixă per jucător"],
        Fee:"Taxa",
        PlayersMinLevel:"Nivelul minim al jucătorilor",
        OtherGames:"Alte evenimente",
        FeeType:"Tipul Taxei",
        FeeTypes:["Per eveniment", "Per oră"],
        WhenRegularsCanRegister:"Când se pot înregistra regularii?",
        RegularsRegistrationTypes:["Oricând", "Cu ore înainte de începere"],
        FeeVariates:"La numărul maxim de participanți taxa va fi",
        TelegramFilterOptions:["Afișează toate Profilele", "Afișează doar Profile din Telegram"],
        CanOtherInviteGuests:"Pot alții, în afară de administratori, să înregistreze invitați?",
        InviteLimit:"Limita de invitatii",
        InviteLimitTitle:"Câți poate invita?",
        GamesFilterOptions:["Afișează toate Evenimentele", "Afișează doar Evenimentele în care mă pot înregistra"]
    },
    ru:{
        AddClub: "Добавь клуб",
        noClubs: "Нет клубов, которыми вы управляете",
        DayOfWeek: "День",
        daysOfWeek: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        Type:'Тип',
        Scope:'Цель',
        GameType:['Классический', 'Пляжный'],
        ClubScopes:['Игра', 'Тренировка', 'Тренировка и Игра'],
        MaxPlayers:"Максимум игроков",
        MinPlayers:"Минимум игроков",
        AddGame:"Добавить игру",
        MemberCode:"Код участника",
        GuestCode:"Гостевой код",
        ViewMembers:'Посмотреть участников',
        CreateGame:"Создать игру",
        NewAgenda:"Добавить новое расписание",
        Club:"Клуб",
        IsPublic:"публичный",
        ClubType:"Тип клуба",
        ClubScope:"Цель Клуба",
        GuestRegistrationStrategy:"Когда гости могут зарегистрироваться?",
        GuestRegistrationStrategies:['По часам до начала', 'я решу'],
        FeePerPlayer:"Плата на одного игрока",
        FeePerGame:"Плата за игру",
        FeeIsFixed:"Плата фиксированная",
        SeatsLimited:"Количество мест для участников ограничено?",
        ClubName:"Название клуба",
        GamesAgenda: "Pасписания событий",
        AdministratedClubs:"Управляемые клубы",
        PrivateClubs:"Частные клубы",
        NoPrivateClubs: "Вы еще не состоитe ни в одном частном клубе в качестве члена или гостя.",
        RegisterToDefault:"Добавьте меня в список по умолчанию для этих игры",
        RemoveFromDefault:"Удалите меня из списка по умолчанию для этих игры",
        NoAgenda:"Нет расписания игр",
        GameHaveOtherScope:"Некоторые игры будут иметь другую цель (либо они были изменены вручную, прежде чем вносить изменения на уровне клуба или другие участники, зарегистрированные вручную, когда клуб был настроен с другой целию). Вы должны обновить их вручную",
        SearchMembers:"Поиск участников",
        ClubAccesability:"Мероприятия этого клуба должны быть видны всем?",
        PaymentTypes:["Бесплатно", "Плату делить между зарегистрированными игроками", "Фиксированная плата за игрока"],
        Fee:"Плата",
        PlayersMinLevel:"Минимальный уровень игроков",
        OtherGames:"Другие события",
        FeeType:"Тип Платежа",
        FeeTypes:["за мероприятие", "за час"],
        WhenRegularsCanRegister:"Когда члены смогут зарегистрироваться?",
        RegularsRegistrationTypes:["В любое время", "По часам до начала"],
        FeeVariates:"При максимальном количестве участников стоимость составит",
        TelegramFilterOptions:["Показать все профили", "Показать только профили Telegram"],
        CanOtherInviteGuests:"Могут ли другие люди, кроме администраторов, регистрировать гостей?",
        InviteLimit:"Лимит приглашений",
        InviteLimitTitle:"Скольких он может пригласить?",
        GamesFilterOptions:["Показать все события", "Показать только события, в которых я могу  зарегистрироваться"]
    }
}