
import authServices from '../../../services/authService';
import apiRequest from '../../../shared/apiRequest';
import { DialogResult } from '../dialog';

export interface ResetPasswordProps{
    email:string
}
async function onOk(model:ResetPasswordProps){
    let result:DialogResult<boolean> = {succeeded:false};
    await apiRequest.execute(() => authServices.reguestResetPassword(model.email), () => result = {succeeded:true, result:true});
    return result;
}
export default {onOk};