import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { GameData } from "../../shared/eventArgs";
import Game from "../../entities/game";
import cacheManager from "../../cacheManager";
import dialogs from "../../components/dialogs";
import { AdjustType, GameRotation, PlayerStatus, Positions, Skills } from "../../entities/enums";
import Team from "../../entities/team";
import TeamMember from "../../entities/teamMember";
import GetTeam from "../../models/getTeamModel";
import apiRequest from "../../shared/apiRequest";
import teamService from "../../services/teamService";
import { Button, Typography } from "@mui/material";
import { show } from "../../dialogs/ui/suggested-teams/team-level";
import strings from "../../services/localizationService";
import { InputWithValidation } from "../../shared/ui/AppFormHelper";
import SectionView from "../../widgets/section-view";
import { TeamMemberView } from "../../features/player-view";
import './index.css';
import AdjustContext from "../../context/adjustContext";
import { toast } from "react-toastify";
import Skill from "../../entities/skill";
import { Check, CheckBox } from "@mui/icons-material";
import { routers } from "../../constants";
import { milisecondsInSecond } from "../../widgets/count-down";
import SuggestedTeamsModel from "../../models/suggestedTeamsModel";

const positionsNames = ['S', 'OH', 'MB', 'RH', 'L'];
const positionsLimit = [{
    position:Positions.Setter,
    rotationLimit:[{
        rotation:GameRotation.Rotation_4_2,
        limit:2
    },
    {
        rotation:GameRotation.Rotation_5_1,
        limit:1
    }
]
},
{
    position:Positions.OutsideHitter,
    rotationLimit:[{
        rotation:GameRotation.Rotation_4_2,
        limit:4
    },
    {
        rotation:GameRotation.Rotation_5_1,
        limit:2
    }
]
}
,
{
    position:Positions.MiddleBlocker,
    rotationLimit:[
    {
        rotation:GameRotation.Rotation_5_1,
        limit:2
    }
]
},
{
    position:Positions.OppositeHitter,
    rotationLimit:[
    {
        rotation:GameRotation.Rotation_5_1,
        limit:1
    }
]
},
{
    position:Positions.Libero,
    rotationLimit:[
    {
        rotation:GameRotation.Rotation_5_1,
        limit:1
    }
]
}
]
function SuggestedTeamsPage(){
    const [seearchParams] = useSearchParams();
    const [suggestedTeamsModel, setSuggesedTeamsModel] = useState<SuggestedTeamsModel>();
    useEffect(() =>{
        loadGame();
    }, []);
    const loadGame = async () => {
        const games = (await cacheManager.getCacheData("game", "/games")) as Game[];
        if(!games) return window.location.replace(routers.games);
        const clubId = seearchParams.get("clubId");
        let date = seearchParams.get("date");
        const start = seearchParams.get("start");
        if(!clubId || !date || !start) return window.location.replace(routers.games);
        date = date.replace(' ', '+')
        const foundeGame = games.find(x => x.clubId == +clubId! && x.date == date && x.start == start);
        if(!foundeGame) return window.location.replace(routers.games);
        const model = (await cacheManager.getCacheData("game", `/team_${foundeGame.clubId}_${foundeGame.date}_${foundeGame.start}`)) as SuggestedTeamsModel
        if(!model) return window.location.replace(routers.games);
        setSuggesedTeamsModel(model);
    }
    return <div>
{suggestedTeamsModel && <SuggestedTeamsView state={suggestedTeamsModel} />}
    </div>
}

function SuggestedTeamsView({state}:{state:SuggestedTeamsModel}){
    const [teams, setTeams] = useState<Team[]>([]);
    const [positions, setPositions] = useState<Positions[]>([]);
    const [adjust, setAdjust] = useState(false);
    const [changeMember, setChangeMember] = useState<TeamMember>();
    const [havePrevioudAdjustment, setHavePrevioudAdjustment] = useState(false);
    const onReplace = (item:TeamMember) =>{
        const team1 = teams.find(x => x.members.find(y => y === item));
        if(!team1) return;
        const team2 = teams.find(x => x.members.find(y => y === changeMember));

        if(!team2) return;
        if(team1 == team2){
            const changeToPosition = item.position;
            item.position = changeMember!.position;
            changeMember!.position = changeToPosition;
            compliteChange();
            return;
        }
        if(positions.length > 0 && item.position !== changeMember!.position)
            return toast.error(strings.SamePositions);
        const teamId = changeMember!.team;
        changeMember!.team = item.team;
        item.team = teamId;
        team1.members = team1.members.map(x => x !== item ? x : changeMember!);
        recalculateTeamLevel(team1);
        team2.members = team2.members.map(x => x !== changeMember ? x : item);
        recalculateTeamLevel(team2);
        compliteChange();
    }
    const recalculateTeamLevel = (team:Team) =>{
        const skills = Object.values(Skills).filter(x => typeof(x) === "number")
        .map(x => {
            const result:Skill = {
                id:x as number,
                skill:x as number,
                level:0
            }
            return result
        });

        console.log(skills);
        team.members.forEach(x => {
            x.skills.forEach(y =>{
                const skill = skills.find(z => z.skill === y.skill)!;
                skill.level += y.level;
            })
        })
        
        let teamLevel = 0;
        skills.forEach(x =>{
            x.level = Math.round((x.level/ team.members.length + Number.EPSILON) * 100) / 100;
            teamLevel += x.level;
        })
        team.skills = skills;
        team.teamLevel = Math.round((teamLevel / skills.length + Number.EPSILON) * 100) / 100;
    }
    const compliteChange = () => {
        setTeams([...teams]);
        setChangeMember(undefined);
    }

    useEffect(() => {
        initiated(state);
    }, []);
    const initiated = async (result:{teams:Team[], positions:Positions[]}) =>{
        setTeams(result.teams);
        setPositions(result.positions);
        const data = (await cacheManager.getCacheData("game", `/suggestedTeams/team_${state.clubId}_${state.date}_${state.start}`)) as Team[];
        console.log(data);
        if(!data) return;
        let allNewMembers:TeamMember[] = [];
        result.teams.forEach(x =>{
            allNewMembers = [...allNewMembers, ...x.members]
        })
        let allOldMember:TeamMember[] = [];
        data.forEach(x =>{
            allOldMember = [...allOldMember, ...x.members]
        })
        if(allNewMembers.length !== allOldMember.length) return;
        const membersChanged = allNewMembers.find(x => allOldMember.find(y => y.playerId === x.playerId && y.status === x.status) === undefined)
        if(membersChanged) return;
        let evaluationChange = false;
        allNewMembers.forEach(x =>{
            const oldMember = allOldMember.find(y => y.playerId === x.playerId && y.status === x.status);
            if(!oldMember){
                evaluationChange = true;
                return;
            }
            x.skills.forEach(y =>{
                const oldSkill = oldMember.skills.find(z => z.skill === y.skill);
                if(!oldSkill){
                    evaluationChange = true;
                    return;
                }
                evaluationChange = y.level !== oldSkill.level;
                if(evaluationChange) return;
            })
        })
        if(evaluationChange) return;
        setHavePrevioudAdjustment(true);
    }
const canAddToTeam = (team:Team) =>{
    if(!adjust) return false;
    if(!changeMember) return false;
    if(changeMember.team === team.id) return false;
    const currentTeam = teams.find(x => x.members.find(y => y === changeMember) != null);
    if(!currentTeam) return false;
    if(currentTeam.members.length <= team.members.length) return false;
    if(state.rotation === GameRotation.NoSpecificPositions) return true;
    if(team.members.length === team.playersPerTeam) return false;
    const positionLimit = positionsLimit.find(x => x.position === changeMember.position)?.rotationLimit.find(x => x.rotation == state.rotation)?.limit;
    if(!positionLimit) return false;
    const ocupated = team.members.filter(x => x.position == changeMember.position).length;
    if(ocupated === positionLimit) return false;
    return true;
}

const storeTeams = async () =>{
    await cacheManager.storeCacheData("game", `/suggestedTeams/team_${state.clubId}_${state.date}_${state.start}`, teams);
    const confirm = await dialogs.Confirmation(strings.Confirmations.SendList);
    if(!confirm) return;
    apiRequest.execute(() => teamService.teamList({
        ...state,
        suggestedTeams:teams
    }))
}
const addToTeam = (team:Team) =>{
    if(!changeMember) return;
    const currentTeam = teams.find(x => x.members.find(y => y === changeMember) != null);
    if(!currentTeam) return;
    currentTeam.members = currentTeam.members.filter(x => x !== changeMember);
    changeMember.team =team.id;
    team.members = [...team.members, changeMember];
    recalculateTeamLevel(currentTeam);
    recalculateTeamLevel(team);
    compliteChange();

}
const setPreviousAdjutments = async () => {
    const data = (await cacheManager.getCacheData("game", `/suggestedTeams/team_${state.clubId}_${state.date}_${state.start}`)) as Team[];
    setTeams(data);
    setHavePrevioudAdjustment(false);
}
    return <AdjustContext.Provider value={{adjust,canAddToTeam, addToTeam, setAdjust, onReplace, setChangeMember, changeMember, storeTeams, hasPreviosAdjustment:havePrevioudAdjustment}}>
<SuggestedTeamsContainer teams={teams} positions={positions} isAdmin={state.requestedPlayerStatus === PlayerStatus.Administrator} setPreviousAdjutments={setPreviousAdjutments} />
    </AdjustContext.Provider> 
}

function SuggestedTeamsContainer({teams, positions, isAdmin, setPreviousAdjutments}:{teams:Team[], positions:Positions[], isAdmin:boolean, setPreviousAdjutments:() => void}){
    const{setAdjust, adjust, storeTeams, hasPreviosAdjustment} = useContext(AdjustContext);
    const onAdjust = async () =>{
        if(!adjust) return setAdjust(true);
        setAdjust(false);
        storeTeams();
    }
return <div className='suggestedTeams-container'>
    <div className='buttons'>
    <Button color='primary' variant='contained' onClick={() =>{show(teams)} }>{strings.Volleyball.ViewTeamsLevel}</Button>

    {hasPreviosAdjustment && <Button color='primary' variant="contained" onClick={setPreviousAdjutments}>{strings.Placeholders.ViewPreviosAdjument}</Button> }
{isAdmin && <Button color='primary' variant={adjust ? "outlined" : "contained"} onClick={onAdjust}>{!adjust ? strings.Adjust : <Check />}</Button> }
    </div>
{positions.length > 0 &&<><Typography style={{marginLeft:30}}>{strings.Volleyball.PositionsLabel}</Typography>
<div className='team-position-container'>
    {positions.map(x => <PositionColorLegend key={x} position={x} />)}
</div>
</>}
<div className='team-view-container'>
    

    {teams.map(x => <TeamView key={x.id} team={x}/>)}
</div></div>
}

function PositionColorLegend({position}:{position:Positions}){
    
    return <Typography>{`${strings.Volleyball.Positions[position]} - ${positionsNames[position]}`}</Typography>
}

function TeamView({team}:{team:Team}){
    const {id} = team;
    const {adjust, changeMember, canAddToTeam, addToTeam} = useContext(AdjustContext);
    const [teamName, setTeamName] = useState(`${strings.Volleyball.Team} ${id}`);
    return <>
    <div className="team-adjust">
    {adjust && <InputWithValidation errorMessage='' textFieldProps={{defaultValue:teamName, onChange:(e) => {setTeamName(e.target.value); team.name = e.target.value}}}
     header={strings.Placeholders.TeamName}
      />}
    {canAddToTeam(team) && <Button variant="contained" style={{height:48}} onClick={() => addToTeam(team)} color="primary">{`${strings.Add} ${changeMember?.name}`}</Button>}
    </div>
    <div className='card team'>
        <SectionView title={teamName}
        Content={<TeamViewContent team={team} />}
        />
    </div>
    </>
}

function TeamViewContent({team}:{team:Team}){
    
return <div className='team-view'>{team.members.map(x =><TeamMemberView
position={x.position > -1 ? positionsNames[x.position] : ''} item={x} key={x.name + x.registrationId}
/>)}
</div>
}
export default SuggestedTeamsPage;