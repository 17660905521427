import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material/';
import { ContentComponentProps, DialogConfig } from '../dialog';
import localizationService from '../../../services/localizationService';
import MemberShipModel from '../../../models/membershipModel';
import playerServices from '../../../services/playerServices';
import Joi from 'joi';
import * as Helper from '../../../shared/ui/AppFormHelper';
import Model from './model';
import { PlayerStatus } from '../../../entities/enums';

export const Membership: DialogConfig<MemberShipModel, PlayerStatus> = {
    Content: ReguestContent,
    inputModel: { clubName:'', code:'' },
    resolvedResult : Model.onOk,
    title: `${localizationService.Request} ${localizationService.MemberShip}`,
    SubmitTitle: localizationService.Request,
    CancelTitle: localizationService.Cancel,
    schema:Joi.object({
        clubName:Joi.string().required(),
        code:Joi.string().required()
    })
}

function ReguestContent(props: ContentComponentProps<MemberShipModel>) {
    const { clubName, code } = props.state;

    return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <Helper.InputWithValidation
        errorMessage={props.errors?.clubName}
        textFieldProps={{label:localizationService.ClubName, name:'clubName', defaultValue:clubName, InputLabelProps:{shrink:true}, 
        onChange:props.onInputChange
        }}
         />
         <Helper.InputWithValidation
        errorMessage={props.errors?.code}
        textFieldProps={{label:localizationService.Code, name:'code', defaultValue:code, InputLabelProps:{shrink:true}, 
        onChange:props.onInputChange
        }}
         />
    </div>;
}