import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import Calendar, { TileDisabledFunc } from "react-calendar";
import strings from "../../services/localizationService";
import LanguageContext from "../../context/languageContext";
import './index.css'
import { CalendarMonth } from "@mui/icons-material";

interface GamesCalendarProps{
    disableTitle:TileDisabledFunc,
    selectedDate?:Date,
    setSelectedDate:(date?:Date) => void
}

export default function GamesCalendar({disableTitle,selectedDate, setSelectedDate }:GamesCalendarProps){
    const language = useContext(LanguageContext);
    const [selectedElement, setSelectedElement] = useState<HTMLElement>();
    const [displayCalendar, setDisplayCalendar] = useState(true);

    const resetCalendar = () =>{
        if(!selectedDate) return;
        setSelectedDate(undefined);
        toggleCalendar();
    }

    const toggleCalendar = () =>{
        const classes = ["react-calendar__tile--active","react-calendar__tile--range", "react-calendar__tile--rangeStart", "react-calendar__tile--rangeEnd", "react-calendar__tile--rangeBothEnds"]
        const findSelected = selectedElement;
        if(!findSelected) return;
        classes.forEach(x => findSelected.classList.toggle(x))
    }

    return <div className="calendarContainer">
        <Button color="inherit" onClick={() => setDisplayCalendar(!displayCalendar)}
        endIcon={<CalendarMonth />}
        variant="outlined"
            >{!displayCalendar ? strings.ShowCalendar : strings.HideCalendar}</Button>
        {displayCalendar && <>
    <Calendar 
    tileDisabled = {disableTitle}
    locale={language.currentLanguage}
    onChange={(v,e) => {
        const sameItem = selectedElement === e.currentTarget;
        if(sameItem && selectedDate) return resetCalendar();
        if(!v) return resetCalendar();
        const userDate = new Date(v.toString());
        setSelectedDate(userDate)
        if(!sameItem)
        setSelectedElement(e.currentTarget);
    else toggleCalendar();
    }}
    value={selectedDate}
     />
     
     <Button disabled={!selectedDate} color='info' onClick={resetCalendar}>{strings.Reset.split(' ')[0]}</Button>
     </>}
    </div>
}