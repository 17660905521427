import { TextField, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { eventNames } from '../../constants';
import localizationService from '../../services/localizationService';
import {register} from './model';
import './index.css';
import GameContext, { GamesContext } from '../../context/gameContext';
import viewModels from '../../viewModels';

export interface RegisterGuestProps{
    clubId:number,
    date:string,
    start:string
}

export const RegisterGuest = () => {
    const {game} = useContext(GameContext);
    const {games} = useContext(GamesContext);
    const { clubId, date, start} = game;
    const [name, setName] = useState('');
    const onRegistered = (e:any) =>{
        setName('');
    }
    useEffect(() =>{
        document.addEventListener(eventNames.updatePlayers, onRegistered);
        return () => {
            document.removeEventListener(eventNames.updatePlayers, onRegistered);
        }
    }, []);

    const onSuccess = () =>{
        const gameIndex = games.indexOf(game);
        const gamesToUpdate = games.filter((x, i) => x.clubId == clubId && i >= gameIndex);
         viewModels.game.recalculateFees(gamesToUpdate);
    }

    return  <div className='guest-registration-container'>
    <TextField color='secondary' variant='outlined'
    style={{ marginRight:20, borderRadius:5}}
    placeholder={localizationService.GuestName}
    value={name}
    onChange = {(e) =>{
        setName(e.target.value);
    }}
     />
 <Button variant="contained"
        color="primary"
        disabled={!name}
        style={{height:50}}
        onClick={() =>{
            register({
                clubId:clubId,
                date:date,
                start:start,
                playerName:name,
                confirmationTimeout:game.confirmationTimeout
            }, onSuccess);
        }}>{localizationService.RegisterGuest}</Button>
        </div>
        
}