import React, { useEffect, useRef, useState } from "react";
import './index.css'
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
interface AppSelectProps{
    View:(isOpen:boolean) => JSX.Element,
    doNotCloseOnOptionClick?:boolean,
    OptionsView: () => JSX.Element
}
export interface DropDownItem{
    id:number,
    title:string,
    selected:boolean
}
interface DropDownSelectProps{
    items:DropDownItem[],
    onItemSelected:(item:DropDownItem) => void
}
export function DropDownSelect({items, onItemSelected}:DropDownSelectProps){
    const selectedItem = items.find(x => x.selected);
    return <AppSelect
    View={(isOpen) => <div className="drop-down-select"><p className="drop-down-selected-item-title">{selectedItem?.title}</p>
    {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}</div>} 
    OptionsView={() => <>{items.filter(x => !x.selected).map(x => <p onClick={() =>{
        onItemSelected(x);
    }} key={x.id}>{x.title}</p>)}</>}
    />
}

export function AppSelect({View, OptionsView, doNotCloseOnOptionClick}:AppSelectProps){
    const optionsRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen]= useState(false);
    const viewRef = useRef<HTMLDivElement>(null);
    useEffect(() =>{
        document.addEventListener("click", onExternalClick);
        return () => document.removeEventListener("click", onExternalClick);
    })
    const onExternalClick = (e:MouseEvent) =>{
        if(clickOnView(e, viewRef) || clickOnView(e, optionsRef))
            return;
            if(isOpen)
            onClick();
    }
    const onClick = () =>{
        optionsRef.current?.classList.toggle("open");
        setIsOpen(!isOpen);
    }
    const clickOnView = (e:MouseEvent, ref:React.RefObject<HTMLDivElement>) => {
        var viewCooronates = ref.current?.getBoundingClientRect()!;
        if(e.x >= viewCooronates.left && e.x <= viewCooronates.right
            && e.y >= viewCooronates.top && e.y <= viewCooronates.bottom)
            return true;
            return false;
    }
    return <div className="app-select">
        <div ref={viewRef} onClick={onClick}> 
            {View(isOpen)}
        </div>
        <div onClick={() => {if(!doNotCloseOnOptionClick) onClick();}} ref={optionsRef} className="app-select-options">
            <OptionsView />
        </div>
    </div>
}