import React, { useEffect, useState } from "react";
import strings from "../../services/localizationService";
import { Typography } from "@mui/material";
interface CountDownProps{
    deadLine:string,
    title:string,
    textColor:"inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error" | undefined,
    onTimeOut:() => void
}

export const milisecondsInSecond = 1000;
export const minutsInHour = 60;
export const secondsInMinut = minutsInHour;
export const hoursInDay = 24;

export default function CountDown({deadLine, title, textColor, onTimeOut}:CountDownProps){
    const [remainingTime, setRemainingTime] = useState('');
    
    const getTime = () =>{
        const time = DDL(deadLine);
        setRemainingTime(time.Display);
        if(time.Exprided)
            onTimeOut();
    }
    useEffect(() =>{
        getTime();
        const interval = setInterval(() => getTime(), milisecondsInSecond);
        return () => clearInterval(interval)
    }, []);
    return<><Typography color={textColor}>{title}</Typography>
    <Typography color={textColor}>{remainingTime}</Typography>
    </>
}

export function DDL(date:string){
    const getDaysForm = (days:number) => {
        if(!days) return '';
        if(days === 1) return `${days} ${strings.DaysForms[0]} ${strings.And}`;
        const isRuLanguage = strings.getLanguage() === 'ru';
        if(!isRuLanguage || days < 5) return `${days} ${strings.DaysForms[1]} ${strings.And}`;
        return `${days} ${strings.DaysForms[2]} ${strings.And}`
    }

    const Time = Date.parse(date) - Date.now();
    const Days = Math.floor(Time / (milisecondsInSecond * secondsInMinut * minutsInHour * hoursInDay));
    const Hours = Math.floor((Time / (milisecondsInSecond * secondsInMinut * minutsInHour)) % hoursInDay).toLocaleString(strings.getLanguage(), {minimumIntegerDigits:2});
    const Minutes = Math.floor((Time / milisecondsInSecond / secondsInMinut) % minutsInHour).toLocaleString(strings.getLanguage(), {minimumIntegerDigits:2});
    const Seconds = Math.floor((Time / milisecondsInSecond) % secondsInMinut).toLocaleString(strings.getLanguage(), {minimumIntegerDigits:2});
    const DaysFormat = getDaysForm(Days);

    return {Time,Days, Hours, Minutes, Seconds, Exprided:Time < 100, Display:`${DaysFormat} ${Hours}:${Minutes}:${Seconds}`}
}
