import React, { useState } from "react";
import { DialogProps, open } from "../dialogHelpers";
import AddAgendaModel from "../../../models/addAgendaModel";
import IDialog from "../dialogs";
import '../index.css'
import './index.css'
import strings from "../../../services/localizationService";
import ClubAgenda from "../../../entities/clubAgenda";
import { DaysOfWeek } from "../../../widgets/days-of-week";
import { DayOfWeek } from "../../../entities/enums";
import TimeInput, {getTimeById} from "../../../widgets/time-input";
import { toast } from "react-toastify";
import DialogHeader from "../dialog-header";
const AgendaDialog:IDialog<ClubAgenda> = {
    show: () => open(AgendaDialogView)
}

function AgendaDialogView({resolve}:DialogProps<ClubAgenda>){
    const [selectedDay, setSelectedDay] = useState(DayOfWeek.Monday);
    const onAddAgenda = () =>{
        const time = getTimeById("agenda").time;
        const isValid = time.start < time.end;
        if(!isValid)
        toast.error(strings.validationErrorsByField["end.string.min"]);
        else
        resolve({day:selectedDay, id:0, ...time});
    }
    return <div className="dialog-view">
        <div className="centered-dialog agenda-dialog">
        
        <p className="dialog-title">{strings.NewAgenda}</p>
        <DaysOfWeek onDayClicked={setSelectedDay} itemAditionClasses="agenda-day" />
        <TimeInput id="agenda" />
        <DialogHeader 
        resolve={resolve}
        steps={[{
            canGoFoward:() => true,
            onForward:onAddAgenda,
        }]}
        />
        </div>
    </div>
}

export default AgendaDialog;