export default {
    en:{
        Text:[{
            Section:"Players rights",
            Details:["Create a club, thus becoming administrator for that club",
            "To Create a club - go to My Clubs and hit: Add Club button and in open dialog, provide required information and hit Add. More about required information you can find in section about club below",
             "Register to a game for available clubs.",
             `In order to register to a game - go To Games and in available list register to game - if registartion is avaialable to you - you should see Register button. 
             Read about Players status to find out in which cases registration is available`,
             `Request Membership for a private club by pressing Membership and in the opened dialog
              enter the name of the club and the access code provided by the administrator.
              By doing that - they will get one of 2 status: Member or Guest. Details of them can be found below`,
              `Add or change playable positions. Read more about positions in the «Positions» section`,
              `Give feedback(evaluation) to other players in clubs that they both belong skills - this feedback is used for suggesting most balanced teams`,
              `Register Guests, if this option is available`
            ]
        },
        {
            Section:"Players obligations",
            Details:["Participate to games which they registered to(except for rezerved - they are not part of active players for game)", "Respect other players"]
        },
        {
            Section:"Club Administrator rights",
            Details:["Change club conditions and games schedule","Create or cancel games",
            `Games Can be created manually or automatically. To Create A game manually - after you create club - it will be a buton to create game. 
            In order to create Games automatically is enough to add a schedule for it`,
            `For automatically created games you can add yourself to default list of players`,
            "Add or remove players from game", "Change players Status for club", "Block players from coming to games - if there is a reason(player didn't come to registered game, show too aggressive behaviour, his player level is not matching clubs level ect.)",
        "Change Club members Points."]
        },
        {
            Section:"Club Administrator obligations",
            Details:["Provide accurate information about games(place,time,number of seats)","Provide valid contact information(phone/facebook link/viber ect.)"]
        },
    {
        Section:"Player status",
        Details:["Player can have one of next status to a specific club. This statuses are visible and changed by club administrators only",
            "Club Administrator - the highest status. Have rights and obligations mentioned above",
                 "Special Guest - is lower then Club Administrator, but higher then Member - They can not be on rezerv - added by Administrator only - for each specific game separately",
                "Member - a regular club member which have rights to register for game anytime. Can be in rezerv - if number of seats are exceeded",
                "Guest - this status is set to players for private clubs - they can register to game, if registarion is active. Registartion can be active in one of 2 cases: Administrator set a secific time(in hours) before game starts to open OR Administrator open registration manually",
                "Blocked - this status is set to players which are not allowed to come to games. Cause of that is mentioned in «Club Administrator rights» Section above",
            `In order to change a player status - Go to My Clubs and from list of administrated clubs -
             click on View members and change status for a selected member`]
    },
    {
        Section:"Games Default Players List",
        Details:["A private club can have a default list of players for each game",
            "By moving to default list - will be included in all games for selected day and club",
                 "This list contains only Regular Members or Administrators and they are allowed to add or remove themself only",
                 "This option is available in «Games» by hitting «Events Schedule», if you have status of a Regular in any private clubs or administer any club",
                "By including to this list - they will be added to games as registered players by default. Regular player can be in rezerv - if nr of registered to this game exceed nr of allowed players to game"]
    },
    {
        Section:"Club",
        Details:["A club is place where players are gatering to play. Have next Conditions(or settings)",
        "Name - a name by which players can distinguish places where they are playing. There can not be 2 clubs with same name",
                 "Address - fizical location of the club. Multiplace clubs can have same address - if games are not overlaping",
                "Maxim players - nr of player allowed to a game",
                "Club accessebility - can be either public or private",
                "Club type - can be classic volleyball, beach volleyball, soccer or basketball",
                "Club Scope - can be game, training or both",
            "Club Fee - amount to pay for games - can be a fixed amount - players pay this amount - no matter how much came OR dynamic - the ammount is split by nr of registered players",
            "Payment type - can be by event or by hour",
            "Penalties -  if applicable, are % from event fee. To apply penalties you should select YES in coresponig box",
            "Name,Club type are set only once on club creation - can not be change afterwards"]
    },
    {
        Section:"Private Club",
        Details:["In case of private club. Additional conditions shoud be suplied",
        "Member Code - a code by which Players will get Member Status. Can not be empty",
                "Guest Code - a code by which Players will get Guest Status. Can be empty - in this case - all registered users of site will see games for this club as Guests",
                "Guests Registration Strategy - which can be either By hours before Start of game Or Manually"]
    },
    {
        Section:"Guests Registration Strategy",
        Details:["Hours before Start of game strategy - Should be provided hours, equal or greater then 1 - this is time when guests will be able to register to games",
    "Manually - Administrator will have specific option to open guests registration and is should be activate for each game separately"]
    },{
        Section:"Site Administrators rights",
        Details:["Restrict a user to use site, in case of getting complaints from other users - by doing that - all clubs and games of that person will be deleted"
    ]
    },
    {
        Section:"Points",
        Details:["Each club member can have club points",
    "They can be changed by Admins and viewed by members on there profile page",
"Points are use to calculate fee for registered event"]
    },
{
    Section:"Contacts",
    Details:['For any questions or suggestions you can reach out on info@play-all-sports.com']
},
{
    Section:"Telegram Bot",
    Details:['You can integrate telegram - login and get notified about events in your telegram profile. To do that - ask telegram bot for help: @Play_all_sports_bot']
}]
    },
    ro:{
        Text:[{
            Section:"Drepturile jucătorilor",
            Details:["Creați un club, devenind astfel administrator pentru acel club",
            "Pentru a crea un club - accesați Cluburile mele și apăsați butonul: Adăugați club și, în dialogul deschis, furnizați informațiile necesare și apăsați Adăugați. Mai multe despre informațiile necesare, puteți găsi în secțiunea despre club de mai jos",
             "Înregistrați la joc pentru cluburile disponibile",
             `Pentru a vă înregistra la un joc - accesați Jocuri și în lista disponibilă - înregistrați-vă la joc - dacă înregistrarea este disponibilă pentru dvs. - ar trebui să vedeți butonul pentru înregistrare.
             Citiți despre statutul jucătorilor pentru a afla în ce cazuri este disponibilă înregistrarea`,
             `Solicitați calitatea de membru pentru un club privat, prin apasând Calitatea de Membru
             și în dialogul deschis introduceți numele clubului și codul de acces furnizate de administrator.
             Făcând asta - ei vor obține unul dintre cele 2 statute: Membru sau Oaspeți. Detalii despre ele pot fi găsite mai jos`,
             `Adăugați sau modificați pozițiile pe care jucați. Mai mult despre poziții citiți în secția «Poziții»`,
             `Oferiți feedback (evaluare) abilitățile altor jucători din cluburi în care ambii aparțin - acest feedback este folosit pentru a sugera cele mai echilibrate echipe.`,
             `Înregistrați oaspeții, dacă această opțiune este disponibilă`
              ]
        },
        {
            Section:"Obligațiile jucătorilor",
            Details:["Participați la jocurile la care v-ați înregistrat (excepția cei din rezerv - nu fac parte din jucătorii activi pentru joc)", "Respecte ceilalți jucători"]
        },
        {
            Section:"Drepturile Administratorului de club",
            Details:["Schimbați condițiile clubului și programul jocului","Creați sau anulați jocuri",
            `Jocurile pot fi create manual sau automat. Pentru a crea un joc manual - după ce ați creat clubul - va fi un buton pentru a crea joc.
            Pentru a crea jocuri automat este suficient să adăugați o agendă`,
            `Pentru jocurile create automat, vă puteți adăuga la lista implicită de jucători`,
            "Adăugați sau eliminați jucători pentru joc", "Schimbați statutul jucătorilor pentru club", "Blocați jucătorii să vină la jocuri - dacă există un motiv (jucătorul nu a venit la jocul înregistrat, arată un comportament prea agresiv, nivelul său de jucător nu se potrivește cu nivelul clubului etc.)",
        "Schimbați punctele membrilor clubului"]
        },
        {
            Section:"Obligațiile Administratorului de club",
            Details:["Furnizați informații exacte despre jocuri (loc, oră, număr de locuri)","Furnizați informații de contact valide (telefon/link Facebook/viber etc.)"]
        },
        {
            Section:"Statutul Jucătorilor",
            Details:["Jucătorul poate avea unul din următorul statut la un anumit club. Aceste statuturi sunt vizibile și modificate numai de administratorii clubului",
            "Administrator de club - cel mai înalt statut. Au drepturile și obligațiile menționate mai sus",
                     "Invitat Special - este mai mic decât Administratorul Clubului, dar mai mare decât Membru - Nu pot fi în rezervă - adăugat doar de Administrator - pentru fiecare joc separat",
                    "Membru - un membru obișnuit al clubului care are drepturi de a se înregistra pentru joc oricând. Poate fi în rezervă - dacă numărul de locuri este depășit",
                    "Invitat - acest statut este setat jucătorilor pentru cluburi private - aceștia se pot înregistra la joc, dacă înregistrarea este activă. Înregistrarea poate fi activă într-unul din cele 2 cazuri: administratorul a stabilit un timp specific (în ore) înaintea începerii jocului când să se deschidă SAU administratorul deschide înregistrarea manual",
                    "Blocat - acest statut este setat pentru jucătorii cărora nu li se permite să vină la jocuri. Motivul este menționat în secțiunea «Drepturile Administratorului de club» de mai sus",
                `Pentru a schimba statutul unui jucător - Accesați Cluburile mele și din lista de cluburi administrate -
                faceți clic pe Vedeți membrii și modificați statutul pentru membru selectat`]
        },
        {
            Section:"Lista de jucători impliciți pentru jocuri",
            Details:["Un club privat poate avea o listă implicită de jucători pentru fiecare joc",
                "Prin trecerea la lista implicită - va fi inclus în toate jocurile pentru ziua și clubul selectat",
                "Această listă conține doar Membri sau Administratori și aceștia au voie să se adauge sau să se elimine numai pe ei înșiși",
                "Această opțiune este disponibilă în «Jocuri» prin apasând Programul Evenimentelor», dacă sunteți cu statut de membru în vreun club privat ori administrați vre-un club",
               "Prin includerea în această listă - vor fi adăugați la jocuri ca jucători înregistrați în mod implicit. Jucătorul cu Statut de Member poate fi în rezervă - dacă numărul de înscriși la acest joc depășește numărul de jucători permisi să joace"]
        },
        {
            Section:"Club",
            Details:["Un club este locul în care jucătorii se întâlnesc să joace. Are următoarele condiții (sau setări)",
            "Denumire - denumire prin care jucătorii pot distinge locurile în care joacă. Nu pot exista 2 cluburi cu același nume",
                     "Adresa - locația fizică a clubului. Cluburile pot avea aceeași adresă - dacă jocurile nu se suprapun",
                    "Numărul maxim de jucători – numărul jucătorului permis la un joc",
                    "Accesibilitatea clubului – poate fi public sau privat",
                    "Tipul club – poate fi volei clasic, volei de plajă, fotbal sau basket",
                    "Scopul clubului - poate fi joc, antrenament sau ambele",
                "Taxa de club – suma de plătit pentru jocuri – poate fi o sumă fixă ​​– jucătorii plătesc această sumă – indiferent cât de mulți au venit SAU dinamic – suma este împărțită la numărul de jucători înregistrați",
                "Tipul Taxei - poate fi per eveniment ori per oră",
            "Penalități -  dacă e aplicabil, se reprezintă ca % din taxa evenimentului. Pentru a aplica penatități - selectați DA în chenarul corespunzător",
                "Numele, tipul de club sunt setate o singură dată la crearea clubului - nu pot fi modificate ulterior"]
        },
        {
            Section:"Club privat",
            Details:["În cazul unui club privat. Ar trebui furnizate condiții suplimentare",
            "Cod de membru - un cod prin care jucătorii vor obține statutul de membru. Nu poate fi gol",
                    "Cod de oaspete – un cod prin care jucătorii vor primi statutul de invitat. Poate fi gol – în acest caz – toți utilizatorii înregistrați ai site-ului vor vedea jocurile pentru acest club ca invitați",
                    "Strategia de înregistrare a oaspeților – care poate fi după ore înainte de începerea jocului sau manual"]
        },
        {
            Section:"Strategia de înregistrare a oaspeților",
            Details:["Ore înainte de începerea de joc – Ar trebui furnizate ore, egale sau mai mari decât 1 – acesta este momentul în care oaspeții se vor putea înregistra la jocuri",
        "Manuală - Administratorul va avea o opțiune specific pentru a deschide înregistrarea oaspeților și ar trebui să fie activat pentru fiecare joc separat"]
        },{
            Section:"Drepturi administratorilor de site",
            Details:["Restricționați un utilizator să folosească site-ul, în cazul în care primesc reclamații de la alți utilizatori - făcând asta - toate cluburile și jocurile acelei persoane vor fi șterse"
        ]
        },
        {
            Section:"Puncte",
            Details:["Fiecare membru al clubului poate avea puncte de club",
        "Ele pot fi modificate de administratori și vizualizate de membri pe pagina lor de profil",
    "Punctele sunt folosite pentru a calcula taxa pentru evenimentul înregistrat"]
        },
        {
            Section:"Contacte",
            Details:['Pentru orice întrebări sau sugestii, ne puteți contacta pe info@play-all-sports.com']
        },
        {
            Section:"Telegram Robot",
            Details:['Puteți integra telegram - autentificați-vă și primiți notificări despre evenimente în profilul dvs. Telegram. Pentru a face asta - cereți ajutor robotului telegram: @Play_all_sports_bot']
        }]
    },
    ru:{
        Text:[{
            Section:"Права игроков",
            Details:["Cоздать клуб, таким образом став администратором этого клуба",
            "Чтобы создать клуб - перейдите в Мои клубы и нажмите кнопку: Добавить клуб и в открытом диалоговом окне введите необходимую информацию и нажмите Добавить. Подробнее о необходимой информации вы можете узнать в разделе о клубе ниже",
             "Зарегистрироваться на игру для доступных клубов",
             `Для того, чтобы зарегистрироваться на игру - перейдите в раздел Игры и в списке доступных выберите игру - если вам доступна регистрация - вы должны увидеть кнопку для регистрации.
             Прочтите о статусе игрока, чтобы узнать, в каких случаях доступна регистрация`,
             `Запросите членство в частном клубе, нажав «Членство» и в открывшемся диалоговом окне.
             ввести название клуба и код доступа, предоставленный администратором.
             Сделав это, они получат один из двух статусов: Член или Гость. Подробности о них можно узнать ниже`,
             `Добавить или изменить игровые позиции. Подробнее о позициях в разделе «Позиции»`,
             `Дайте отзыв (оцените) навыки других игроков в клубах, к которым вы оба принадлежите — этот отзыв используется, чтобы предложить наиболее сбалансированные команды.`,
             `Регистрация гостей, если эта опция доступна`
            ]
        },
        {
            Section:"Обязательства игроков",
            Details:["Участвовать в играх, на которые они зарегистрировались(кроме запасных - они не являются активными игроками для игры)", "Уважать других игроков"]
        },
        {
            Section:"Права администратора клуба",
            Details:["Изменить клубные условия и расписание игр","Создать или отменить игры",
            `Игры можно создавать вручную или автоматически. Чтобы создать игру вручную - после того, как вы создадите клуб - будет кнопка для создания игры.
            Для автоматического создания игр достаточно добавить для них расписание`,
            `Для автоматически созданных игр вы можете добавить себя в список игроков по умолчанию`,
            "Добавить или удалить игроков из игры", "Изменить статус игроков для клуба", "Запретить игрокам приходить на игры - если есть причина (игрок не пришел на зарегистрированную игру, ведет себя слишком агрессивно, его уровень игры не соответствует уровню клуба и т.д.)",
        "Изменить баллы членов клуба"]
        },
        {
            Section:"Обязанности администратора клуба",
            Details:["Предоставлять достоверную информацию об играх (место, время, количество мест)","Предоставьте действительную контактную информацию (телефон/ссылка на  facebook/viber и т. д.)"]
        },
        {
            Section:"Статусов Игрока",
            Details:["Игрок может иметь один из следующих статусов в конкретном клубе. Эти статусы видны и изменяются только администраторами клуба.",
            "Администратор клуба - высший статус. У них есть права и обязанности, упомянутые выше.",
                     "Особый гость - ниже, чем администратор клуба, но выше, чем член - не может быть в зарезерве - добавляется только администратором - для каждой игры отдельно",
                    "Член - постоянный член клуба, имеющий право зарегистрироваться для игры в любое время. Может быть в зарезерве - если количество мест превышено",
                    "Гость - этот статус устанавливается игрокам для частном клубе - они могут зарегистрироваться для игры, если регистрация активна. Регистрация может быть активной в одном из 2-х случаев: администратор установил конкретное время (в часах) перед началом игры, когда открывать ИЛИ администратор открывает регистрацию вручную",
                    "Заблокирован - этот статус устанавливается для игроков, которым запрещено приходить на игры. Причина указана в разделе «Права администратора клуба» выше.",
                `Для того, чтобы изменить статус игрока - Зайдите в Мои Клубы и из списка администрируемых клубов -
                нажмите «Просмотреть участников» и измените статус для выбранного участника`]
        },
        {
            Section:"Список игроков по умолчанию",
            Details:["Частный клуб может иметь список игроков по умолчанию для каждой игры",
                "При переходе в список по умолчанию - будет включен во все игры выбранного дня и клуба",
            "Этот список содержит только Члены или администраторы, и им разрешено добавлять или удалять только себя",
            "Эта опция доступна в «Игры», при нажатий «Расписание событий», если вы состоите в каких-либо частных клубах c стутусам Члена или администрируете клуб",
           "При включении в этот список - они будут добавлены в игры как зарегистрированные игроки по умолчанию. Члены могут быть в резерве - если количество зарегистрированных в этой игре превышает количество допущенных к игре игроков"]
        },
        {
            Section:"Клуб",
            Details:["Клуб — это место, где игроки собираются играть. Есть следующие условия (или настройки)",
            "Имя - название, по которому игроки могут отличить места, где они играют. Не может быть 2-х клубов с одинаковым названием",
                     "Адрес - физическое местонахождение клуба. Клубы могут иметь один и тот же адрес - если игры не пересекаются",
                    "Максим игроков - количество игроков, допущенных к игре",
                    "Доступность клуба - может быть публичной или приватной",
                    "Тип Клуба - может быть классический волейбол, пляжный волейбол, футбол или баскетбол",
                    "Цель Клуба - может быть игра, тренировка или оба",
                "Клубный сбор - сумма для оплаты игр - может быть фиксированной суммой - игроки платят эту сумму - независимо от того, сколько пришло ИЛИ динамическим - сумма делится на количество зарегистрированных игроков",
                "Тип Платежа - может быть за событие или за час",
            "Штрафы - если применимо, составляют % от платы за мероприятие. Чтобы применить штрафы, вы должны выбрать ДА в соответствующая поле",
                "Название, тип клуба задаются только один раз при создании клуба - после изменить их нельзя"]
        },
        {
            Section:"Приватный клуб",
            Details:["В случае приватного клуба. Необходимо указать дополнительные условия",
            "Код участника - код, по которому игроки получат статус члена. Не может быть пустым",
                    "Код Гостя - код, по которому Игроки будут получать Статус Гостя. Может быть пустым - в этом случае все зарегистрированные пользователи сайта будут видеть игры данного клуба в качестве Гостей",
                    "Стратегия регистрации гостей — за несколько часов до начала игры или вручную"]
        },
        {
            Section:"Стратегия регистрации гостей",
            Details:["Несколько часов до начала игры – должны быть указаны часы, равные или превышающие 1 – это время, когда гости смогут зарегистрироваться для участия в играх",
        "Вручную - Администраторы будут иметь специальную опцию, чтобы открыть регистрацию гостей, и ее следует активировать для каждой игры отдельно"]
        },{
            Section:"Права администратора сайта",
            Details:["Ограничить пользователю использование сайта, в случае поступления жалоб от других пользователей - все клубы и игры этого человека будут удалены"
        ]
        },
        {
            Section:"Баллы",
            Details:["Каждый член клуба может иметь клубные баллы",
        "Они могут быть изменены администраторами и просмотрены участниками на странице профиля",
    "Баллы используются для расчета платы за зарегистрированное мероприятие"]
        },
        {
            Section:"Контакты",
            Details:['По любым вопросам или предложениям вы можете обращаться по адресу info@play-all-sports.com']
        },
        {
            Section:"Tелеграмм Бот",
            Details:['Вы можете интегрировать телеграмм - войдите и получайте уведомления о событиях в своем профиле телеграмм. Для этого обратитесь за помощью к телеграм-боту: @Play_all_sports_bot']
        }]
    }
}