import { AddGameProps } from "../../../components/dialogs/add-game";
import ClubAgenda from "../../../entities/clubAgenda";
import ClubSettings from "../../../entities/clubSettings";
import clubAgendaService from "../../../services/clubAgendaService";
import clubService from "../../../services/clubService";
import gameService from "../../../services/gameService";
import apiRequest from "../../../shared/apiRequest";

async function addAgenda(clubId:number, dialogResult: ClubAgenda | undefined, onSuccess:(item:ClubAgenda) => void) {
if(!dialogResult) return;
return apiRequest.execute(() => clubAgendaService.post(clubId, {
                day:dialogResult.day,
                end:getTimeSpan(dialogResult.end),
                start:getTimeSpan(dialogResult.start)
            } as ClubAgenda), (id) => onSuccess({...dialogResult, id:id}))
}

async function removeAgendaItem(clubId:number, agendaId:number, onSuccess: () => void){
    return apiRequest.execute(() => clubAgendaService.delete(clubId, agendaId), onSuccess);
}

async function getSettings(id:number, onSuccess:(settings:ClubSettings) => void) {
    return apiRequest.execute(() => clubService.getSettings(id), onSuccess);
}

async function getSchedule(id:number, onSuccess:(schedule:ClubAgenda[]) => void){
    return apiRequest.execute(() => clubAgendaService.get(id), onSuccess);
}

async function createGame(id:number, dialogResult:AddGameProps | undefined){
    if(!dialogResult) return;

    await apiRequest.execute(() => gameService.createGame({
        date:dialogResult.date,
                start:getTimeSpan(dialogResult.start),
                end:getTimeSpan(dialogResult.end),
                clubId:id,
                scope:dialogResult.type
    }), () => {});
    };

function getTimeSpan(input:string){
    return input.length == 5 ? input + ":00" : input;
}
export default {createGame, getSettings, getSchedule, removeAgendaItem, addAgenda};