import React, { useContext } from "react";
import Game from "../../entities/game";
import strings from "../../services/localizationService";
import GameContext from "../../context/gameContext";

export function Time(){
    const {game} = useContext(GameContext);
    const {date, start, end} = game;
    const dateValue = new Date(date);
    return <div className="game-item-list-dateTime card">
        <p>{dateValue.toLocaleDateString(strings.getLanguage(), {dateStyle:'full'})}</p>
        <p>{start.substring(0, 5)} - {end.substring(0, 5)}</p>
        <p>{strings.Participants}: {game.minPlayers} - {game.maxPlayers}</p>
    </div>
}