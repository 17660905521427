import React, { useState } from "react";
import IDialog from "../dialogs";
import { DialogProps, open } from "../dialogHelpers";
import MemberClub from "../../../entities/memberClub";
import { AppSelect } from "../../../widgets/app-select";
import { Button, TextField } from "@mui/material";
import AppOptions from "../../../widgets/app-options";
import DialogHeader from "../dialog-header";

function TimeoutConfirmation(item:MemberClub):IDialog<MemberClub>{
    return {
        show: () => open((resolve) => <TimeoutConfirmationView resolve={resolve.resolve} item={item} />) 
    }
}

interface TimeoutConfirmationViewProps extends DialogProps<MemberClub> {
    item:MemberClub
}

function TimeoutConfirmationView({item, resolve}:TimeoutConfirmationViewProps){

    const [index, setIndex] = useState(item.confirmationTimeout ? 0 : 1);
    const [confirmationTimeout, setConfirmationTime] = useState(item.confirmationTimeout);
    const onStateChanged = (newState:number) =>{
        let newConfirmationTimeout = newState === 0 && item.confirmationTimeout ? item.confirmationTimeout : (newState === 0 ? 30 : undefined);
        setIndex(newState);
        setConfirmationTime(newConfirmationTimeout);
    }
    return <div className="dialog-view centered-dialog">
        <AppOptions optionType="TimeoutConfirmation"
        onStateChanged={onStateChanged}
        defaultValue={index}
        />
        {index === 0 && <TextField type="number" 
        value={confirmationTimeout}
        onChange={(e) => {setConfirmationTime(+e.currentTarget.value)}}
        />}
        <DialogHeader
        resolve={resolve}
        steps={[{
            canGoFoward:() => item.confirmationTimeout !== confirmationTimeout && (confirmationTimeout === undefined || (confirmationTimeout >= 30 && confirmationTimeout <= 120)),
            onForward:() => resolve({...item, confirmationTimeout})
        }]}
         />
    </div>
}

export default TimeoutConfirmation;