import Game from "../../entities/game";
import clubService from "../../services/clubService";
import gameService from "../../services/gameService";
import apiRequest from "../../shared/apiRequest";

async function execute(game:Game, onSuccess: () => void){
    return apiRequest.execute(() => gameService.update(game),
                     () =>{
                        onSuccess();
                     });
}
function isAdmin(game:Game) { return clubService.isAdmin() && !game.cancelled}
export default { execute, isAdmin};