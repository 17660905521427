import React from 'react';
import Game from '../entities/game';
import Club from '../entities/club';
export interface ClubItemProps extends DeleteClub{
    club:Club,
    updateClub:(newState:Club) => void
}
interface DeleteClub{
    deleteClub:(clubId:number) => void,
}
export interface ClubsProps extends DeleteClub{
    clubs:Club[]
}

const ClubContext = React.createContext({} as ClubItemProps);
ClubContext.displayName = "ClubContext";
export const ClubsContext = React.createContext({} as ClubsProps);
ClubsContext.displayName = "ClubsContext";

export default ClubContext;