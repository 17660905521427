export const routers = {
    register:"/register",
    login:"/login",
    games: "/games",
    home: '/',
    profile: '/profile/:id',
    clubs: '/clubs',
    members:'/clubs/members/:id',
    addMembers:'/clubs/members/add/:id',
    notFound: '*',
    reset: '/reset',
    confirmEmail:'/confirm/:email',
    termsAndConditions:'/termsAndConditions',
    privacyPolicy:'/privacyPolicy',
    accessDenied:"/accessDenied",
    deleteProfile:"/deleteProfile",
    feedback:"/feedback",
    chat:"/chat",
    suggestedTeams:"/suggestedTeams"
}
export const localStorageKeys = {
    language: "language",
    token: "token",
    playerId:"id",
    notificationAllowed:"notificationAllowed"
}

export const eventNames = {
    updatePlayers:"updatePlayers",
    tabSelected:"TabSelected",
    addClub:"AddClub",
    gameSateChanged:"GameStateChanged",
    loading:"loading",
    timeExpiered:"timeExperied",
    skillsEvaluated:"skillsEvaluated",
    clubSelected:"clubSelected",
    messageReceived:"MessageRecevied",
    messageSend:"MessageSend",
    chatRemoved:"chatRemoved",
    chatListChange:"ChatListChanged",
    onMessageStateChanged:"newMessage",
    totalRegisteredCount:"totalRegisteredCount",
    deleteMessage:"DeleteMessage",
    chatUnreadeCountChanged:"ChatUnreadCountChanged",
    menuItemClicked:"menuItemClicked",
    recalculateFee:"recalculateFee",
    updateFee:"updateFee"
};

export const ServerCalls = true;

export const languages = ['en', 'ru', 'ro'];
