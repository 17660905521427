import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Game from '../../entities/game';
import localizationService from '../../services/localizationService';
import AppButton from '../../shared/ui/appButton';
import Model from './model';
import GameContext from '../../context/gameContext';

export interface GameStateProps{
    game:Game
}

export const GameState = () => {
    const { game, updateGame} = useContext(GameContext);
    const isActive = !game.cancelled;
    const confirmationMessage = isActive ? localizationService.Confirmations.CancelGame 
                                         : localizationService.Confirmations.ActivateGame;
    const label = isActive ? localizationService.CancelGame : localizationService.OpenGame;
    const onClick = () =>{
        return Model.execute(isActive, game, () => updateGame({...game, cancelled:!game.cancelled}));
    }

    return <AppButton variant="contained"
        color={isActive ? "secondary" : "primary"}
        onClick={onClick} confirmationmessage={confirmationMessage} label={label} />;
        
}