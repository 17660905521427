import React, { useState } from "react";
import DialogHeader from "../dialog-header";
import { TextField } from "@mui/material";
import { DialogProps, open } from "../dialogHelpers";
import strings from "../../../services/localizationService";
import IDialog from "../dialogs";

export default function InvitationLimitationDialog(limitation?:number):IDialog<number>{
return {
    show:() => open((resolve) => <DialogView resolve={resolve.resolve} limit={limitation} />)
}
}
interface Props extends DialogProps<number>{
    limit?:number
}
function DialogView({resolve, limit}:Props){

    const [limitation, setLimitation] = useState(limit);

    return <div className="dialog-view">
    <div className="centered-dialog">
    <p className="dialog-title">{strings.InviteLimitTitle}</p>
    <TextField type="number" variant="outlined"
    defaultValue={limitation}
    onChange={(e) => setLimitation(+e.currentTarget.value)}
     />
    <DialogHeader
    resolve={resolve}
    steps={[{
        canGoFoward:() => !!limitation && limitation > -1,
        onForward:() => resolve(limitation)
    }]}
     />
</div>
</div>
}