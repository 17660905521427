import React, { useContext, useState } from 'react';
import Game from '../../entities/game';
import localizationService from '../../services/localizationService';
import AppButton from '../../shared/ui/appButton';
import Model from './model';
import GameContext from '../../context/gameContext';

export const GameAutoCancellation = () => {
    const { game, updateGame} = useContext(GameContext);
    const onClick = () =>{
        return Model.execute({...game, disableAutoCancellation:!game.disableAutoCancellation}, () => {updateGame({...game, disableAutoCancellation: !game.disableAutoCancellation})});
    }

    return <AppButton variant="contained"
        color={!game.disableAutoCancellation ? "secondary" :"primary"}
        className='openRegistration'
        onClick={onClick} confirmationmessage={localizationService.Confirmations.Default} label={game.disableAutoCancellation ? localizationService.EnableAutoCancellation : localizationService.DisableAutoCancellation} />;
        
}