import { localStorageKeys } from "../../constants";
import authService from "../../services/authService"
import { SocialPlatform } from "../../services/interfaces/authService"
import localStorageService from "../../services/localStorageService";
import apiRequest from "../../shared/apiRequest"
import viewModels from "../../viewModels";

interface Props{
    accessToken:string,
    plaform:SocialPlatform,
    onSuccess:() => void
}
function execute(props:Props){
    return apiRequest.execute(() => authService.socialPlatform(props.accessToken, props.plaform), async (auth) =>{
        if(!auth.succeeded) return;
        if(!auth.sportsSelected)
        await viewModels.user.setSports([], () => {
            Succeeded();
        }, true);
        Succeeded();
        function Succeeded(){
            localStorageService.setItem(localStorageKeys.playerId, auth.id);
            props.onSuccess();
        }
    });
}
export default {execute}