import Game from '../entities/game';
import { ClubScope, NotificationType, PlayerStatus } from "../entities/enums";
import RegisteredPlayer from "../entities/registeredPlayer";
import clubService from "../services/clubService";
import clubMemberService from "../services/clubMemberService";
import gameService from "../services/gameService";
import apiRequest from "../shared/apiRequest";
import loading from "../shared/ui/loadable/loading";
import playerService from "../services/playerServices";
import NotificationService from "../services/notificationService";
import strings from "../services/localizationService";
import { GameData, UpdatePlayerListArgs } from '../shared/eventArgs';
import { eventNames } from '../constants';
import events from '../shared/events';
import dialogs from '../components/dialogs';
import walletService from '../services/walletService';
import UpdateGame from '../models/updateGameModel';
import UpdateGameResult from '../models/updateGameResult';
import { toast } from 'react-toastify';
interface RemoveModel{
    clubId:number,
    clubName?:string,
    player:RegisteredPlayer | undefined
    date:string,
    start:string
}

function update(game:UpdateGame,onSuccess:(result:UpdateGameResult) => void){
    return apiRequest.execute(() => gameService.update(game), onSuccess);
}

async function recalculateFees(games:GameData[]) {
    return apiRequest.execute(() => walletService.recalculate(games), (result) =>{
        result.forEach(item =>{
            console.log(item);
            events.trigger(eventNames.updateFee, item);
        });
    })
}

async function ChargeFee(gameData:Game){
    return apiRequest.execute(() => gameService.chargeFee(gameData), () => toast.info(strings.Placeholders.OperationCompleted));
}

async function removePlayer(model:RemoveModel, onSucces:() => void){
    if(!model.player) return;
    console.log(model.player);
    const confirmationIndex = model.player.playerId == playerService.getPlayerId() && model.player.status != PlayerStatus.AdminGuest
    ? 0 : 1;
    const confirmed = await dialogs.Confirmation(strings.Confirmations.DeleteRegistration[confirmationIndex]);
    if(!confirmed) return;
    var isSingleRegistration = !model.player.byDefault;
    return apiRequest.execute(() => isSingleRegistration ? removeSingleRegistration(model) : removeDefaultPlayer(model),
    async () => {
        if(model.player!.playerId && model.player!.playerId != playerService.getPlayerId())
        NotificationService.send({
            arguments:[model.clubName!, new Date(model.date).toLocaleDateString(strings.getLanguage(), {dateStyle:'short'})],
            notificationType:NotificationType.RemovedFromGame,
            toUsers:[model.player!.playerId],
            action:''
        });
        events.trigger<UpdatePlayerListArgs>(eventNames.updatePlayers, {add:false, game:{...model}, registrationId:model.player!.registrationId, record:{playerId:model.player!.playerId, registrationId:model.player!.registrationId} as RegisteredPlayer});
        onSucces();
    });
}

function getRefreshedPlayersList(oldList:RegisteredPlayer[], updateArgs:UpdatePlayerListArgs, game:Game){
    const {maxPlayers:maxPlayers, clubId, date, start} = game;
    const {clubId:currentClub, date:currentDate, start:currentStart} = updateArgs.game;
    if(clubId !== currentClub || currentDate !== date || currentStart !== start)
    return oldList;
    const {record:toRemove} = updateArgs;
    const newList = oldList.filter(x => !(x.registrationId === toRemove!.registrationId && x.playerId === toRemove!.playerId));
    if(!maxPlayers || oldList.length < maxPlayers) return newList;
    newList.forEach(x => {
        if(!x.isRezerv) return;
        const index = newList.indexOf(x);
        x.isRezerv = index >= maxPlayers;
    });
    return newList;
}

function addPlayer(oldList:RegisteredPlayer[], updateArgs:UpdatePlayerListArgs, game:Game){
    const {maxPlayers:maxPlayers, clubId, date, start} = game;
    const {clubId:currentClub, date:currentDate, start:currentStart} = updateArgs.game;
    const alreadyInList = oldList.find(x => x.playerId === updateArgs.record?.playerId && x.status === updateArgs.record?.status && x.registrationId == updateArgs.record?.registrationId);
    if(clubId !== currentClub || currentDate !== date || currentStart !== start || alreadyInList)
    return oldList;
    const {record} = updateArgs;
    var player = {...record!};
    const newList = [...oldList, player];
    if(!maxPlayers || newList.length < maxPlayers) return newList;
    newList.forEach((x, i, array) =>{
        if(x.status < PlayerStatus.Regular) return;
        x.isRezerv = i >= maxPlayers;
    });
    return newList;
}

function removeSingleRegistration(model:RemoveModel){
    return clubService.removeRegistration(model.clubId, model.player!.status == PlayerStatus.AdminGuest, {
        clubId:model.clubId,
        date:model.date,
        start:model.start,
        registrationId:model.player!.registrationId,
        isDefault:model.player!.byDefault
    });
}

function removeDefaultPlayer(model:RemoveModel){
    return clubMemberService.exludeFromGame(model.clubId, {
        clubId:model.clubId,
        date:model.date,
        start:model.start,
        registrationId:model.player!.registrationId,
        isDefault:model.player!.byDefault
    });
}

async function getPlayers(game:Game, onSucces:(result:RegisteredPlayer[]) => void){
    const {clubId, date, start} = game;
    const loadingId = `game${clubId}${date}${start}`;
    loading.start(loadingId);
    await apiRequest.execute(() => gameService.getRegisteredPlayers(game), onSucces);
    loading.stop(loadingId);
}

export default {getPlayers, removePlayer, getRefreshedPlayersList, addPlayer, update, recalculateFees, ChargeFee}