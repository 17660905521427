export default{
    en:{
        Positions:["Point Guard", "Shooting Guard", "Center", "Small Forward", "Power Foward"],
    },
    ro:{
        Positions:["Liderul", "Garda de Tragere", "Centru", "Mijlocaj Lateral", "Mijlocaj Lateral puternic"],
    },
    ru:{
        Positions:["Разыгрывающий защитник", "Атакующий защитник","Центровой", "Лёгкий форвард", "Тяжёлый форвард"],
    }
}