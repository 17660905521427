import { Button } from "@mui/material";
import { ArrowBack, ArrowForward, Check, Close } from "@mui/icons-material";
import React, { useState } from "react";
import { DialogProps } from "./dialogHelpers";
import './index.css';
interface Step{
    onBackward?:() => void,
    onForward:() => void,
    canGoFoward:() => boolean,
    mandatory?:boolean
}
interface DialogHeaderProps<TResult> extends DialogProps<TResult>{
    steps:Step[]
}
export default function DialogHeader<TResult>({steps, resolve}:DialogHeaderProps<TResult>){
    const [stepIndex, setStepIndex] = useState(0);
    const currentStep = steps[stepIndex];
    const isFirst = stepIndex === 0;
    const isLast = stepIndex === steps.length - 1;
    return <div className="dialog-header">
        <Button color="secondary" variant="outlined"
        disabled={isFirst && currentStep.mandatory}
        onClick={() => {
            if(isFirst)
            return resolve(undefined);
            currentStep.onBackward?.();
            setStepIndex(stepIndex - 1);
            
        }}
        >{isFirst ? <Close /> : <ArrowBack />}</Button>
        
        <Button color="primary" disabled={!currentStep.canGoFoward()}
        onClick={() => {
            currentStep.onForward();
            if(!isLast)
            setStepIndex(stepIndex + 1)

        }}
         variant="outlined">{isLast ? <Check /> : <ArrowForward />}</Button>
    </div>
}