import React, { useEffect, useState } from "react";
import UnreadedMessages from "../../entities/unreadedMessages";
import viewModels from "../../viewModels";
import events from "../../shared/events";
import { eventNames } from "../../constants";
import UnreadedMessagesContext from "../../context/unreadMessageContext";
import playerService from "../../services/serverServices/playerServices";

function ChatHighOrderComponent({children}:{children:React.ReactElement}){
    const [unreaded, setUnreaded] = useState<UnreadedMessages>({importantMessages:0, otherMessages: 0});
    useEffect(() => {
        if(playerService.isLoggedIn())
        viewModels.chat.getUnreaded(setUnreaded);
        events.on(eventNames.chatUnreadeCountChanged, chatUnreadedCountChanged);
        return () =>{
        events.off(eventNames.chatUnreadeCountChanged, chatUnreadedCountChanged);
        }
    }, []);

    const chatUnreadedCountChanged = (e:any) =>{
        const changes = e.detail as UnreadedMessages;
        let changed = false;
        setUnreaded(x => {
            if(changed) return x;
            changed = true;
            x.importantMessages += changes.importantMessages;
            x.otherMessages += changes.otherMessages;
            return {...x};
        })
    }

    return <UnreadedMessagesContext.Provider value={{unreadedMessages:unreaded}}>{children}</UnreadedMessagesContext.Provider>
}
export default ChatHighOrderComponent;