import { Fab } from "@mui/material";
import { ChatBubble } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { eventNames, routers } from "../../constants";
import './chatIcon.css'
import UnreadedMessages from "../../entities/unreadedMessages";
import viewModels from "../../viewModels";
import events from "../../shared/events";
import Chat from "../../entities/chat";
import UnreadedMessagesContext from "../../context/unreadMessageContext";

export default function ChatIcon(){
    const history = useNavigate();
    const {unreadedMessages} = useContext(UnreadedMessagesContext);

    return <Fab size='small' variant='circular' className='help-icon chat-icon' onClick={() => {history(routers.chat);
    events.trigger(eventNames.menuItemClicked, {})}}>
        <ChatBubble />
        {unreadedMessages.importantMessages > 0 && <p className="chat-icon-message-indicator important">{unreadedMessages.importantMessages}</p>}
        {unreadedMessages.otherMessages > 0 && <p className="chat-icon-message-indicator others">{unreadedMessages.otherMessages}</p>}
        </Fab>
}