export default
{
 en:{
    "name.string.pattern.base":"Name can not contain numbers",
    "password.string.pattern.base":"Password should cotain lower case, upper case, symbols and numbers",
    "password.string.min":"Password should be longer then 8 characters",
    "repeatedPassword.any.only":"Passwords not do match",
    "maxPlayers.number.min":"Value cannot by less then 0",
    "start.string.min":"Can't create game in the past",
    "end.string.min":"End time should be grater then start time",
    "openRegularsRegistrationHours.number.min":"Regulars should be able to register before guests",
    "penaltyForRemovingSameDay.number.max":"Too harsh",
    "penaltyForNotComming.number.min":"Too soft",
    "penaltyForNotComming.number.max":"Too harsh"
 },
 ro:{
    "name.string.pattern.base":"Nume nu poate conține cifre",
    "password.string.pattern.base":"Parola trebuie să conțină majuscule, minuscule, cifre și simboluri",
    "password.string.min":"Parola trebuie să fie cel puțin 8 caractere",
    "repeatedPassword.any.only":"Parolele nu coincid",
    "maxPlayers.number.min":"Valoarea nu poate fi mai mica ca 2",
    "fee.number.min":"Valoarea nu poate fi mai mica ca 0",
    "start.string.min":"Nu se poate crea un joc în trecut",
    "end.string.min":"Ora de finisare trebuie sa fie mai mare ca ora de incepere",
    "openRegularsRegistrationHours.number.min":"Memberii ar trebui să se poată înregistra înaintea de invitați",
    "penaltyForRemovingSameDay.number.max":"Pre dur",
    "penaltyForNotComming.number.min":"Pre moale",
    "penaltyForNotComming.number.max":"Pre dur"
 },
 ru:{
    "name.string.pattern.base":"Имя не может содержать цифры",
            "password.string.pattern.base":"Пароль должен содержать прописные и строчные буквы, цифры и символы",
            "password.string.min":"Пароль должен быть не менее 8 символов",
            "repeatedPassword.any.only":"Пароли не совпадают",
            "maxPlayers.number.min":"Значение не может быть меньше 2",
            "fee.number.min":"Значение не может быть меньше 0",
            "start.string.min":"Не можете создать игру в прошлом",
            "end.string.min":"Время окончания должно быть больше времени начала",
            "openRegularsRegistrationHours.number.min":"Члены должны иметь возможность зарегистрироваться раньше гостей",
            "penaltyForRemovingSameDay.number.max":"Слишком сурово",
            "penaltyForNotComming.number.min":"Слишком мягка",
            "penaltyForNotComming.number.max":"Слишком сурово"
 }
}