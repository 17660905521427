import React from 'react';
import { Membership} from '../../dialogs';
import open from '../../dialogs/ui/dialog';
import { toast } from 'react-toastify';
import AppButton from '../../shared/ui/appButton';
import localizationService from '../../services/localizationService';
import strings from '../../services/localizationService';
import dialogs from '../../components/dialogs';

export const RequestMemberShip = () =>{
    const memberShip = async () => {
        const result = await open(Membership);
        if(result)
        {
            await dialogs.Info(`${strings.Confirmations.AddedToClub}  ${strings.Statuses[result]}`);
            window.location.reload();
        }
    }
return <AppButton label={localizationService.MemberShip} color='primary' variant='contained' onClick={memberShip}/>
}