import React, { useEffect, useState } from "react";
import MemberClub from "../../entities/memberClub";
import './index.css'
import viewModels from "../../viewModels";
import { Button } from "@mui/material";
import dialogs from "../../components/dialogs";
import strings from "../../services/localizationService";
import { SportType } from "../../entities/enums";
import { InfoOutlined } from "@mui/icons-material";
function MemberClubs({items}:{items:MemberClub[]}){
    return <div className="member-club-container">
        {items.length === 0 && <p className="no-clubs">{strings.Placeholders.ListEmpty}</p>}
        {items.length > 0 && items.map(x => <MemberClubItem key={x.clubName} item={x}/>)}
    </div>
}
const SportsLabel = Object.values(SportType).filter(x => typeof x === "string").map(x => x as string);
function MemberClubItem({item}:{item:MemberClub}){
    const [quit, setQuit] = useState(item.quit);
    const [confirmationTimeout, setConfirmationTimeout] = useState(item.confirmationTimeout);
    const text = quit ? strings.Placeholders.Return : strings.Placeholders.Quit;
    const timeoutLabel = confirmationTimeout ? `${confirmationTimeout} ${strings.Placeholders.Minutes}` : strings.Placeholders.Disabled;
    
    return <div className="member-club-item card">
        <img src={item.picture ?? require(`../../assets/${SportsLabel[item.sportType].toLowerCase()}_${item.clubId % 5 +  1}.jpg`)} />
        <div>
        <p className="member-club-item-title">{item.clubName}</p>
        <Button color={quit ? "primary" : "secondary"} variant="outlined"
        onClick={async() => {
            const confirmation = await dialogs.Confirmation(strings.Confirmations.Default);
            if(!confirmation) return;
            viewModels.members.toggleQuit(item.clubId, () =>{
                setQuit(!quit);
            })
        }}>{text}</Button>
        {!quit && <div className="confirmationTimeout">
        <Button color="primary" variant="outlined"
        onClick={async () =>{
            const newConfirmation = await dialogs.TimeoutConfirmation({...item, confirmationTimeout}).show();
            if(!newConfirmation) return;
            if(!newConfirmation.confirmationTimeout &&
                !await dialogs.Confirmation(strings.Confirmations.ConfirmationTimeout)
            )
            return;
            await viewModels.members.setConfirmationTimeout(item.clubId, () => setConfirmationTimeout(newConfirmation.confirmationTimeout), newConfirmation.confirmationTimeout);
        }}>
                {strings.Placeholders.ChangeTimeout} ({timeoutLabel})
            </Button>
            <em onClick={() =>{
            dialogs.Info(strings.Placeholders.ConfirmationTimeout);
        }}><InfoOutlined /></em>
        </div>}
        </div>
    </div>
}

export default MemberClubs;