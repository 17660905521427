import { HubConnectionBuilder, HubConnection, HubConnectionState } from '@microsoft/signalr'
import { eventNames } from '../constants';
import events from '../shared/events';

let connection: HubConnection;
export let connectionId:string;
export function construct(){
    build();
    setInterval(build, 60000*60);
}

export async function close(){
    if(connection.state !== HubConnectionState.Connected) return;
    connection.off("Listen", (result) => events.trigger(eventNames.messageReceived, result));
    connection.off("ChatListChanged", (result) => events.trigger(eventNames.chatListChange, result));
    connection.off("DeleteMessage", (result) => events.trigger(eventNames.deleteMessage, result));
    await connection.stop();
}

function build() {
    connection = new HubConnectionBuilder().withUrl(`${process.env.REACT_APP_API_URL}/chatHub`)
        .build();
    connection.onclose(e => setTimeout(listen, 5000));
    connection.on("Listen", (result) => {
        events.trigger(eventNames.messageReceived, result);
    });
    connection.on("DeleteMessage", (result) => events.trigger(eventNames.deleteMessage, result));
    connection.on("ChatListChanged", (result) => events.trigger(eventNames.chatListChange, result));
    listen();
}

async function listen() {
    try{
        await connect()
    }catch(error){
        setTimeout(listen, 5000)
    }
}

function connect() {
        return connection.start();
}