import React, { useContext } from "react";
import './index.css';
import GameContext from "../../context/gameContext";

const indicatorSpots = 12;
const spots = Array.from({length:indicatorSpots}, (v, k) => k);
const ParticipateColor = "#B1FDB4";
const ReservColor = "#F08C8C";

export function FillIndicators(){
    const {game, registeredPlayers} = useContext(GameContext);
    const totalRegistered = game.cancelled ? 0 : registeredPlayers.length;
    const allowedLimitForGame = game.maxPlayers;
    let indicatorsCount = +(totalRegistered/ indicatorSpots).toFixed();
    if(totalRegistered % indicatorSpots !== 0)
    indicatorsCount += 1;
    if(indicatorsCount < 1)
    indicatorsCount = 1;
    const fillIndicators = Array.from({length: indicatorsCount}, (v, k) => {
        const fillCount = totalRegistered - indicatorSpots * k;
        const allowedSpotsCount = allowedLimitForGame - indicatorSpots * k;
        return {fillCount, allowedSpotsCount}
    });
    return <>{fillIndicators.map((x,i) => <FillProgress key={i} {...x} />)}</>
}

interface FillProgressProps{
    fillCount:number,
    allowedSpotsCount:number
}

function FillProgress({fillCount, allowedSpotsCount}:FillProgressProps){
    return <div className="fill-progress">
        {spots.map((x, i) => <div key={i} className="spot" style={{background: fillCount > i ? (allowedSpotsCount > i ? ParticipateColor : ReservColor) : "gray"}} />)}
    </div>
}