import React, { useContext, useEffect, useState } from 'react';
import strings from '../../../services/localizationService';
import Fab from '@mui/material/Fab';
import { Remove, Add, Edit, Schedule, CalendarToday } from '@mui/icons-material/';
import ClubAgenda from '../../../entities/clubAgenda';
import { Typography } from '@mui/material';
import Club from '../../../entities/club';
import ClubSettings from '../../../entities/clubSettings';
import openDialog from '../../../dialogs/ui/dialog';
import AppButton from '../../../shared/ui/appButton';
import { useNavigate } from 'react-router-dom';
import { routers } from '../../../constants';
import localizationSerivce from '../../../services/localizationService';
import './index.css';
import Model from './model';
import AgandaItem from './club-list-agenda';
import Dialogs from '../../../components/dialogs';
import ClubContext from '../../../context/clubContext';

export const ClubItemlist = ({club, deleteClub}:{club:Club, deleteClub:(clubId:number) => void}) => {
    const [internalClub, setClub] = useState(club);
    const {name, id,
        address,
        clubType, scope, guestCodeAccess,
        codeAccess, isPublic, maxPlayers,
        minPlayers, picture } = internalClub;
    const[schedule, setSchelude] = useState(internalClub.agendas);
    const history = useNavigate();

    const addAgendaItem = async (clubId: number) => {
        const result = await Dialogs.AgendaDialog.show();
        if(result)
        await Model.addAgenda(clubId, result, (item) => setSchelude([...schedule, item]));
    };

    const removeAgendaItem = async (clubId: number, agendaId: number) => {
        const confirmed = await Dialogs.Confirmation(strings.Confirmations.Default);
        if(!confirmed) return;
        await Model.removeAgendaItem(clubId, agendaId, () => setSchelude(schedule.filter(x => x.id !== agendaId)));
    };

    const viewMembers = () => {
        history(routers.members.replace(":id", id.toString()));
    }

    const addMembers = () => {
        history(routers.addMembers.replace(":id", id.toString()));
    }

    const createGame = async () =>{
        const result = await Dialogs.GameDialog.show();
        await Model.createGame(id, result);
    };

    const editClub = async () =>{
        const modifiedClub = await Dialogs.Club.Modify(internalClub).show();
        if(modifiedClub)
        setClub(modifiedClub);
    }

    return <div className='club-item-container'>
        <div className='club-item-header'>
            <Typography variant="h5" style={{ width: "100%"}}>{name}</Typography>
            <Fab style={{ position: "absolute", right: "-3%" }} className='club-manipulation' onClick={() => deleteClub(id)}><Remove /></Fab>
            <Fab style={{ position: "absolute", left: "-3%" }} className='club-manipulation' onClick={editClub}><Edit /></Fab>
        </div>
        <div className='club-item-body'> 
        {picture && <img src={picture} />}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", flexWrap: "wrap" }}>
                <Typography  variant="h6">{localizationSerivce.Address} {address}</Typography>
                <Typography variant="h6">{strings.Type}: {strings.Volleyball.Types[clubType]}</Typography>
                <Typography variant="h6">{strings.Scope}: {strings.ClubScopes[scope]}</Typography>
                <Typography variant="h6">{localizationSerivce.MaxPlayers} {maxPlayers}</Typography>
                <Typography variant="h6">{localizationSerivce.MinPlayers} {minPlayers}</Typography>
                {codeAccess && <Typography variant="h6">{localizationSerivce.MemberCode} {codeAccess}</Typography>}
                {guestCodeAccess && <Typography variant="h6">{localizationSerivce.GuestCode} {guestCodeAccess}</Typography>}
            </div>
            <div className='club-schedule'>
                <div className='schedule-header' onClick={() => addAgendaItem(id)}>
                <Typography variant="h5" align='center'>{strings.Add}</Typography>
                <CalendarToday />
                </div>
                
                {schedule?.map(a => <AgandaItem key={a.id} item={a} remove={removeAgendaItem} clubId={id} />)}
            </div>
        </div>
        
        <div style={{ display: "flex", flexDirection:"row", justifySelf:"center", alignSelf:"center"}}>
            <AppButton label={localizationSerivce.ViewMembers} color="primary" variant="contained" style={{ marginRight:5}}
        onClick={viewMembers}/>
        {!isPublic && <AppButton label={localizationSerivce.Add + ' ' + localizationSerivce.ViewMembers.split(' ')[1]} color="primary" variant="contained" style={{ marginRight:5}}
        onClick={addMembers}/>}   
        <AppButton label={localizationSerivce.CreateGame} color="primary" variant="contained"  style={{ marginLeft:5}}
        onClick={createGame}/>
            </div>
    </div>;
}