import { Button } from "@mui/material";
import React, { useRef } from "react";
import { toast } from "react-toastify";
import strings from "../../services/localizationService";
import './index.css';

export default function FileUploader({title, image, onImageSelected}:{title:string, image?:string, onImageSelected:(file:File, newUrl:string) => void}){
    const fileInput = useRef<HTMLInputElement>(null);
    const selectFile = () => {
        fileInput.current?.click();
    };
    const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = e.target.files?.item(0);
            if (file) {
                onImageSelected(file, URL.createObjectURL(file));
            }
        } catch (error) {
            toast.error(strings.GenericError);
        }
    }
    
    return <div className="image-uploader">
        <Button variant="contained" color="primary"
    style={{ marginBottom: 15, marginTop: 15 }}
    onClick={selectFile}>{title}</Button>
<input type="file" accept="image, .jpg, .png" style={{ display: "none" }} ref={fileInput} onChange={onFileSelected} />
{image && <img src={image} />}
        </div>
}