import { Positions, SportType } from "../../entities/enums";
import Player from "../../entities/player";
import SportSection from "../../entities/sportSection";
import ProfileService from "../interfaces/profileService";
import http from "./http";
import playerService from "./playerServices";
const basepath = "/profile";
function get(id:number): Promise<Player>{
    return http.getWithCaching({cacheKey:`info/${id}`, cacheName:'player'}, `${basepath}/${id}`);
}

function update(model:any) : Promise<Player>{
    return http.put(basepath, model);
}

function updatePositions(sportType:SportType, newPositions:number[]){
    return http.post(`${basepath}/positions/${sportType}`, newPositions);
}

function getTelegramCode():Promise<string>{
    return http.get(`${basepath}/telegramCode`);
}
function getAll():Promise<Player[]>{
    return http.get(`${basepath}`);
}
function updateInteres(sports:SportType[]){
    return http.post(`${basepath}/sports`, sports)
}
function getSportsInfo(playerId:number):Promise<SportSection[]>{
    return http.getWithCaching({cacheKey:`sportInfo/${playerId}`, cacheName:"player"}, `${basepath}/sportsInfo/${playerId}`);
}
const implementation: ProfileService = {
    get,
    update,
    updatePositions,
    getTelegramCode,
    getAll,
    updateInteres,
    getSportsInfo
}

export default implementation;