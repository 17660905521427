export enum Skills{
    Serve,
    Receive,
    Set,
    Hit,
    Block
}

export enum SportType{
    ClassicVolleyball,
    BeachVolleyball,
    Soccer,
    Basketball
}

export enum ClubScope{
    Game,
    Training,
    Both
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6

}
export enum GuestsRegistrationStrategy
{
    HoursBeforeGame,
    Manual
}
export enum PlayerLevel{
    Unknown,
    Beginner,
    Medium,
    Advanced
}
export enum PlayerStatus
{
    Administrator,
    AdminGuest,
    Regular,
    Guest,
    Blocked
}
export enum GameRotation{
    NoSpecificPositions,
    Rotation_4_2,
    Rotation_5_1
}

export enum Positions{
    Setter,
    OutsideHitter,
    MiddleBlocker,
    OppositeHitter,
    Libero
}

export enum NotificationType{
    GameCancelled,
        GameReOpened,
        RegistrationOpened,
        NowActivePlayer,
        NowRezervedPlayer,
        RemovedFromGame
}

export enum ChatModificationType{
    Add,
    Remove
}
export enum WalletOperation{Supply, Withdraw}
export enum ChatLevel{
    App,
    Sport,
    Club,
    Secret,
    Private
}

export enum ClubAccesability{
    Private,
    Public
}

export enum ClubPayment{
    Free,
    PerGame,
    PerPerson
}
export enum ClubOption{
    Admin,
    Member
}

export enum PaymentType{
    ByEvent,
    ByHour
}

export enum RegularsRegistartionType{
    Anytime,
    HoursBeforeStart
}

export enum AdjustType{
    None,
    Initiation,
    Replace,
    Cancel
}