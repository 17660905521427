import React, { useEffect, useState } from 'react';
import Game from '../../entities/game';
import { Button, Table, TableBody, TableContainer, Typography } from '@mui/material';
import AppButton from '../../shared/ui/appButton';
import localizationService from '../../services/localizationService';
import './index.css';
import Model from './model';
import http from '../../services/serverServices/http';
import {Item} from '../../features/game-list-item'
import { RequestMemberShip } from '../../features/requestMembership';
import { CalendarToday } from '@mui/icons-material';
import { Schedules } from '../../features/schedules';
import { GamesContext } from '../../context/gameContext';
import strings from '../../services/localizationService';
import { PlayerStatus } from '../../entities/enums';
import { DropDown } from '../../widgets/drop-down';
import cacheManager from '../../cacheManager';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import AppOptions from '../../widgets/app-options';
import GamesCalendar from '../../features/games-calendar';

const GamesPage = () => {
    const [games, setGames] = useState([] as Game[]);
    const [schedulesOpened, setSchedulesOpened] = useState(false);
    const [hasMoreToLoad, setHasMoreToLoad] = useState(false);
    const [displayNoGames, setDisplayNoGames] = useState(false);
    const [pageModel, setPageModel] = useState(Model.getNexPage());
    const [gameFilterOption, setGameFilterOption] = useState(1);
    const [selectedDate, setSelectedDate] = useState<Date>();
    Model.setPageModel(pageModel);

    const loadMore = async () =>{
        var newPageModel = Model.getNexPage(games);
        setPageModel(newPageModel);
        await Model.loadGames((result) =>{
            const newGames = [...games, ...result];
            setGames(newGames)
            cacheManager.storeCacheData("game", "games", newGames);
        });
    }

    useEffect(() => {
        Model.loadGames((games) =>{
            setGames(games);
            cacheManager.storeCacheData("game", "games", games);
            setDisplayNoGames(games.length === 0);
            
            setHasMoreToLoad(games.length > 0);
        }, () =>{ setDisplayNoGames(true);
        });
        return () =>{
            http.cancelRequest();
        }
    }, []);
    const filtered = gameFilterOption === 1 ? games.filter(x => x.requestedPlayerStatus === PlayerStatus.Administrator ||(x.canRegister && !x.cancelled)) : games;

    return <GamesContext.Provider value={{games:filtered, updateGames:setGames}}>
 <div className="gamePage">
    <AppOptions optionType='GamesFilter' defaultValue={gameFilterOption} onStateChanged={setGameFilterOption} />
    <div>

    <GamesCalendar setSelectedDate={setSelectedDate} selectedDate={selectedDate}
    disableTitle={({activeStartDate, date, view}) => filtered.find(x => {
        const gameDate = new Date(x.date);
        return gameDate.valueOf() == date.valueOf();
    }) === undefined} />
    </div>
        <div className='gamepage-header'>
        <RequestMemberShip />
        <Button color='inherit' onClick={() => setSchedulesOpened(!schedulesOpened)} endIcon={<CalendarToday />}>
            {strings.GamesAgenda}
        </Button>
        {hasMoreToLoad && <AppButton label={localizationService.LoadMore}  variant="contained" color="primary" onClick={loadMore}/>}
        </div>
        <Schedules opened={schedulesOpened} />
        {selectedDate && <GameListView games={filtered.filter(x => new Date(x.date).valueOf() === selectedDate.valueOf())} />}
            {!selectedDate && <>
        <GameListViewByStatus games={filtered} status={PlayerStatus.Administrator} />
        <GameListViewByStatus games={filtered} status={PlayerStatus.Regular} />
        <GameListViewByStatus games={filtered} status={PlayerStatus.Guest} />
            </>}
        {displayNoGames && <Typography style={{alignSelf:"center", marginTop:40, width:"100%"}} variant="h4"> {localizationService.NoGames} </Typography>}
        
    </div>
    </GamesContext.Provider>;
}

function GameListView({games, title}:{games:Game[], title?:string}){
    const haveGames = games?.length > 0;
    const displyDropDown =haveGames && !!title; 
    return displyDropDown ? <DropDown 
    title={title}
    children={<TableContainer>
        <Table>
            <TableBody>{games?.map(g => <Item game={g} key={`${g.clubId}${g.date}${g.start}`} />)}</TableBody>
            </Table>
        </TableContainer>}
    /> : (haveGames ? <TableContainer>
        <Table>
            <TableBody>{games?.map(g => <Item game={g} key={`${g.clubId}${g.date}${g.start}`} />)}</TableBody>
            </Table>
        </TableContainer> : <></>)
}

function GameListViewByStatus({games, status}:{games:Game[], status:PlayerStatus}){
    const filteredGames = games?.filter(x => x.requestedPlayerStatus === status);
return <GameListView games={filteredGames} title={`${strings.GamesListLabel} ${strings.Statuses[status]} (${filteredGames.length})`} />
}

export default GamesPage;