import { eventNames } from "../../constants";
import Club from "../../entities/club";
import ClubSettings from "../../entities/clubSettings";
import clubService from "../../services/clubService";
import ApiRequest from '../../shared/apiRequest'
import events from "../../shared/events";

async function getClubs(onSuccess:(items: Club[]) => void, onFailer:() => void){
    return ApiRequest.execute(() => clubService.get(), onSuccess, onFailer);
}
async function deleteClub(id:number, onSuccess:(items: Club[]) => void, onFailer:() => void){
    return ApiRequest.execute(() => clubService.delete(id), onSuccess, onFailer);
}
async function modifyClub(id:number, onSuccess:(settins:ClubSettings) => void){
    return ApiRequest.execute(() => clubService.getSettings(id), onSuccess);
}

function addClub(newClub:Club){
    events.trigger<Club>(eventNames.addClub, newClub);
}
export default {getClubs, deleteClub, modifyClub, addClub};