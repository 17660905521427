import React, { useContext } from "react";
import UserContext from "../../context/userContext";
import playerService from "../../services/playerServices";
import './index.css'
import { Button } from "@mui/material";
import strings from "../../services/localizationService";
import TelegramCode from "../telegram-code";
import NotificationGroups from "../notification-group";
import EmailSubscription from "../email-subscription";
import { useNavigate } from "react-router-dom";
import { routers } from "../../constants";
import open from "../../dialogs/ui/dialog";
import { UpdateProfileDialog } from '../../dialogs';
import viewModels from "../../viewModels";
import dialogs from "../../components/dialogs";

export function UserActions(){
    const history = useNavigate();
    const {player, updatePlayer} = useContext(UserContext);
    const isOwner = player.id === playerService.getPlayerId();
    const onClick = async () =>{
        var result = await open(UpdateProfileDialog(player));
        if(result)
        updatePlayer(result);
    }
    return <div className="user-actions-conatiner">
        <Button className="user-action-button" onClick={onClick}>{strings.Update} {strings.Home}</Button>
        <TelegramCode />
        <NotificationGroups />
        <EmailSubscription />
        <Button className="user-action-button" onClick={async () =>{
            await viewModels.club.getWallets(async (result) =>{
                await dialogs.UserWallets(result).show();
            })
        }}>{strings.Placeholders.Points}</Button>
        <Button className="user-action-button delete-profile" onClick={() => history(routers.deleteProfile)}>{strings.Placeholders.DeleteProfile}</Button>
    </div>
}