import AuthResult, { SocialAuthResult } from '../../models/authResult';
import RegistrationModel, { LoghinModel } from "../../models/registrationModel";

export default interface AuthService
{
    socialPlatform:(socialToken:string, platform:SocialPlatform) => Promise<SocialAuthResult>,
    emailRegistration:(model:RegistrationModel) => Promise<AuthResult>,
    emailLogin:(model:LoghinModel) => Promise<AuthResult>,
    logout:() => Promise<any>,
    reguestResetPassword:(email:string) => Promise<boolean>,
    resendConfirmation:(email:string) => Promise<unknown>,
    reset:(email:string, passowrdToken:string, newPassword:string) => Promise<AuthResult>,
    deleteAccount:() => Promise<unknown>
}

export enum SocialPlatform{
    Facebook, Google, Telegram
}