import React from 'react';
import Game from '../entities/game';
import Club from '../entities/club';
import TeamMember from '../entities/teamMember';
import Team from '../entities/team';
import { GameData } from '../shared/eventArgs';
export interface AdustProps{
    adjust:boolean,
    setAdjust:(adjust:boolean) => void
    changeMember?:TeamMember,
    setChangeMember:(changeMember?:TeamMember) => void,
    onReplace:(changeMember:TeamMember) => void,
    canAddToTeam:(team:Team) => boolean,
    storeTeams:() => void,
    addToTeam:(team:Team) => void,
    hasPreviosAdjustment:boolean
}

const AdjustContext = React.createContext({} as AdustProps);
AdjustContext.displayName = "AdjustContext";

export default AdjustContext;