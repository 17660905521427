import React, { useEffect, useState } from "react";
import Game from "../../entities/game";
import RegisteredPlayer from "../../entities/registeredPlayer";
import {CountDisplayView, CountIndicator} from './count-indicator';
import { Header } from "./header";
import { Players } from "./players";
import { Time } from "./time";
import Loadable from "../../shared/ui/loadable";
import viewModels from "../../viewModels";
import strings from "../../services/localizationService";
import { RegisterOrRemoveFromGame } from "../addOrRemoveFromGame";
import events from "../../shared/events";
import { eventNames } from "../../constants";
import { UpdatePlayerListArgs } from "../../shared/eventArgs";
import GameContext from "../../context/gameContext";
import SuggestedTeams from "../suggested-teams";
import { RegisterGuest } from "../registerGuest";

export function Item({game}:{game:Game}){
    const [players, setPlayers] = useState<RegisteredPlayer[]>(game.registeredPlayers);
    const [item, setItem] = useState<Game>(game);
    useEffect(() =>{
        events.on(eventNames.updatePlayers, onPlayersListChanged);
    }, []);

    const onPlayersListChanged = (e:any) =>{
        const args = e.detail as UpdatePlayerListArgs;
        if(args.add)
        setPlayers(x => viewModels.game.addPlayer(x, args, game));
        else
        setPlayers(x => viewModels.game.getRefreshedPlayersList(x, args, game));
    }

    const updateGame = (newState:Game) =>{
        setItem(newState);
        if(newState.cancelled)
        setPlayers([]);
    }

    const participates = players.filter(x => !x.isRezerv);
    const reserv = players.filter(x => x.isRezerv);
    
    return <GameContext.Provider value={{game:item, registeredPlayers:players, updateGame }}><tr className="game-list-item card-parent">
        <td>
        <Header />
        </td>
        <td className="body">
            <div className="game-footer-picture">
                <CountDisplayView />
            </div>

            <div className="game-list-item-deatils">
            <Time />
            {!item.cancelled && <div className="game-list-item-buttons">
         {item.canRegister && <RegisterGuest />}
            <SuggestedTeams />
            <RegisterOrRemoveFromGame />
            </div>}
            </div>
            {!item.cancelled && <Loadable
            id={`game${game.clubId}${game.date}${game.start}`}
            View={() =>
                <div className="players">
                <Players participates={participates} title={strings.Participants} />
                <Players participates={reserv} title={strings.Rezerv}  />
            </div>
            }
        />}
        {item.cancelled && <div className="game-cancel"><p>{strings.Cancelled}</p></div>}
           <CountIndicator />
        </td>
    </tr></GameContext.Provider>
}