import React, { useEffect, useState } from "react";
import IDialog from "../dialogs";
import Wallet, { NamedWallet } from "../../../entities/wallet";
import { DialogProps, open } from "../dialogHelpers";
import '../index.css'
import './index.css'
import DialogHeader from "../dialog-header";
import { Typography } from "@mui/material";
import { InputWithValidation } from "../../../shared/ui/AppFormHelper";
import strings from "../../../services/localizationService";
import { WalletOperation } from "../../../entities/enums";
import AppOptions from "../../../widgets/app-options";
import WalletModel from "../../../models/walletModel";
import viewModels from "../../../viewModels";
import Game from "../../../entities/game";
import { GameData } from "../../../shared/eventArgs";
import Loadable from "../../../shared/ui/loadable";
import loading from "../../../shared/ui/loadable/loading";

export function ClubWallet(wallet:Wallet):IDialog<WalletModel>{
    return {
        show:() => open((resolve) => <WalletView resolve={resolve.resolve} input={wallet} />)
    }
}

export function RegisteredPlayerWallet(clubId:number, playerId:number):IDialog<WalletModel>{
    return {
        show:() => open((resolve) => <RegisteredWalletView clubId={clubId} playerId={playerId} resolve={resolve.resolve} />)
    }
}

export function UserWallets(wallets:NamedWallet[]):IDialog<boolean>{
    return {
        show:() => open((resolve) => <UserWalletsView resolve={resolve.resolve} input={wallets} />)
    }
}
interface RegisteredProps extends DialogProps<WalletModel>{
    playerId:number,
    clubId:number
}
interface Props extends DialogProps<WalletModel>{
    input:Wallet
}

function RegisteredWalletView({resolve, playerId, clubId}:RegisteredProps){
    const [wallet,setWallet] = useState<Wallet>({clubMemberId:playerId, clubId, points:0});
    useEffect(() =>{
        loading.start("wallet");
        viewModels.members.getMember(clubId, playerId, setWallet);
        loading.stop("wallet");
    }, []);
    return <Loadable
    View={() => <WalletView input={wallet} resolve={resolve} />}
    id="wallet"
    ></Loadable>
}

function WalletView({resolve, input}:Props){
    const [value, setValue] = useState(0);
    const [operation, setOperation] = useState(WalletOperation.Supply);
    const updatedValue = operation === WalletOperation.Supply ? input.points + value : input.points - value;
    return <div className="dialog-view">
        <Typography color="primary" className="dialog-title amount-label">{strings.Placeholders.CurrentBalance}: {input.points}</Typography>
        <Typography color="secondary" className="dialog-title amount-label">{strings.Placeholders.AfterModification}: {updatedValue}</Typography>
        <AppOptions optionType="Wallet"
        onStateChanged={(newState) => setOperation(newState)} />
        <InputWithValidation 
        errorMessage=""
        header={strings.Modify}
        textFieldProps={{type:"number",
        value:value,
        onChange:(e) => setValue(+e.target.value)}}
        />
        <DialogHeader 
        resolve={resolve}
        steps={[{
            canGoFoward:() => !!value,
            onForward:() => resolve({
                points:value,
                clubId:input.clubId,
                clubMemberId:input.clubMemberId,
                updateValue:updatedValue,
                operationType:operation
            })
        }]}
        />
    </div>
}

interface UserWalletProps extends DialogProps<boolean>{
    input:NamedWallet[]
}

function UserWalletsView({input, resolve}:UserWalletProps){
return <div className="dialog-view">
    {input.map(x => <p key={x.name} className="user-wallet">
        {`${x.name}: ${x.points} ${strings.Placeholders.Points.toLowerCase()}`}
    </p>)}
    <DialogHeader 
        resolve={resolve}
        steps={[{
            canGoFoward:() => true,
            onForward:() => resolve(true)
        }]}
        />
</div>
}