export default{
    en:{
        RegisterToGame: "Register to game",
        RegistrationNotAvailable:"Registration Not Avialable yet",
        TaxPerPlayer: "Tax for this game is:",
        TaxDynamicMessage:"(Value is changing based on nr of active players)",
        Location: "Location:",
        Administrator: "Administrator:",
        ForQuestion: "For questions: ",
        Unregister: 'Unregister from game',
        NoGames:"No Available games",
        FreeGame:"This game is free",
        LoadMore:"Load more",
        GuestName:"Guest Name",
        RegisterGuest:"Register Guest",
        Rezerv:"In Reserv",
        NoPlayerYet: "No players yet",
        Extra: "Extra",
        CancelGame:"Cancel Game",
        RegistationWillOpenIn:"Until the opening of registration remained ",
        DaysForms:["day", "days"],
        RezervNotification:`You are in rezerv. If any active player will cancel their registration and no more rezerv players before you - your status will change to Active player and you can come to game.
         Please Note that when you are moved to active player list and you will not confirm your participation in given time({0}) - you will be removed from the list.
         We will send you notification to confirm your participation - so please make sure that you have at least one method that allow us to send notifications(email,telegram bot). For any questionn - contact us: inf@play-all-sports.com.
        You can change or cancel timeout in Clubs Page under Club Members tab or by clicking the button below`,
        DisableAutoCancellation:"Disable Auto-Cancellation",
        EnableAutoCancellation:"Enable Auto-Cancellation",
        ViewOnMap:"View on Map",
        Participants:"Participants",
        DefaultParticipation:"Default Participation",
        Admins:"Admins",
        ViewAll:"View All",
        Paid:"Payed",
        OpenRegistrationReminder:"At the moment only members can register. Registration for guests should be opened by you",
        PaymentDetails:"View Payment Status",
        GamesListLabel:"Events where you are",
        ChargeFee:"Charge the Fee",
        SamePositions:"Players should play on same positions",
        ShowCalendar:"Show Events Calendar",
        HideCalendar:"Hide Events Calendar"
    },
    ro:{
        NoPlayerYet: "Nu sunt jucători înregistrați",
        RegistrationNotAvailable:"Înregistrarea nu este încă disponibilă",
        Extra: "Extra",
        RegisterToGame: "Înregistrează-te la joc",
        TaxDynamicMessage:"(Valoarea se schimbă în funcție de numărul de jucători activi)",
        TaxPerPlayer: "Taxa pentru joc:",
        Location: "Locația:",
        Administrator: "Administrator:",
        ForQuestion: "Pentru întrebări: ",
        Unregister: 'Șterge înregistarea',
        LoadMore:"Încarcă mai mult",
        NoGames:"Nu sunt jocuri disponibile",
        FreeGame:"Acest joc e gratis",
        GuestName:"Nume invitat",
        RegisterGuest:"Înregistrează invitat",
        Rezerv:"În rezervă",
        CancelGame:"Anulează jocul",
        RegistationWillOpenIn:"Până la deschiderea înregistrării a rămas ",
        DaysForms:["zi", "zile"],
        RezervNotification:`Ești în rezervă. Dacă vreun jucător activ își va anula înregistrarea și nu mai sunt jucători rezervi înaintea ta - statutul tău se va schimba în Jucător activ și poți veni la joc. Vă rugăm să rețineți că atunci când sunteți mutat în lista de jucători activi și nu vă veți confirma participarea în intervalul de timp({0})
         - veți fi eliminat din listă.
        Vă vom trimite o notificare pentru a vă confirma participarea - așa că vă rugăm să vă asigurați că aveți cel puțin o metodă care ne permite să trimitem notificări (e-mail, telegram bot). Pentru orice întrebare - contactați-ne: inf@play-all-sports.com/
        Puteți anula timeout în Pagina Cluburi, fila Cluburi de Membru sau făcând clic pe butonul de mai jos`,
        DisableAutoCancellation:"Dezactivați anularea automată",
        EnableAutoCancellation:"Activați anularea automată",
        ViewOnMap:"Vezi pe harta",
        Participants:"Participanți",
        DefaultParticipation:"Participare implicită",
        Admins:"Admini",
        ViewAll:"Vedeți toți",
        Paid:"A Plătit",
        OpenRegistrationReminder:"În acest moment, doar membrii se pot înregistra. Înregistrarea pentru oaspeți ar trebui să fie deschisă de dvs.",
        PaymentDetails:"Vedeți starea plății",
        GamesListLabel:"Evenimente unde ești",
        ChargeFee:"Percepe Taxa",
        SamePositions:"Jucătorii ar trebui să joace pe aceleași poziții",
        ShowCalendar:"Afișați calendarul evenimentelor",
        HideCalendar:"Ascunde calendarul evenimentelor"
    },
    ru:{
        NoPlayerYet: "Не кто не записан",
        Extra: "Extra",
        RegisterToGame: "Записатся на игру",
        RegistrationNotAvailable:"Регистрация пока недоступна",
        TaxDynamicMessage:"(Значение меняется в зависимости от количества активных игроков)",
        TaxPerPlayer: "Цена на игру:",
        Location: "Расположение:",
        Administrator: "Администратор:",
        ForQuestion: "По вопросам: ",
        Unregister: 'отписатся от игры',
        LoadMore:"Загрузить ещё",
        NoGames:"Нет доступных игр",
        FreeGame:"Эта игра бесплатна",
        GuestName:"Имя гостя",
        RegisterGuest:"Зарегистрировать гостя",
        Rezerv:"В Резерве",
        CancelGame:"Oтменить игру",
        RegistationWillOpenIn:"До открытия регистрации осталось ",
        DaysForms:["день", "дня", "дней"],
        RezervNotification:`Вы в резерве. Если какой-либо из активных игроков отменит свою регистрацию и до вас больше не будет резервных игроков - ваш статус изменится на Активный игрок и вы сможете приходить на игру. Обратите внимание, что если вы перейдете в список активных игроков и не подтвердите свое участие в заданное время({0}) - вы будете удалены из списка.
        Мы отправим вам уведомление для подтверждения вашего участия - поэтому, пожалуйста, убедитесь, что у вас есть хотя бы один метод, который позволяет нам отправлять уведомления (email, Telegram bot). По любым вопросам - свяжитесь с нами: inf@play-all-sports.com.
        Вы можете отменить этот тайм-аут на странице клубов в вкладке «Членкие клубы» или нажав кнопку ниже`,
        DisableAutoCancellation:"Отключить автоотмену",
        EnableAutoCancellation:"Включить автоотмену",
        ViewOnMap:"Посмотреть на карте",
        Participants:"Участники",
        DefaultParticipation:"Участие по умолчанию",
        Admins:"Админы",
        ViewAll:"Просмотреть всех",
        Paid:"Заплатил(а)",
        OpenRegistrationReminder:"На данный момент зарегистрироваться могут только участники. Регистрация для гостей должна быть открыта вами",
        PaymentDetails:"Посмотреть статус платежа",
        GamesListLabel:"События, где вы ",
        ChargeFee:"Взимать Плату",
        SamePositions:"Игроки должны играть на одинаковых позициях",
        ShowCalendar:"Показать календарь событий",
        HideCalendar:"Скрыть календарь событий"
    }
}