import React, { useContext, useEffect, useState } from 'react';
import Game from '../../entities/game';
import gameService from '../../services/gameService';
import localizationService from '../../services/localizationService';
import AppButton from '../../shared/ui/appButton';
import { toast } from 'react-toastify';
import Model from './model';
import events from '../../shared/events';
import { eventNames } from '../../constants';
import GameContext from '../../context/gameContext';

export const OpenGuestRegistration = () => {
    const {game} = useContext(GameContext);
    const [guestsRegistrationOpened, setGuestsRegistrationOpened] =  useState(game.guestsRegistrationOpened);
    Model.setFields(game.guestsRegistrationStrategy!, guestsRegistrationOpened!);
    const open = async() =>{
        return Model.open(game, () =>{
            toast.info(localizationService.RegistrationOpened);
            setGuestsRegistrationOpened(true);
        });
    }

    const gameStateChange = () =>{
        setGuestsRegistrationOpened(undefined);
    }

    useEffect(() =>{
        events.on(eventNames.gameSateChanged, gameStateChange);
        return () =>{
            events.off(eventNames.gameSateChanged, gameStateChange);
        }
    }, []);

    return guestsRegistrationOpened ? <></> : <AppButton variant="contained" className='openRegistration'
        color="primary"
        onClick={open} label={localizationService.OpenRegistration} />;
        
}