import React, { useContext, useState } from "react";
import RegisteredPlayer from "../../entities/registeredPlayer";
import HorizontalScrollView from "../../widgets/horizontal-scroll-view";
import strings from "../../services/localizationService";
import { RegisteredPlayerView } from "../player-view";
import GameContext from "../../context/gameContext";
import dialogs from "../../components/dialogs";
import { PlayerStatus, WalletOperation } from "../../entities/enums";
import playerService from "../../services/playerServices";
import { Button } from "@mui/material";
import { AccountBalanceWallet } from "@mui/icons-material";
import viewModels from "../../viewModels";
import { toast } from "react-toastify";
import EditInviteLimit from "../edit-invite-limit";

export function Players({participates, title}:{participates:RegisteredPlayer[], title:string}){
    const {game} = useContext(GameContext);
    
    const updateWallet = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, playerId:number) => {
        e.preventDefault();
        const walletModel = await dialogs.RegisteredPlayerWallet(game.clubId, playerId).show();
        if(!walletModel) return;

        viewModels.members.updateWallet({
            ...walletModel
        }, () =>{
            toast.info(`${strings.Update} ${strings.Placeholders.Points} ${strings.Ok}`)
        })
    }
    return <div className="game-list-item-players">
        <Header title={`${title} ${participates.length}`}/>
        <div className="participates-container card">
        {participates.length === 0 && <p>{strings.NoPlayerYet}</p>}
        {participates.map(x => <div key={x.playerId + x.registrationId + x.registeredTime}><RegisteredPlayerView item={x} viewedByAdmin />
        
        {game?.requestedPlayerStatus == PlayerStatus.Administrator &&
         x.status !== PlayerStatus.AdminGuest &&
        x.playerId !== playerService.getPlayerId() && <><Button className='modify-points' variant='outlined' color='primary'
        onClick={(e) => updateWallet(e, x.playerId)}><AccountBalanceWallet /></Button>
        <EditInviteLimit playerId={x.playerId} clubId={game.clubId} game={game} isDefault={x.byDefault} invitationLimit={x.inviteLimit} registrationId={x.registrationId} />
        </> }</div>)}
        </div>
    </div>
}

function Header({title}:{title:string}){
    return <div className="header">
        <p>{title}</p>
    </div>
}