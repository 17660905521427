import React, { useContext, useEffect, useState } from "react";
import ParticipableAgenda from "../../entities/participableAgenda";
import { DayOfWeek, PlayerStatus } from "../../entities/enums";
import { Button, Typography } from "@mui/material";
import strings from "../../services/localizationService";
import './index.css';
import viewModels from "../../viewModels";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import events from "../../shared/events";
import { eventNames } from "../../constants";
import { GamesContext } from "../../context/gameContext";
import { UpdatePlayerListArgs } from "../../shared/eventArgs";
import RegisteredPlayer from "../../entities/registeredPlayer";
import { DaysOfWeek } from "../../widgets/days-of-week";
import dialogs from "../../components/dialogs";
export function Schedules({opened}:{opened:boolean}){
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState<ParticipableAgenda[]>([]);
    const [currentDay, setCurrentDay]= useState(1);
    const {games} = useContext(GamesContext);
    const visiableItems = items.filter(x => x.day === currentDay);
useEffect(() =>{
    if(!loaded && opened)
    {
        viewModels.club.getParticipableAgendas(setItems);
        setLoaded(true);
    }
}, [opened, loaded]);

const onDayClicked = (day:DayOfWeek) =>{
    setCurrentDay(day);
}

const onScheduleClicked = async (item:ParticipableAgenda) =>{
    let player:RegisteredPlayer;
    let regisitraionId:number;
    let failed = false;
    let addToDefault = item.addToDefault;
    if(addToDefault)
    await viewModels.club.registerToDefault(item, 
(result) => {item.defaultRegistrationId = result.registrationId; player=result; regisitraionId = result.registrationId},
() => {failed = true;});
    else
    await viewModels.club.excludeFromDefault(item, async () => { player={playerId:item.playerId, registrationId:item.defaultRegistrationId} as RegisteredPlayer;
    regisitraionId = item.defaultRegistrationId!;
     item.defaultRegistrationId = undefined;
    }, () => {failed = true;});
     if(failed) return;
    item.addToDefault = !item.defaultRegistrationId;
    setItems([...items]);

    const alreadyRegistred = !addToDefault ? [] :  games.filter(x => x.end >= item.start && x.start <= item.end &&
                                                                     x.clubId !== item.clubId && new Date(x.date).getDay() === item.day &&
                                                                     x.registeredPlayers.find(y => y.playerId === player.playerId && y.status !== PlayerStatus.Guest) !== undefined);
    
    const gamesToUpdate = games.filter(x => x.start === item.start && x.clubId === item.clubId && new Date(x.date).getDay() === item.day
    && alreadyRegistred.find(y => y.date === x.date) === undefined);
    const triggers = gamesToUpdate.map(x => {
        return {
            add:!item.addToDefault,
            game:x,
            registrationId:regisitraionId,
            record:player
    } as UpdatePlayerListArgs});
    triggers.forEach(element => {
        events.trigger(eventNames.updatePlayers, element);
    });
    viewModels.game.recalculateFees(gamesToUpdate);
}

    return <div className={!opened ? "participable-agenda-container" : "participable-agenda-container opened"}>
<DaysOfWeek onDayClicked={onDayClicked} />
<ParticipableAgendaViewItemsContainer items={visiableItems.filter(x => !x.defaultRegistrationId)}
title={strings.OtherGames}
onClick={onScheduleClicked}
/>
<ParticipableAgendaViewItemsContainer items={visiableItems.filter(x => x.defaultRegistrationId)}
title={strings.DefaultParticipation}
onClick={onScheduleClicked} />
    </div>
}

function ParticipableAgendaViewItemsContainer({items, title, onClick}:{items:ParticipableAgenda[], title:string, onClick:(item:ParticipableAgenda) => void}){
    const className = title === strings.DefaultParticipation ? "participable-agenda-items-container default" : "participable-agenda-items-container";
return <div className={className}>
<Typography>{title}</Typography>
{items.length === 0 && <Typography>{strings.Placeholders.ListEmpty}</Typography>}
<div className="days-of-week">
{items.map(x => <ParticipableAgendaView item={x} key={x.clubId + x.start + x.day} onClick={onClick}/>)}
</div>
</div>
}

function ParticipableAgendaView({item, onClick}:{item:ParticipableAgenda, onClick:(item:ParticipableAgenda) => void}){
    return <div className="participable-agenda">
    {item.defaultRegistrationId && <Button className="exculde-default" onClick={() => onClick(item)}><ArrowUpward /></Button>}
    
    <Typography>{strings.ClubScopes[item.scope]}</Typography>
        <Typography>{item.clubName}</Typography>
        <Typography>{`${item.start.substring(0, 5)} - ${item.end.substring(0, 5)}`}</Typography>
        {item.addToDefault && <Button  color="primary" onClick={() => onClick(item)}><ArrowDownward /></Button>}
    </div>
}

