import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import dialogs from '../../components/dialogs';

export interface AppButtonProps extends ButtonProps {
    label?:string,
    confirmationmessage?:string,
    onClick?:() => void
}

export default function AppButton(props:AppButtonProps){
    const {confirmationmessage: confirmationMessage, onClick, label} = props;
    const handleClick = async () => {
        const userHasConfirmed = confirmationMessage ? await dialogs.Confirmation(confirmationMessage) : true;
        if(userHasConfirmed)
        onClick?.();
    }
return <Button {...props} onClick={handleClick}>{label}</Button>
}