import { Typography } from "@mui/material";
import React from "react";
import strings from "../../services/localizationService";
import AppButton from "../../shared/ui/appButton";
import Model from './model';
import './index.css'

function DeleteProfile(){

    return <div className="delete-container">
        <Typography>{strings.Placeholders.FacebookInfo}</Typography>
        <a href="https://www.facebook.com/settings?tab=applications">{strings.Placeholders.FacebookSettings}</a>
        <AppButton 
        confirmationmessage={strings.Confirmations.DeleteProfile}
        variant='contained'
        color="primary"
        onClick={Model.deleteProfile}
        label={strings.Placeholders.DeleteProfile}
         />
    </div>
}

export default DeleteProfile;