import React, { useEffect } from "react";
import { DialogProps, open } from "../dialogHelpers";
import IDialog from "../dialogs";
import Loadable from "../../../shared/ui/loadable";

function Loading(execute:Promise<any>):IDialog<boolean> {
    return {
        show:() => open((resolve) => <View execution={execute} resolve={resolve.resolve} />)
    }
}

interface Props extends DialogProps<boolean>{
    execution:Promise<any>
}

function View({execution, resolve}:Props){
    useEffect(() =>{
        stopLoading();
    }, [])

    const stopLoading = async () =>{
        await execution;
        resolve(true);
    }

    return <Loadable 
    View={() => <></>}
    id="API"
    screenCentered
    startOnRendering
    />
}

export default Loading;