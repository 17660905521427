import React, { useContext, useState } from "react";
import GameContext from "../../../context/gameContext";
import './index.css'
import { Button, Fab, TextField } from "@mui/material";
import { AccountBalanceWallet, ArrowBack, ArrowForward, Check, Close, Directions, DoubleArrow, DuoTwoTone, Message, MoneyTwoTone, TransferWithinAStation } from "@mui/icons-material";
import strings from "../../../services/localizationService";
import viewModels from "../../../viewModels";

export default function GameAdmins(){
    const {game} = useContext(GameContext);
    const adminsCount = game.admins.length;
    const [currentAdminIndex, setCurrentAdminIndex] = useState(0);
    const currentAdmin = game.admins[currentAdminIndex];
    const [askFormOpened, setAskFormOpened] = useState(false);

    return <div className="game-admins">
        <div className="admins-switcher">
            {currentAdminIndex > 0 && <div onClick={() => setCurrentAdminIndex(currentAdminIndex - 1)} className="admins-switcher-arrow"><ArrowBack /> </div>}
            <p className="admins-label admin-view-label">{`${strings.Admins} (${adminsCount})`}</p>
            {(adminsCount > 0 && currentAdminIndex + 1 < adminsCount) && <div className="admins-switcher-arrow" onClick={() => setCurrentAdminIndex(currentAdminIndex + 1)}><ArrowForward /> </div>}
        </div>
        <div className="admin">
            <div className="admin admin-details">
            <img src={currentAdmin.picture ?? require('../../../assets/avatar.jpg')} />
            <div className="admin-details-labels">
                <p className="admin-view-label">{currentAdmin.name}</p>
                <p className="admin-view-label">{currentAdmin.contactInfo}</p>
            </div>
            </div>
            <Fab className="transfer-contanier" size="small" onClick={async () => {
                await viewModels.club.askForTransfer(game.clubId, currentAdmin.id);
            }}><AccountBalanceWallet/></Fab>
        <div className="MuiButtonBase-root MuiFab-root MuiFab-circular MuiFab-sizeSmall MuiFab-default MuiFab-root MuiFab-circular MuiFab-sizeSmall MuiFab-default transfer-contanier css-1q51plx-MuiButtonBase-root-MuiFab-root" onClick={() => setAskFormOpened(true)}><Message />
        {askFormOpened && <AskForm adminId={currentAdmin.id} onFormClosed={() => {setAskFormOpened(false)}} />}
        </div>
        </div>
    </div>
}

function AskForm({onFormClosed, adminId}:{onFormClosed:() => void, adminId:number}){
    const {game} = useContext(GameContext);
    const [message, setMessage] = useState('');
    const closeForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
        e.stopPropagation();
        onFormClosed();
    }
return <div className="ask-form">
<textarea value={message} onChange={(e) => {setMessage(e.currentTarget.value)}} placeholder="Enter your question" />
<div className="edit-buttons-container">
<Button color="secondary" variant="contained" onClick={closeForm}><Close /></Button>
<Button color="primary" variant="contained" disabled={!message?.trim()} onClick={async (e)=>{
    await viewModels.chat.ask(game.clubId, adminId, message);
closeForm(e);
}}><Check /></Button>
</div>
</div>;
}