import React, { useState } from "react";
import {InputWithValidation} from '../shared/ui/AppFormHelper'
import strings from "../services/localizationService";
import {SecurityRounded, Close} from '@mui/icons-material'
function PasswordInput({onChange, password, errorMessage, name, hideHeader}:{onChange:(e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void,
    password:string, errorMessage:string, name:string, hideHeader?:boolean }){
    const [viewPassword, setViewPassword] = useState(false);
    const type = viewPassword ? "text" : "password";
    const placeHolder = name === "password" ? strings.Placeholders.Password : strings.Placeholders.RepaetePassword;
    return <div className="password-input"><InputWithValidation textFieldProps={{name, type, value:password,
        
    placeholder:placeHolder, onChange:onChange
    }}
                           errorMessage={errorMessage} 
                           header={hideHeader ? '' : strings.InputHeader.password}
                           />
                           <div onClick={() => setViewPassword(true)} className="view-password-eye">
                            <SecurityRounded></SecurityRounded>
                           </div>
                           {viewPassword && <div onClick={() => setViewPassword(false)}  className="view-password-eye">
                            <Close></Close>
                           </div>}
                           </div>
}
export default PasswordInput;