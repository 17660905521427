import { Typography } from "@mui/material";
import React, { useState } from "react";
import ClubLocation from "../../entities/clubLocation";
import localizationService from "../../services/localizationService";
import * as Helper from '../../shared/ui/AppFormHelper';
import model from "./model";
import Club from "../../entities/club";
import ClubSettings from "../../entities/clubSettings";

interface Props{
    errorMessage:string,
    address:string,
    setState: (newModel: ClubSettings) => void,
    onInputChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}
function AddressSeach({errorMessage, onInputChange, address, setState}:Props){
    const[locations, SetLocations] = useState<ClubLocation[]>([]);
    const onEdit = async (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
        onInputChange(e);
        if(e.target.value)
        model.find(e.target.value, 
            (result) =>{
                if(result.length > 0)
                setState({place_id:result[0].place_id, description:result[0].description} as ClubSettings);
                SetLocations(result);
            });
    }
return <><Helper.InputWithValidation
errorMessage={errorMessage}
header={`${localizationService.Club} ${localizationService.Address}`}
textFieldProps={{ name:'address',
placeholder:`${localizationService.Enter} ${localizationService.Address}`,
 defaultValue:address, InputLabelProps:{shrink:true},
onChange:onEdit,
inputProps:{list:"locations"}
}}
 />
 <datalist id="locations">
    {locations.map((x, i) => <option key={x.place_id} onSelect={(e) => {console.log("Selected")}}>{x.description}</option>)}
 </datalist>
 </>

}

export default AddressSeach;
