import React from 'react';
import { ContentComponentProps, DialogConfig } from '../dialog';
import open from '../dialog';
import AppMap from '../../../features/app-map/appmap';
import strings from '../../../services/localizationService';
interface Props{
    longitude?:number, latitude?:number
}
function OpenMap({longitude,latitude}:Props){
    const config: DialogConfig<Props, boolean> = {
        inputModel: {longitude, latitude},
        validationNotNeeded:false,
        resolvedResult:() => Promise.resolve({succeeded:true, result:true}),
        Content: DialogView,
        title: strings.Location,
        CancelTitle: strings.Ok
    }
    return open(config);
}

function DialogView(props:ContentComponentProps<Props>){
    return <AppMap lat={props.state.latitude} lng={props.state.longitude} />
}
export default OpenMap;