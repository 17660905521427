import React from 'react';
import RegisterForm from '../../forms/registration-form';

function RegisterPage(){
    
    return<div className='auth-page'>
    <img src={require('../../assets/auth-background.svg').default} />
    <RegisterForm />
</div>
}

export default RegisterPage;