import FeedbackGroup from "../../entities/FeedbackGroup";
import { GameRotation, SportType } from "../../entities/enums";
import Feedback from "../../entities/feedback";
import Game from "../../entities/game";
import Player from "../../entities/player";
import PlayerForFeedback from "../../entities/playerForFeedback";
import Skill from "../../entities/skill";
import SkillsBySport from "../../entities/skillsBySport";
import SportSection from "../../entities/sportSection";
import Team from "../../entities/team";
import TeamMember from "../../entities/teamMember";
import GetTeam from "../../models/getTeamModel";
import TeamListModel from "../../models/teamListModel";
import TeamService from "../interfaces/teamService";
import http from "./http";
const basePath = 'team/';

function getSkills(playerId:number):Promise<SportSection[]>{
    return http.get(`${basePath}skills/?playerId=${playerId}`);
}

function getSkillsForFeedback(playerId:number):Promise<SkillsBySport[]>{
    return http.get(`${basePath}feedback/skills?playerId=${playerId}`);
}

function getTeams(model:GetTeam):Promise<Team[]>{
    return http.post(basePath, model);
}

function give(feedback:Feedback){
    return http.post(`${basePath}feedback`, feedback);
}

function getPlayers():Promise<FeedbackGroup[]>{
    return http.get(`${basePath}feedback/players`);
}
function teamList(feedback:TeamListModel){
    return http.post(`${basePath}teamList/${feedback.clubId}`, feedback);
}

const teamService:TeamService = {
    getSkills,
    give,
    getTeams,
    getSkillsForFeedback,
    getPlayers,
    teamList
};

export default teamService;