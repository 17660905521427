import React from 'react';
import { Skills, SportType } from '../entities/enums';
import Skill from '../entities/skill';
export interface SkillContextValue {
    skills: Skill[];
    playerId:number,
    sportType:SportType,
    readonly:boolean,
    updateSkills?:(newValues:Skill[]) => void
}
const SkillContext = React.createContext<SkillContextValue>({} as SkillContextValue);
SkillContext.displayName = "SkillContext";

export default SkillContext;