
import gameService from "../../../services/gameService";
import clubMemberService from "../../../services/clubMemberService";
import events from "../../../shared/events";
import {eventNames as eventNames, localStorageKeys} from "../../../constants";
import GameRegisterModel from "../../../models/gameRegisterModel";
import playerService from "../../../services/playerServices";
import { GameData, UpdatePlayerListArgs } from "../../../shared/eventArgs";
import apiRequest from "../../../shared/apiRequest";
import { toast } from "react-toastify";
import strings from "../../../services/localizationService";
import RegisteredPlayer from "../../../entities/registeredPlayer";
import localStorageService from "../../../services/localStorageService";
import dialogs from "../../../components/dialogs";
import viewModels from "../../../viewModels";
interface RemoveModel extends GameData{
    registrationId:number,
    isDefault:boolean
}
const register = async (input:GameRegisterModel, onSucces:() => void) =>{
    return apiRequest.executeWithLoading(() => gameService.registerToGame({
        clubId: input.clubId,
        date: input.date,
        start:input.start,
        playerId:playerService.getPlayerId(),
        playerName:''
    }), async (result) =>{
         events.trigger<UpdatePlayerListArgs>(eventNames.updatePlayers, {add:true,game:{...input}, registrationId:result.registrationId, record:result})
         onSucces();
         if(result.isRezerv && input.confirmationTimeout &&
            await dialogs.RezervInfo(input.confirmationTimeout).show())
            viewModels.members.setConfirmationTimeout(input.clubId, () => {});
        });
}

const remove = async (model:RemoveModel, onSucces:() => void) =>{
    return apiRequest.executeWithLoading(() => model.isDefault ? clubMemberService.exludeFromGame(model.clubId, model)
                                                          : gameService.unregisterFromGame(model.clubId, model),
                              async (result) =>{
                                events.trigger<UpdatePlayerListArgs>(eventNames.updatePlayers, {add:false, game:{...model}, registrationId:model.registrationId, record:{playerId:playerService.getPlayerId(), registrationId:model.registrationId} as RegisteredPlayer});
                                onSucces();
                                if(result)
                                    await dialogs.Info(result);
                              });
}

export {register, remove};