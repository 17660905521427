import React, { useContext, useEffect, useState } from "react";
import Game from "../../entities/game";
import strings from "../../services/localizationService";
import OpenMap from "../../dialogs/ui/map";
import GameContext from "../../context/gameContext";
import { GuestsRegistrationStrategy, PlayerStatus } from "../../entities/enums";
import AdminOptions from "./admin-options";
import { Button, Typography } from "@mui/material";
import GameAdmins from "./game-admins";
import events from "../../shared/events";
import { eventNames } from "../../constants";
import { InfoOutlined } from "@mui/icons-material";
import dialogs from "../../components/dialogs";
import clubService from "../../services/serverServices/clubService";
import loading from "../../shared/ui/loadable/loading";

export function Header(){
    const {game, updateGame} = useContext(GameContext);
    const [rulesAsked, setRulesAsked] = useState(false);
    const fee = game.fee;
    const updateFee = (e:any) => {
        const updatedGame = e.detail as Game;
        if(game.clubId === updatedGame.clubId && game.date === updatedGame.date && game.start === updatedGame.start)
            updateGame({...game, fee: updatedGame.fee});
    }
    useEffect(() =>{
        events.on(eventNames.updateFee, updateFee);
        return () => {
            events.off(eventNames.updateFee, updateFee);
        }
    }, []);
    const canViewOnMap = !!game.latitude;
    const isAdmin = game.requestedPlayerStatus === PlayerStatus.Administrator;
    const guestsCanRegister = game.isPublic || !isAdmin || game.guestsRegistrationStrategy !== GuestsRegistrationStrategy.Manual || game.guestsRegistrationOpened;
    const variationLabel = game.feeIsFixed || game.registeredPlayers.length >= game.maxPlayers ? '' : `(${strings.FeeVariates} ${game.feeOnMaxPlayers} ${game.currency})`
    return <div className="game-list-item-header">
        <div className="game-list-item-header-info">
        <p className="title">{game.name}</p>
        <p className="content">{strings.ClubScopes[game.scope]} / {strings.Volleyball.Types[game.type]}</p>
        <p className={canViewOnMap ? "content map" : "content"} onClick={async () => {
            if(canViewOnMap)
            await OpenMap(game);
        }}>{strings.Address}: <em>{game.address}</em></p>
        <p>{!fee || fee === 0 ? strings.FreeGame : `${strings.TaxPerPlayer} ${Math.round(fee!)} ${game.currency} ${variationLabel}`}
        <em style={{cursor:"pointer"}} onClick={() =>{
            dialogs.Info(strings.Placeholders.Price);
        }}><InfoOutlined /></em></p>
        {!guestsCanRegister && <p className="guest-registration-reminder">{strings.OpenRegistrationReminder}</p>}
        
            
        {!isAdmin && <Button variant="outlined" color="primary" onClick={async() =>{
            if(rulesAsked) return;
            setRulesAsked(true);
            var rules = await clubService.getRules(game.clubId);
            dialogs.Info(rules);
            setRulesAsked(false);
        }}>{strings.Placeholders.ClubRules}</Button>}
        </div>
        {!isAdmin && <GameAdmins />}
        {isAdmin && <AdminOptions />}
        <img className="game-country-flag" src={game.flag} />
    </div>
}