import http from "./http";
import Game from "../../entities/game";
import GameRegisterModel from '../../models/gameRegisterModel';
import GameService from '../interfaces/gameService';
import PaginationModel from "../../models/paginationModel";
import CreateGameModel from "../../models/createGameModel";
import GameDetails from "../../entities/gameDetails";
import RegisteredPlayer from "../../entities/registeredPlayer";
import RemoveFromGame from "../../models/removeFromGame";
import cacheManager from "../../cacheManager";
import { GuestsRegistrationStrategy, PlayerStatus } from "../../entities/enums";
import UpdateGame from "../../models/updateGameModel";
import UpdateGameResult from "../../models/updateGameResult";
import { GameData } from "../../shared/eventArgs";

const baseUrl = "game";
 async function get(model:PaginationModel): Promise<Game[]> {
    console.log(model);
    const games = await http.post<Game[]>(baseUrl, model);
    games.forEach(x => x.canRegister = canRegister(x));
    return games;
}

async function registerToGame(model:GameRegisterModel){
    await cacheManager.clearCache("registered", cacheManager.getGameRelatedCache(model));
    await cacheManager.clearCache("registered", `/count${cacheManager.getGameRelatedCache(model)}`);
    const result = await http.post<RegisteredPlayer>(`${baseUrl}/register/${model.clubId}`, model);
    return result;
}

async function unregisterFromGame(clubId:number, model:RemoveFromGame):Promise<string>{
    await cacheManager.clearCache("registered", cacheManager.getGameRelatedCache(model));
    await cacheManager.clearCache("registered", `/count${cacheManager.getGameRelatedCache(model)}`);
    return http.post(`${baseUrl}/unregister/${clubId}`, model);
}

async function getRegisteredPlayers(game:Game): Promise<RegisteredPlayer[]>{
    const cachedData = await cacheManager.getCacheData("registered", cacheManager.getGameRelatedCache(game));
    if(!game.registeredPlayersUpdated && cachedData)
    return cachedData as RegisteredPlayer[];
    const result = await http.postWithCaching<RegisteredPlayer[]>({cacheName:"registered",cacheKey:cacheManager.getGameRelatedCache(game)}, baseUrl + `/RegisteredPlayers/${game.clubId}`, game);
    return result;
}
async function getDetails(game:Game){
    const deatils = await http.getWithCaching<GameDetails>({cacheName:'game', cacheKey:`/details${cacheManager.getGameRelatedCache(game)}`}, `${baseUrl}/details?clubId=${game.clubId}&date=${game.date}&start=${game.start}`);
    deatils.game.canRegister = canRegister(deatils.game);
    return deatils;
}

async function createGame(model:CreateGameModel): Promise<Game>{
    const result = await http.post<Game>(`${baseUrl}/${model.clubId}`, model);
    await cacheManager.clearCache("game");
    return result;
}
async function cancel(model:Game): Promise<unknown>{
    await cacheManager.clearCache("game");
    await cacheManager.clearCache("registered", cacheManager.getGameRelatedCache(model));
    return http.post(`${baseUrl}/cancel/${model.clubId}`, model);
}
async function reactivate(model:Game): Promise<unknown>{
    await cacheManager.clearCache("game");
    return http.post(`${baseUrl}/reactivate/${model.clubId}`, model);
}
async function openRegistration(game:Game){
    await cacheManager.clearCache("game");
    return http.post(`${baseUrl}/OpenRegistrationForGuests/${game.clubId}`, game);
}

async function update(game:UpdateGame):Promise<UpdateGameResult>{
    await cacheManager.clearCache("game");
    return http.put(`${baseUrl}/update/${game.clubId}`, game);
}
function canRegister(game:Game){
    if(game.isPublic) return true;
    if(game.requestedPlayerStatus === PlayerStatus.Administrator ||
        (game.requestedPlayerStatus === PlayerStatus.Regular && !game.regularsShouldWaitToo))
    return true;
    if(game.guestsRegistrationStrategy === GuestsRegistrationStrategy.Manual && game.requestedPlayerStatus !== PlayerStatus.Regular)
    return !!game.guestsRegistrationOpened;
    const date = new Date(game.gameOpenTime!).valueOf();
    return date - Date.now() < 0;
}

function chargeFee(game:Game){
    return http.post(`clubWallet/charge/${game.clubId}`, game);
}

const implemenation:GameService =
{
    get,
    registerToGame,
    getRegisteredPlayers,
    unregisterFromGame,
    createGame,
    getDetails,
    cancel,
    openRegistration,
    reactivate,
    update,
    chargeFee
};
export default implemenation;