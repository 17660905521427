import React, { FunctionComponent, memo, useContext, useEffect, useRef, useState } from 'react';
import localizationService from '../../services/localizationService';
import { Localization } from '../../features/localization';
import AppButton from '../../shared/ui/appButton';
import { Logout } from '../../features/logout';
import { RequestMemberShip } from '../../features/requestMembership';
import { AppTabs } from '../../features/tabs';
import {  eventNames, routers } from '../../constants';
import './index.css';
import { Fab, Typography } from '@mui/material';
import { ChatBubble, Help, Menu, MenuOpen } from '@mui/icons-material';
import { useNavigate  } from 'react-router-dom';
import playerService from '../../services/serverServices/playerServices';
import LanguageContext from '../../context/languageContext';
import Logo from '../../features/logo';
import ChatIcon from './chatIcon';
import events from '../../shared/events';
import ChatHighOrderComponent from './chatHighOrderCompoent';

const NavigationBar: FunctionComponent = () => {
    const history = useNavigate();
    
    //for some magical reasons - if useContext is not called - tabs are not updated, when language is changed
    useContext(LanguageContext);
    const menu = useRef<HTMLDivElement>(null);
    const tabs = useRef<HTMLDivElement>(null);
    const menuIcon = useRef<HTMLButtonElement>(null);
    const [menuOpened, setMenuOpened] = useState(false);

    useEffect(() =>{
        events.on(eventNames.menuItemClicked, onMenuItemClicked);
        return () =>{
            events.off(eventNames.menuItemClicked, onMenuItemClicked);
        }
    }, []);

    const onMenuItemClicked = () =>{
        var closed = tabs.current!.classList.contains('Tabs-closed');
        if(!closed) MenuCliked();
    }

    const MenuCliked = () =>{
        var state = tabs.current!.classList.contains('Tabs-closed');
        menu.current!.classList.toggle('NavigationAppBar-closed');
        tabs.current!.classList.toggle('Tabs-closed');
        menuIcon.current!.classList.toggle('closed');
        setMenuOpened(state);
    };

    const help = () =>{
        if(!window.location.pathname.includes(routers.termsAndConditions))
        history(routers.termsAndConditions);
        onMenuItemClicked();
    }

    return <ChatHighOrderComponent><div ref={menu}
    className={playerService.isLoggedIn() ? 'NavigationAppBar NavigationAppBar-closed' : "NavigationAppBar NavigationAppBar-closed Unautorized"}>
       <Fab className='MenuIcon closed' ref={menuIcon} onClick={MenuCliked} color="primary">{menuOpened ? <MenuOpen /> : <Menu/>}</Fab>
       {playerService.isLoggedIn() && <div className='mobile-chat-icon'><ChatIcon /></div>}
       <div ref={tabs} className='Tabs Tabs-closed'
       >
           <Logo />
           <AppTabs />
           <span style={{ flex: 1 }} />
           {playerService.isLoggedIn() && <Logout />}
           <Fab size='small' variant='circular' className='help-icon' onClick={help}><Help /></Fab>
           {playerService.isLoggedIn() && <div className="desktop-chat-icon"> <ChatIcon /></div> }
           <Localization />
       </div>
   </div></ChatHighOrderComponent> ;
}

export default memo(NavigationBar);