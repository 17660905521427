import Game from "../../entities/game";
import Wallet, { NamedWallet } from "../../entities/wallet";
import WalletModel, { GameWalletModel } from "../../models/walletModel";
import { GameData } from "../../shared/eventArgs";
import WalletService from "../interfaces/walletService";
import http from "./http";
const basePath = "clubWallet";
function executeOperation(wallet:WalletModel){
    return http.post(`${basePath}/${wallet.clubId}`, wallet);
}
function executeGameOperation(wallet:GameWalletModel){
    return http.post(`${basePath}/gameWalletOperation/${wallet.clubId}`, wallet);
}
function get():Promise<NamedWallet[]>{
    return http.getWithCaching({cacheName:"player", cacheKey:"wallet"}, `${basePath}`);
}
function recalculate(games:GameData[]):Promise<Game[]>{
    if(games.length === 0) return Promise.resolve([]);
    const clubId = games[0].clubId;
    return http.post(`${basePath}/recalculate/${clubId}`, games);
}

function askForTransfer(clubId:number, adminId:number){
    return http.post(`${basePath}/transferPointsRequest/start/${clubId}?adminId=${adminId}`)
}
function completeTransfer(clubId:number, memberId:number, accept:boolean):Promise<number>{
    return http.post(`${basePath}/transferPointsRequest/complete/${clubId}?memberId=${memberId}&accept=${accept}`);
}
function payments(clubId:number, payments:WalletModel[]){
    return http.post(`${basePath}/payments/${clubId}`, payments);
}
const implementation: WalletService = {
    executeOperation,
    get,
    recalculate,
    askForTransfer,
    completeTransfer,
    payments
}
export default implementation;