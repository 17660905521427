import http from "./http";
import AuthService, { SocialPlatform } from '../interfaces/authService';
import AuthResult, { SocialAuthResult } from '../../models/authResult';
import localStorageService from "../localStorageService";
import RegistrationModel, { LoghinModel } from "../../models/registrationModel";
import { localStorageKeys } from "../../constants";
import cacheManager from "../../cacheManager";
const basepath = "account/";

async function socialPlatform(accessToken:string, plaftorm:SocialPlatform) : Promise<SocialAuthResult>{
    return http.get(basepath + `login/external?accessToken=${accessToken}&platform=${plaftorm}`);
}

async function emailRegistration(model:RegistrationModel) {
    return emailAuth(model, 'register');
}

async function logout(){
    localStorageService.removeItem(localStorageKeys.playerId);
    await cacheManager.clearFull();
    return http.get(`${basepath}logout`);
}

async function emailLogin(model:LoghinModel) {
    return http.post<AuthResult>(basepath + 'login', model);
}

async function emailAuth(model:RegistrationModel, path:string) {
    return http.post<AuthResult>(basepath + path, model);
}

function reguestResetPassword(email:string): Promise<boolean>{
    return http.post(`${basepath}requestreset/${email}`);
}

function reset(email:string,passwordToken:string, newPassword:string):Promise<AuthResult>{
    return http.post(`${basepath}reset/${email}?passwordToken=${passwordToken}&newPassword=${newPassword}`);
}
function resendConfirmation(email:string){
    return http.get(`${basepath}resendConfirmation/${email}`);
}
function deleteAccount(){
    return http._delete(basepath);
}
const authImplemtation:AuthService = {
    socialPlatform, emailRegistration, emailLogin, logout, reguestResetPassword, reset, resendConfirmation,
    deleteAccount
}
export default authImplemtation;