import cacheManager from "../../cacheManager";
import ClubAgenda from "../../entities/clubAgenda";
import ClubAgendaService from "../interfaces/clubAgendaService";
import http from "./http";

const baseUrl = "agenda";

function get(clubId:number): Promise<ClubAgenda[]>{
    return http.getWithCaching({cacheName:'club', cacheKey:`/agendas/${clubId}`},`${baseUrl}/${clubId}`);
}

async function post(clubId:number, clubAgenda:ClubAgenda) : Promise<number>{
    const result = await http.post<number>(`${baseUrl}/${clubId}`, clubAgenda);
    await cacheManager.clearCache("club");
    return result;
}

function put(clubId:number, clubAgenda:ClubAgenda) : Promise<any>{
    return http.put(`${baseUrl}/${clubId}`, clubAgenda);
}
async function _delete(clubId:number, id:number): Promise<any>{
    await http._delete(`${baseUrl}/${clubId}?id=${id}`);
    await cacheManager.clearCache("club");
}

const clubAgendaService:ClubAgendaService = {
    get,
    post,
    put,
    delete:_delete
}

export default clubAgendaService;