export default
{
 en:{
    Email:"email@example.com",
    Password:"Enter Password",
    Name:"Jon Smith",
    ContactInfo:"How we can get in touch?",
    RepaetePassword:"Repeat Password",
    DayOfWeek:"Select Day of week",
    Contact:"Contact Info:",
    CallBy:"Your First and Last name",
    FacebookInfo:"If you logged in through Facebook, in addition to deleting the account - make sure to delete the association with Facebook by accessing",
    FacebookSettings:"Facebook settings",
    DeleteProfile:"Delete Profile",
    TelegramCode:"Telegram Code",
    TelegramBot:"Telegram bot @Play_all_sports_bot can help you to get code. Use code command",
    CurrentBalance:"Current Balance",
    AfterModification:"After Modification",
    Price:"Fee can variate based on next factors: is price fixed or is splitted by nr of participants, do you have club points in your account. Club Points can be viewed in your profile",
    Points:"Points",
    Quit:"Quit",
    Return:"Return",
    ListEmpty:"List is empty",
    NewVersion:"New Version is Available",
    OperationCompleted:"Operation Completed",
    PointsTransfer:"Points Transfer",
    OnlyThisSports:"Only games for this sports will be displayed to you",
    CreateTelegram:"Telegram bot @Play_all_sports_bot can help you create a profile. Use the create command",
    ConfirmationTimeout:"Timeout for player who was moved from reserve to participation list, to confirm or reject participation. If player do not confirm in this time limit after moved to participation list - will be removed from event automaticly",
    ChangeTimeout:"Change Confirmation Timeout",
    Minutes:"min.",
    Disabled:"Disabled",
    HaveToPay:"Has to pay",
    NothingToPay:"Paid",
    WillParticipate:"Will participate in chat",
    WhoShouldPay:"Show only who should pay",
    PlayersRestrictions:"Restrictions on players",
    Codes:"Codes",
    Penalties:"Penalties",
    CanBeFined:"Players can be fined",
    ClubRules:"Read Club Rules",
    PenaltyFor24Hours:"Penalty in % from game fee for removing within 24 hours before event starts",
    PenaltyForNotComing:"Penalty in % from game fee for not coming to the event",
    TeamName:"Team Name",
    ReplaceWith:"Switch with",
    ViewPreviosAdjument:"View previous adjustment"
 },
 ro:{
    Email:"email@exemplu.com",
    Password:"Introduceți parola",
    Name:"Ion Smith",
    ContactInfo:"Cum putem lua legătura?",
    RepaetePassword:"Repetă parola",
    DayOfWeek:"Alegeți ziua săptămânii",
    Contact:"Informatii de contact:",
    CallBy:"Numele și prenumele",
    FacebookInfo:"Dacă v-ați autentificat prin Facebook, pe lângă ștergerea contului - asigurați-vă că ștergeți asocierea cu Facebook accesând",
    FacebookSettings:"setări Facebook",
    DeleteProfile:"Ștergeți profilul",
    TelegramCode:"Telegram Cod",
    TelegramBot:"Botul Telegram @Play_all_sports_bot vă poate ajuta să obțineți codul. Utilizați comanda code",
    CurrentBalance:"Bilanțul curent",
    AfterModification:"După Modificări",
    Price:"Taxa poate varia în funcție de următorii factori: prețul este fix sau este împărțit pe numărul de participanți, aveți puncte de club în cont. Punctele Clubului pot fi vizualizate în profilul dvs",
    Points:"Puncte",
    Quit:"Părăsește",
    Return:"Întoarce-te",
    ListEmpty:"Lista e goală",
    NewVersion:"Versiunea nouă este disponibilă",
    OperationCompleted:"Operațiune finalizată",
    PointsTransfer:"Transfer de puncte",
    OnlyThisSports:"Doar jocurile pentru aceste sporturi îți vor fi afișate",
    CreateTelegram:"Botul Telegram @Play_all_sports_bot vă poate ajuta să vă creați un profil. Utilizați comanda create",
    ConfirmationTimeout:"Timeout pentru jucătorul care a fost mutat din rezervă în lista de participare, pentru a confirma sau respinge participarea. Dacă jucătorul nu confirmă în această limită de timp după ce a fost mutat în lista de participare - va fi eliminat automat de la eveniment",
    ChangeTimeout:"Modificați Timeout de confirmare",
    Minutes:"min.",
    Disabled:"Dezactivat",
    HaveToPay:"Are de achitat",
    NothingToPay:"Achitat",
    WillParticipate:"Vor participa la chat",
    WhoShouldPay:"Afișează doar cine trebuie să achite",
    PlayersRestrictions:"Restricții privind jucătorii",
    Codes:"Coduri",
    Penalties:"Penalități",
    CanBeFined:"Jucătorii pot fi amendați",
    ClubRules:"Citiți regulile clubului",
    PenaltyFor24Hours:"Penalizare în % din taxa de joc pentru eliminarea cu 24 de ore înainte de începerea evenimentului",
    PenaltyForNotComing:"Penalizare în % din taxa de joc pentru neprezentarea la eveniment",
    TeamName:"Denumirea Echipei",
    ReplaceWith:"Schimbă cu",
    ViewPreviosAdjument:"Vezi ajustarea precedentă"
 },
 ru:{
    Email:"email@example.com",
    Password:"Введите пароль",
    Name:"Jon Smith",
    ContactInfo:"Как мы можем связаться?",
    RepaetePassword:"Повторите пароль",
    DayOfWeek:"Выберите день недели",
    Contact:"Контактная информация:",
    CallBy:"Имя и фамилия",
    FacebookInfo:"Если вы вошли через Facebook, помимо удаления аккаунта - обязательно удалите ассоциацию с Facebook, зайдя",
    FacebookSettings:"Настройки Facebook",
    DeleteProfile:"Удалить профиль",
    TelegramCode:"Telegram код",
    TelegramBot:"Телеграмм-бот @Play_all_sports_bot может помочь вам получить код. Используете команду code",
    CurrentBalance:"Текущий баланс",
    AfterModification:"После изменений",
    Price:"Цена может варьироваться в зависимости от следующих факторов: фиксированная цена или она делится на количество участников, есть ли у вас клубные баллы на счету. Клубные баллы можно посмотреть в своем профиле",
    Points:"Баллы",
    Quit:"Бросить",
    Return:"Вернуться",
    ListEmpty:"Список пуст",
    NewVersion:"Доступна новая версия",
    OperationCompleted:"Операция завершена",
    PointsTransfer:"Перевод баллов",
    OnlyThisSports:"Вам будут показаны только игры по этим видам спорта",
    CreateTelegram:"Телеграм-бот @Play_all_sports_bot может помочь вам создать профиль. Используете команду create",
    ConfirmationTimeout:"Тайм-аут для игрока, переведенного из резерва в список участия, для подтверждения или отказа от участия. Если игрок не подтвердит в течение этого срока после перемещения в список участия, он будет автоматически удален из мероприятия.",
    ChangeTimeout:"Изменение таймаута",
    Minutes:"мин.",
    Disabled:"Отключен",
    HaveToPay:"Должен заплатить",
    NothingToPay:"Оплачено",
    WillParticipate:"Приму участие в чате",
    WhoShouldPay:"Показывай только кто должен платить",
    PlayersRestrictions:"Ограничения на игроков",
    Codes:"Коды",
    Penalties:"Штрафы",
    CanBeFined:"Игроки могут быть оштрафованы",
    ClubRules:"Читать правила клуба",
    PenaltyFor24Hours:"Штраф в % от игровой комиссии за удаление в течение 24 часов до начала события",
    PenaltyForNotComing:"Штраф в % от игрового взноса за неявку на мероприятие",
    TeamName:"Название команды",
    ReplaceWith:"Поменять с",
    ViewPreviosAdjument:"Просмотреть предыдущую корректировку"
 }
}