export default{
    en:{
        Positions:["Goalkeeper", "Wingback", "Winger", "Striker"],
    },
    ro:{
        Positions:["Portar", "Later", "Ater", "Atacant"],
    },
    ru:{
        Positions:["Вратарь", "Вингбек", "Вингер", "Нападающий"],
    }
}