import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import cacheManager from '../../cacheManager';
import { eventNames, routers } from '../../constants';
import open from '../../dialogs/ui/dialog';
import { SportType, GameRotation, Positions } from '../../entities/enums';
import Game from '../../entities/game';
import strings from '../../services/localizationService';
import events from '../../shared/events';
import './index.css';
import GameContext from '../../context/gameContext';
import Team from '../../entities/team';
import GetTeam from '../../models/getTeamModel';
import apiRequest from '../../shared/apiRequest';
import teamService from '../../services/serverServices/teamService';
import { useNavigate, useLocation } from 'react-router-dom';
import dialogs from '../../components/dialogs';
import SuggestedTeamsModel from '../../models/suggestedTeamsModel';

function SuggestedTeams(){
    const {game, registeredPlayers} = useContext(GameContext);
    const history = useNavigate();
    const count = registeredPlayers.length;
    const handleOnClick = async () =>{
        const sportData = await dialogs.RotationType(game).show();
        if(!sportData) return;
        const model = {
            ...game,
            registeredCount:game.registeredPlayers.length,
            clubType:game.type,
            rotation:sportData.rotation,
            withLibero:sportData.withLibero
        };
        await dialogs.Loading(apiRequest.execute(() => teamService.getTeams(model), async (result) =>{
           
            const teamsResult:{teams:Team[], positions:Positions[]} = {teams:[], positions:[]};
            result.forEach(item =>{
                item.sportType = model.clubType;
                teamsResult.teams.push(item);
                item.positions.forEach(position =>{
                    if(teamsResult.positions.find(x => x == position) === undefined && position > -1)
                    teamsResult.positions.push(position);
                })
            })
            const suggested: SuggestedTeamsModel = {
                ...model,
                ...teamsResult
            };
            await cacheManager.storeCacheData("game", `team_${model.clubId}_${model.date}_${model.start}`,suggested)
        history(`${routers.suggestedTeams}?clubId=${game.clubId}&date=${game.date}&start=${game.start}`)
        })).show();
    }

    return <Button disabled={count < 4}
    color='primary' variant='contained' onClick={handleOnClick} className='suggested-team-button'>{strings.Volleyball.SuggestedTeams}</Button>
}

export default SuggestedTeams;