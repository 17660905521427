import React, { useContext, useEffect, useState } from 'react';
import Club from '../../entities/club';
import Model from './Model';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import strings from '../../services/localizationService';
import {ClubItemlist} from './club-list-item';
import events from '../../shared/events';
import { eventNames } from '../../constants';
import './index.css';
import dialogs from '../../components/dialogs';
import ClubContext, { ClubsContext } from '../../context/clubContext';

const ClubList = () => {
    const {clubs, deleteClub } = useContext(ClubsContext);
    const clubsExists = clubs?.length > 0;
    const onDeleteClub = async (id: number) => {
        const confirmed = await dialogs.Confirmation(strings.Confirmations.Default);
        if(!confirmed) return;
        Model.deleteClub(id, () => deleteClub(id), () => {});
    }
    
    return <div className='club-list-container'>
    { clubsExists ? clubs.map(c =>
<ClubItemlist key={c.id}
            club={c}
            deleteClub={onDeleteClub}
        />
        ) : <Typography>{strings.noClubs}</Typography>}</div>;
};

export default ClubList;