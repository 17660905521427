import { Fab, Link } from '@mui/material';
import { Close, MenuBook } from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import './index.css';
import { DropDown } from '../drop-down';
interface AppPolicyWrapperSection{
    Section:string,
    Details:string[]
}
interface AppPolicyWrapperProps{
    policy:AppPolicyWrapperSection[]
}
function AppPolicyWrapper(props:AppPolicyWrapperProps){

    return <div className='section'>{props.policy.map(x => <DropDown
        defaultState={false}
        title={x.Section}
        key={x.Section}
             ><ul className='section-details'>{x.Details.map((y, i) => <li key={i}>{y}</li>)}</ul></DropDown>)}
             </div>;
}

export default AppPolicyWrapper;