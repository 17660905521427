import React, { useEffect, useState } from "react";
import IDialog from "../dialogs";
import { DialogProps, open } from "../dialogHelpers";
import { GameContextValue } from "../../../context/gameContext";
import { FormControl, Input, TextField, Typography } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { PlayerStatus, WalletOperation } from "../../../entities/enums";
import Member from "../../../entities/member";
import viewModels from "../../../viewModels";
import './index.css'
import Loading from "../../../shared/ui/loadable/loading";
import Loadable from "../../../shared/ui/loadable";
import loading from "../../../shared/ui/loadable/loading";
import AppOptions from "../../../widgets/app-options";
import strings from "../../../services/localizationService";
import DialogHeader from "../dialog-header";
import WalletModel from "../../../models/walletModel";
interface GamePaymentDialogProps extends DialogProps<WalletModel[]>{
    contextVlue:GameContextValue
}

interface PlayerPaymentStatus {
picture:string,
name:string,
haveToPay:number,
playerId:number,
paid:number,
paidOption:number,
alreadyPaid:boolean
}

function GamePaymentsDialog(contextVlue:GameContextValue):IDialog<WalletModel[]>{
    return {
        show: () => open((resolve) => <GamePlaymentsView contextVlue={contextVlue} resolve={resolve.resolve} />)
    }
}

function GamePlaymentsView({contextVlue, resolve}:GamePaymentDialogProps){
    const [members, setMembers] = useState<PlayerPaymentStatus[]>([]);
    useEffect(() =>{
        viewModels.members.getMembers(contextVlue.game.clubId, (result) =>{
            const playersToDisplay = contextVlue.registeredPlayers.filter(x => x.status === PlayerStatus.Regular || x.status === PlayerStatus.Guest)
            .map(x => {
                const points = result.find(y => y.clubMemberId === x.playerId)!.points;
                const haveToPay = contextVlue.game.fee! - points;
                return {
                    picture:x.picture,
                    name:x.name,
                    playerId:x.playerId,
                    haveToPay: haveToPay,
                    paid:0,
                    paidOption:0,
                    alreadyPaid:haveToPay <= 0
                }
            })
            setMembers(playersToDisplay);
            loading.stop("paymentDetails");
        });
    }, [])

    
    return <Loadable View={() => <GamePlaymentsViewInternal members={members} resolve={resolve} clubId={contextVlue.game.clubId} />} 
        id="paymentDetails"
        startOnRendering
        screenCentered/> 
}

function GamePlaymentsViewInternal({members, resolve, clubId}:{members:PlayerPaymentStatus[], 
    resolve:(value: WalletModel[] | PromiseLike<WalletModel[] | undefined> | undefined) => void, clubId:number}){
    const [name, setName] = useState('');
    const [onlyWhoShouldPay, setOnlyWhoShouldPay] = useState(true);
    const [whoPaidNow, setWhoPaidNow] = useState<PlayerPaymentStatus[]>([]);
    let toDisplay = !name ? members : members.filter(x => x.name.toLowerCase().includes(name.toLowerCase()));
    if(onlyWhoShouldPay)
        toDisplay = toDisplay.filter(x => x.haveToPay > x.paid && x.paidOption === 0 && !x.alreadyPaid);

return <div className="dialog-view">
    <div className="whoShouldPayOnly" onClick={() => setOnlyWhoShouldPay(!onlyWhoShouldPay)}>
        <Typography>{strings.Placeholders.WhoShouldPay}</Typography>
    <input type="CheckBox" checked={onlyWhoShouldPay} onChange={(e) => setOnlyWhoShouldPay(e.target.checked)} />
    </div>
<TextField value={name} className="search" onChange={(e) => setName(e.currentTarget.value)}  variant='outlined' placeholder={strings.SearchMembers}></TextField>

<div className="players-payment-details">
    {toDisplay.length > 0 ? toDisplay.map(x => <Player key={x.playerId} item={x} verifyCanExecute={() => setWhoPaidNow(members.filter(x => x.paid != 0 || (x.paidOption === 1 && !x.alreadyPaid)))} />) :
    <p>{strings.Placeholders.ListEmpty}</p>}
</div>
<DialogHeader
resolve={resolve}
steps={[{
    canGoFoward:() => whoPaidNow.length > 0,
    onForward:()=> resolve(whoPaidNow.map(x => {
        return {
            clubId,
            clubMemberId:x.playerId,
            operationType:WalletOperation.Supply,
            points:x.paidOption === 0 ? x.paid : x.haveToPay,
            updateValue:x.paidOption === 0 ? x.paid - x.haveToPay : 0
        }
    }))
}]} 
/>
</div>
}

function Player({item, verifyCanExecute}:{item:PlayerPaymentStatus, verifyCanExecute:() => void}){
    const {paid, paidOption, picture, name, haveToPay} = item;
    const [paidInternal, setPaidInternal] = useState(paid);
    const [option, setOption] = useState(paidOption);
    const haveToPayLabel = paidInternal >= haveToPay || option === 1 ? strings.Placeholders.NothingToPay : `${strings.Placeholders.HaveToPay}: ${haveToPay - paid}`;
    return <div className="player-payment-details">
        <img src={picture ?? require("../../../assets/avatar.jpg")} />
        <Typography>{name}</Typography>
        <Typography>{haveToPayLabel}</Typography> 
        {!item.alreadyPaid && <AppOptions optionType="PlayerPayment"
        defaultValue={paidOption}
        onStateChanged={(value) => {
            setOption(value);
            item.paidOption = value;
            verifyCanExecute();
        } }
        />}
       {(paidOption === 0 || item.alreadyPaid) && <TextField type="number" value={paid} onChange={(e) => {
        item.paid = +e.target.value;
        setPaidInternal(item.paid);
       }}
       onBlur={verifyCanExecute}
       label={item.alreadyPaid ? strings.PlayerPaymentOffer : ''}
       />}
    </div>
}

export default GamePaymentsDialog;