import { Fab, Typography } from '@mui/material';
import { Remove } from '@mui/icons-material';
import React, { MouseEventHandler, useState } from 'react';
import ClubAgenda from '../../../../entities/clubAgenda';
import strings from '../../../../services/localizationService';
import './index.css';

interface AgandaItemProps {
    item: ClubAgenda,
    clubId: number
    remove: (clubId: number, agendaId: number) => void;
}

function AgandaItem({ item, remove, clubId }: AgandaItemProps) {
    

    return <div key={item.id} className='club-list-agenda'>
        <div>
            <p>{strings.daysOfWeek[item.day]}</p>
            <p>{item.start.substring(0, 5)} - {item.end.substring(0, 5)}</p>
        </div>  
        <Fab
            size="small" onClick={() => remove(clubId, item.id)} color="secondary">
                <Remove />
            </Fab>
    </div>;
}

export default AgandaItem;