import FeedbackGroup from "../../entities/FeedbackGroup";
import { SportType } from "../../entities/enums";
import Player from "../../entities/player";
import PlayerForFeedback from "../../entities/playerForFeedback";
import teamService from "../../services/teamService";
import apiRequest from "../../shared/apiRequest";

async function getPlayers(onSuccess:(result:FeedbackGroup[]) => void){
    await  apiRequest.execute(() => teamService.getPlayers(), onSuccess);
}
export default {getPlayers}