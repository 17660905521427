import React from 'react';
import Joi from 'joi';
import joiValidation from '../joiValidationMessages';
import { TextField, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import localizationService from '../../services/localizationService';

export interface ExecuteProps{
    schema:Joi.Schema,
    state: any,
    errors: any,
    onFail: (errors:any) => void,
    onSucces: () => void
}
const GetSchema = (schema:Joi.Schema) => joiValidation.getSchemaWithValidationMessages(schema);
const GetUpdatedState = (state:any, e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) =>{
    const{name, value} = e.target;
    let newState = {...state};
    type ObjectKey = keyof typeof newState;
    var prop = name as ObjectKey
    newState[prop] = value;
    return newState;
}

const GetErrorsWithClearedField = (errors:any, e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) =>{
    let newErrors = {...errors};
    type ObjectKey = keyof typeof errors;
    const prop = e.target.name as ObjectKey;
    newErrors[prop] = '';
    return newErrors;
}

const HasErrors = (errorsState:any) => {
    type ObjectKey = keyof typeof errorsState;
        const errorKeys = Object.keys(errorsState);
        for(let i =0; i < errorKeys.length; i++){
            if(errorsState[errorKeys[i] as ObjectKey])
            return true;
        }
        return false;
}

const Execute = ({schema, errors, onSucces, state, onFail}: ExecuteProps) => {
    const newErrors = ValidateInputs(schema, state, errors);
    if(HasErrors(newErrors)) {
        onFail(newErrors);
        return;
    }
    onSucces();
}

const ValidateInputs = (schema:Joi.AnySchema, state:any, errors:any) =>{
    const validationErrors = GetSchema(schema).validate(state, {abortEarly:false});
        type ObjectKey = keyof typeof schema;
        let newErrors = {...errors};
        if(validationErrors.error){
            type ValidationFieldKeys = keyof typeof localizationService.validationErrorsByField;
            const errorDetails = validationErrors.error.details.filter(e => e.type !== "object.unknown").map(e => {
                const key = e.path + '.' + e.type as ValidationFieldKeys;
                const message = !!localizationService.validationErrorsByField[key] ? localizationService.validationErrorsByField[key]: e.message;
                return {path:e.context?.key, message: message}});
            errorDetails.forEach(element => {
                const prop = element.path as ObjectKey;
                newErrors[prop] = element.message;
            });
        }
    return newErrors;
}

export interface InputWithValidationProps{
    textFieldProps: TextFieldProps,
    errorFieldProps?:TypographyProps,
    errorMessage:string,
    header?:string
}

function InputWithValidation(props:InputWithValidationProps){
    const errorMessageProps = Object.assign({style:{marginBottom: 15, color:"red"}}, props.errorFieldProps);
    const textFieldProps = Object.assign({variant:"outlined",type:"text"}, props.textFieldProps);
    return <div className='app-input-container'>
    <Typography className='app-input-header'>{props.header}</Typography>
    <TextField {...textFieldProps} />
        <Typography {...errorMessageProps}>{props.errorMessage}</Typography>
    </div>
}

export {ValidateInputs, GetErrorsWithClearedField, GetUpdatedState, GetSchema, InputWithValidation, HasErrors, Execute};