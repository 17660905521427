import React, { useRef, useState } from 'react';
import Joi from 'joi';
import { Typography } from '@mui/material';
import AppButton from '../shared/ui/appButton';
import authService from '../services/authService';
import * as _ from '../shared/ui/AppFormHelper';
import localizationService from '../services/localizationService';
const schema = Joi.object({password:Joi.string().required().regex(/(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/).min(8),
repeatePassword:Joi.ref('password')});
interface ResetFormProps{
    email:string, passwordToken:string
}
function ResetForm({email, passwordToken}: ResetFormProps){
    const[state, setState] = useState({password:'', repeatePassword:''});
    const[errors, setErrors] = useState({password:' ', repeatePassword:' '});
    const reset = async () =>{
        try{
            await authService.reset(email, passwordToken, state.password);
            window.location.reload();
        }catch(error){

        }
    };

    const onChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) =>{
        setState(_.GetUpdatedState(state, e));
        setErrors(_.GetErrorsWithClearedField(errors, e));
    };

    return <form>
        <div style={{ display: "flex", flexDirection: "column", height:"50%", width:"50vh", padding:"30px", borderRadius:"5px" }}>
    <div style={{ display: "flex", flexDirection: "column" }}>
        <_.InputWithValidation textFieldProps={{name:"password", type:"password", placeholder:localizationService.Placeholders.Password, value:state.password, onChange:onChange}}
                               errorMessage={errors.password} />
        <_.InputWithValidation textFieldProps={{name:"repeatePassword", type:"password", placeholder:localizationService.Placeholders.RepaetePassword,
        value:state.repeatePassword, onChange:onChange}}
                               errorMessage={errors.repeatePassword} />
     </div>
     <AppButton disabled={_.HasErrors(errors)} label={localizationService.Reset} variant='outlined' color='primary' onClick={() => _.Execute({
        errors:errors,
        onFail:setErrors,
        onSucces:reset,
        schema:schema,
        state:state
     })} />
</div>
    </form>
}

export default ResetForm;