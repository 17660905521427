import React, { useReducer, useState } from "react";
import '../index.css'
import './index.css'
import IDialog from "../dialogs";
import Club from "../../../entities/club";
import { DialogProps, open } from "../dialogHelpers";
import strings from "../../../services/localizationService";
import { Execute, InputWithValidation, ValidateInputs } from "../../../shared/ui/AppFormHelper";
import { ClubAccesability, ClubPayment, ClubScope, SportType, GuestsRegistrationStrategy, PaymentType, RegularsRegistartionType } from "../../../entities/enums";
import AppOptions from "../../../widgets/app-options";
import AddressSeach from "../../../features/address-search";
import Joi from "joi";
import viewModels from "../../../viewModels";
import FileUploader from "../../../widgets/file-uploader";
import DialogHeader from "../dialog-header";
import { toast } from "react-toastify";
import ClubSettings from "../../../entities/clubSettings";
import { DropDown } from "../../../widgets/drop-down";
import YesNoOption from "../../../widgets/yes-no-option";
  interface ClubRecordChange{
    propsKey: string;
    propValue: any;
  }
  type ClubDialogAction = {
    valueToChange:ClubRecordChange[]
  }

  export interface ClubDialogProps extends ClubSettings{
    id?:number,
    name:string,
    clubType:SportType,
    hasFee?:boolean,
    editMode?:boolean,
    file?:File,
    newFilePath?:string,
    regularsRegistrationType:RegularsRegistartionType,
    hasPenalities:boolean
}

const initialState : ClubDialogProps = {
    name:'',
    clubType:SportType.ClassicVolleyball,
    scope:ClubScope.Game,
    address:'',
    fee:0,
    feeIsFixed:true,
    isPublic:true,
    maxPlayers:12,
    minPlayers:4,
    playersMinLevel:0,
    paymentType:PaymentType.ByHour,
    regularsRegistrationType:RegularsRegistartionType.Anytime,
    hasPenalities:false,
    canOthersInviteGuests:false
}

  const counterReducer = (state: ClubDialogProps, action: ClubDialogAction):ClubDialogProps => {
    const { valueToChange } = action;
    let result = {...state};
    type ObjectKeys = keyof typeof state;
    valueToChange.forEach(item =>{

        const prop = item.propsKey as ObjectKeys;
        result[prop] = item.propValue as never;
    })
    return result;
  }

const Create : IDialog<Club | undefined> = {
    show:() => open(ClubView)
};

const Modify = (state:Club): IDialog<Club | undefined> =>{
    return {
        show:() => open((resolve) => <ClubView resolve={resolve.resolve} sendingProps={{...state, editMode:true, hasFee:state.fee > 0, 
            regularsRegistrationType:state.openRegularsRegistrationHours ? RegularsRegistartionType.HoursBeforeStart : RegularsRegistartionType.Anytime,
        hasPenalities: state.penaltyForRemovingSameDay != undefined || state.penaltyForNotComming != undefined}} />)
    }
}

const schema = Joi.object({name:Joi.string().required(), 
    address:Joi.string().required(),
    isPublic:Joi.boolean(),
    fee:Joi.number().min(0),
    hasPenalities:Joi.boolean(),
    regularsRegistrationType:Joi.number(),
    playersMinLevel:Joi.number().min(0).max(9),
    maxPlayers:Joi.number().min(4),
    minPlayers:Joi.number().min(1),
    openGuestRegistrationHours:Joi.any(),
    guestsRegistrationStrategy:Joi.any(),
    penaltyForRemovingSameDay:Joi.when(Joi.ref("hasPenalities"), {
        is: true, then:Joi.number().max(90)
    }),
    penaltyForNotComming: Joi.when(Joi.ref("hasPenalities"), {
        is: true, then:Joi.number().min(100).max(200)
    }),
    openRegularsRegistrationHours:Joi.when(Joi.ref('regularsRegistrationType'), {
        is:1, then:Joi.when(Joi.ref("guestsRegistrationStrategy"),{
            is:0, then:Joi.number().min(Joi.ref("openGuestRegistrationHours"))
        })
       }),
    codeAccess:Joi.when(Joi.ref('isPublic'), {
     is:false, then:Joi.string().required()
    })})
interface ClubViewPros extends  DialogProps<Club>{
    sendingProps?:ClubDialogProps
} 
function ClubView({resolve, sendingProps}:ClubViewPros){
    const [state, dispatching] = useReducer(counterReducer, sendingProps ?? initialState);
    const [errors, setErrors] = useState({} as any);
    const onChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
        dispatching({valueToChange:[{ propsKey:e.currentTarget.name, propValue:e.currentTarget.value}]});
    }
    return <div className="dialog-view club-dialog">
        <p className="dialog-title">{!state.editMode ? strings.AddClub : strings.Modify}</p>
        
        {!state.editMode && <InputWithValidation header={strings.ClubName} errorMessage={errors?.name} textFieldProps={{
            placeholder:strings.Club,
            name:'name',
            onChange}} />}
            <FileUploader title={`${strings.Pic} ${strings.Club}`}
            image={state.newFilePath ?? state.picture}
            onImageSelected={(file, picture) =>{
                dispatching({valueToChange:[
                    {propsKey:"newFilePath", propValue:picture},
                    {propsKey:"file", propValue:file}
                ]})
            }} />
        <AddressSeach address={state.address} errorMessage={errors?.address}
         onInputChange={onChange} setState={(model) => {
            dispatching({valueToChange:[{
                propsKey:'place_id', propValue:model.place_id
            },
            {
                propsKey:'description', propValue:model.description
            }]})
         }} />
        {!state.editMode && <AppOptions optionType="Type" onStateChanged={(index) => dispatching({valueToChange:[{ propsKey:'clubType', propValue:index}]})} />}
        <AppOptions defaultValue={state.scope} optionType="Scope" onStateChanged={(index) => dispatching({valueToChange:[{propsKey:'scope', propValue:index}]})} />
        
        <YesNoOption defaultValue={state.isPublic} title={strings.ClubAccesability} onStateChanged={(value) => {
            
            const isPublicChanger = {propsKey:'isPublic', propValue:value};
            const strategyChanger = {propsKey:'guestsRegistrationStrategy', propValue:value ? undefined : GuestsRegistrationStrategy.HoursBeforeGame};
            const openHoursChanger = {propsKey:'openGuestRegistrationHours', propValue:value ? undefined : 6};
            dispatching({valueToChange:[isPublicChanger, strategyChanger, openHoursChanger]});
         }} />
         <YesNoOption defaultValue = {state.canOthersInviteGuests} title={strings.CanOtherInviteGuests} onStateChanged={(value) =>{
            dispatching({valueToChange:[{propsKey:'canOthersInviteGuests', propValue:value}]});
         }} />
         <YesNoOption
          title={strings.Placeholders.CanBeFined}
          defaultValue={state.hasPenalities} 
          onStateChanged={(value) => {
            const hasPenalitiesChanger = {propsKey:'hasPenalities', propValue:value};
            const penaltyForRemovingSameDayChanger = {propsKey:'penaltyForRemovingSameDay', propValue:value ? 0 : undefined};
            const penaltyForNotCommingChanger = {propsKey:'penaltyForNotComming', propValue:value ? 100 : undefined};
            dispatching({valueToChange:[hasPenalitiesChanger, penaltyForRemovingSameDayChanger, penaltyForNotCommingChanger]});
          }}
          />

        <DropDown title={strings.Placeholders.PlayersRestrictions}>
            <><InputWithValidation
        errorMessage={errors?.maxPlayers}
        header={`${strings.Club} ${strings.MaxPlayers}`}
        textFieldProps={{ type:"number", defaultValue:state.maxPlayers,
    name:'maxPlayers', onChange}}
         />
         <InputWithValidation
        errorMessage={errors?.minPlayers}
        header={`${strings.Club} ${strings.MinPlayers}`}
        textFieldProps={{ type:"number", defaultValue:state.minPlayers,
    name:'minPlayers', onChange}}
         />

<InputWithValidation
        errorMessage={errors?.playersMinLevel}
        header={`${strings.Club} ${strings.PlayersMinLevel}`}
        textFieldProps={{ type:"number", defaultValue:state.playersMinLevel,
    name:'playersMinLevel', onChange}}
         /></>
        </DropDown>
{!state.isPublic && <DropDown title={strings.Placeholders.Codes}

><><InputWithValidation
        errorMessage={errors?.codeAccess}
        header={strings.MemberCode}
        textFieldProps={{
            name:'codeAccess',
            defaultValue:state.codeAccess,
            onChange
         }}
         />
         <AppOptions defaultValue={state.regularsRegistrationType} optionType="RegularsOpenRegistrationHours"
          onStateChanged={(index) => {
            const registrationHours:number|undefined = index == RegularsRegistartionType.Anytime ? undefined : 24;
            dispatching({valueToChange:[{propsKey:'regularsRegistrationType', propValue:index},
                {propsKey:"openRegularsRegistrationHours", propValue:registrationHours}
            ]})
          }} />
          {state.regularsRegistrationType === RegularsRegistartionType.HoursBeforeStart && <InputWithValidation
          errorMessage={errors?.openRegularsRegistrationHours}
          textFieldProps={{type:"number",
        name:'openRegularsRegistrationHours',
    defaultValue:state.openRegularsRegistrationHours,
    onChange
}} 
          />}
         <InputWithValidation
         errorMessage=''
         header={strings.GuestCode}
         textFieldProps={{ 
            defaultValue:state.guestCodeAccess,
            name:'guestCodeAccess',
            onChange
         }}
          />
          
          <AppOptions defaultValue={state.guestsRegistrationStrategy} optionType="RegistrationStrategy"
          onStateChanged={(index) => {
            dispatching({valueToChange:[{propsKey:'guestsRegistrationStrategy', propValue:index}]})
          }} />
          {state.guestsRegistrationStrategy === GuestsRegistrationStrategy.HoursBeforeGame && <InputWithValidation
          errorMessage={errors?.openGuestRegistrationHours}
          textFieldProps={{type:"number",
        name:'openGuestRegistrationHours',
    defaultValue:state.openGuestRegistrationHours,
    onChange
}} 
          />}</>
          </DropDown> }
          <DropDown title={strings.Fee}>
<><AppOptions defaultValue={!state.fee || state.fee === 0 ? ClubPayment.Free : (state.feeIsFixed ? ClubPayment.PerPerson : ClubPayment.PerGame)} optionType="Payment" onStateChanged={(index) => {
            const hasFee = index !== ClubPayment.Free;
            dispatching({valueToChange:[{propsKey:'hasFee', propValue:hasFee}, {propsKey:'feeIsFixed', propValue:index === ClubPayment.PerPerson}]});
          }} />
          
         {state.hasFee && 
         <>
         <InputWithValidation
         errorMessage={errors?.fee}
         textFieldProps={{ type:"number",
         defaultValue:state.fee,
        placeholder:state.feeIsFixed ? strings.FeePerPlayer : strings.FeePerGame,
    name:'fee',
onChange }}
          />
          <AppOptions optionType="PaymentType" defaultValue={state.paymentType} 
          onStateChanged={(index) =>{
            dispatching({valueToChange:[{propsKey:'paymentType', propValue:index}]})
          }}
          />
          </>}
</>
          </DropDown>
         {state.hasPenalities && <DropDown title={strings.Placeholders.Penalties}>
            <><InputWithValidation
         errorMessage={errors?.penaltyForRemovingSameDay}
         header={strings.Placeholders.PenaltyFor24Hours}
         textFieldProps={{ type:"number",
         defaultValue:state.penaltyForRemovingSameDay,
    name:'penaltyForRemovingSameDay',
onChange }}
          />
          <InputWithValidation
         errorMessage={errors?.penaltyForNotComming}
         header={strings.Placeholders.PenaltyForNotComing}
         textFieldProps={{ type:"number",
         defaultValue:state.penaltyForNotComming,
    name:'penaltyForNotComming',
onChange }}
          /></>
          </DropDown>}
          <DialogHeader
        resolve={resolve}
        steps={[{
            canGoFoward:() => true,
            onForward:() => Execute({
                errors:{},
                onFail:setErrors,
                onSucces: async () => {
                    console.log("execut")
                   const result = await viewModels.club.create({...state});
                   toast.info(strings.Placeholders.OperationCompleted);
                   resolve(result);
                },
                schema:schema,
                state:state
            })
        }]}
         />
    </div>
}

export default {Create, Modify}