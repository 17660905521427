import { localStorageKeys } from "../../constants";
import RegistrationModel, { LoghinModel } from "../../models/registrationModel"
import authService from "../../services/authService"
import localStorageService from "../../services/localStorageService";
import apiRequest from "../../shared/apiRequest";
import open from '../../dialogs/ui/dialog';
import { ResetPassword} from '../../dialogs/index';
import { toast } from "react-toastify";
import localizationService from "../../services/localizationService";
import dialogs from "../../components/dialogs";

function login(model:LoghinModel){
    return apiRequest.execute(() => authService.emailLogin(model),async (auth) => {
        if(!auth.succeeded){
            await dialogs.Info(auth.error);
            return;
        }
        console.log(auth);
        localStorageService.setItem(localStorageKeys.playerId, auth.id);
        window.location.reload();
    });
}
async function onForgotPassword(){
    var result = await open(ResetPassword);
    if(result)
    toast.info(localizationService.ResetLinkSend);
};
export default {login, onForgotPassword}