import React, { useRef, useState } from "react";
import IDialog from "../dialogs";
import { DialogProps, open } from "../dialogHelpers";
import { ClubScope } from "../../../entities/enums";
import '../index.css'
import './index.css'
import { Button, TextField, Typography } from "@mui/material";
import TimeInput, { getTimeById } from "../../../widgets/time-input";
import strings from "../../../services/localizationService";
import { toast } from "react-toastify";
import DialogHeader from "../dialog-header";
const GameDialog:IDialog<AddGameProps | undefined>={
    show:() => open(AddGameView)
}
const scopes = Object.values(ClubScope).filter(x => typeof(x) === 'number').map(x => x as ClubScope);

export interface AddGameProps{
    date:string,
    type:ClubScope,
    start:string,
    end:string
}
function AddGameView({resolve}:DialogProps<AddGameProps | undefined>){
    const [selectedOption, setSelectedOption] = useState(ClubScope.Game);
    const dateRef = useRef<HTMLTextAreaElement>(null);
    const onOk = () =>{
        const selectedDate = new Date(dateRef.current?.value!);
        let valid = true;
        const time = getTimeById("gameTime").time;
        selectedDate.setHours(time.startTimeSpan.hours, time.startTimeSpan.minutes);
        console.log(selectedDate);
        valid = selectedDate.valueOf() >= Date.now();
        if(!valid)
        toast.error(strings.validationErrorsByField["start.string.min"]);
        selectedDate.setHours(time.startTimeSpan.hours, time.startTimeSpan.minutes) ;
        valid = valid && time.start < time.end;
        if(time.start > time.end)
        toast.error(strings.validationErrorsByField["end.string.min"]);
        if(valid)
        resolve({type:selectedOption, date:dateRef.current?.value!, ...time});

    }
    return <div className="dialog-view">
        <div className="centered-dialog">
        <p className="dialog-title">{strings.AddGame}</p>
        <TextField type="date" className="game-date" variant="outlined" defaultValue={getDateAsString()}
        inputRef={dateRef}
         />
        <TimeInput id="gameTime" />
        <div className="game-scopes">{scopes.map(x => <Button key={x} onClick={() => setSelectedOption(x)} className={x === selectedOption ? "app-option-button active" : "app-option-button"}>{strings.ClubScopes[x]}</Button>)}</div>
        </div>
        <DialogHeader
        resolve={resolve}
        steps={[{
            canGoFoward:() => true,
            onForward:onOk
        }]}
         />
    </div>
}

function getDateAsString(){
    const date = new Date(Date.now());
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${year}-${month}-${day}`;
}
export default GameDialog;