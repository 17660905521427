import { Button } from "@mui/material";
import React, { useContext } from "react";
import strings from "../../services/localizationService";
import GameContext from "../../context/gameContext";
import dialogs from "../../components/dialogs";
import viewModels from "../../viewModels";
import { toast } from "react-toastify";

export default function ViewPayments(){
    const context = useContext(GameContext);

    const onClick = async () => {
        const result = await dialogs.GamePaymentsDialog(context).show();
        if(!result || result.length === 0) return;
        viewModels.members.payments(context.game.clubId, result, () => toast.info(strings.Placeholders.OperationCompleted));
    }

    return <Button color="primary" variant="contained"
    onClick={onClick}>
        {strings.PaymentDetails}
    </Button>
}