import React from "react";
import strings from "../../services/localizationService";
import RestrictionView from "../../widgets/restriction-view";

function AccessDeniedPage(){
    return <RestrictionView image={require('../../assets/redCard.png')}
                            message={strings.AccessDenied}
    />
}

export default AccessDeniedPage;