import Game from "../../entities/game";
import { GuestsRegistrationStrategy } from "../../entities/enums";
import clubService from "../../services/clubService";
import gameService from "../../services/gameService";
import apiRequest from "../../shared/apiRequest";

let strategy:GuestsRegistrationStrategy;
let registrationOpeded:boolean;
function setFields(newStrategy:GuestsRegistrationStrategy, state:boolean){
    strategy = newStrategy;
    registrationOpeded = state;
}
async function open(game:Game, onSuccess: () => void){
    return apiRequest.execute(() => gameService.openRegistration(game), onSuccess);
}
function isActive(){
    const isAdmin = clubService.isAdmin();
    return isAdmin && strategy == GuestsRegistrationStrategy.Manual && !registrationOpeded;
}
export default {isActive, setFields, open}