import { toast } from "react-toastify";
import { eventNames } from "../../constants";
import Feedback from "../../entities/feedback";
import Skill from "../../entities/skill";
import strings from "../../services/localizationService";
import teamService from "../../services/teamService";
import apiRequest from "../../shared/apiRequest";
import events from "../../shared/events";


function giveFeedback(feedback:Feedback, onSuccess?:(newValues:Skill[]) => void){
    return apiRequest.execute(() => teamService.give(feedback), () => {
        events.trigger(eventNames.skillsEvaluated, {id:feedback.playerId, sportType:feedback.sportType});
        onSuccess?.(feedback.skills);
        toast.info(strings.Volleyball.Evaluated);})
}
export default { giveFeedback}