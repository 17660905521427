import React from 'react';
import localizationService from '../../services/localizationService';
import AppButton from '../../shared/ui/appButton';
import Model from '../../viewModels';

export const Logout = () => {
    const logout = async () =>{
        Model.user.logout(() =>{
            window.location.reload();
        })
    }
    
    return <AppButton color="secondary" variant='text' label={localizationService.Logout} style={{marginRight:20}}
                       onClick={logout}
                       confirmationmessage={localizationService.Confirmations.Logout}/>
}