import React from "react";
import '../index.css'
import { DialogProps, open } from "../dialogHelpers";
import strings from "../../../services/localizationService";
import { Button } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import DialogHeader from "../dialog-header";
interface InfoProps extends DialogProps<boolean>{
    message:string
}

async function Info(message:string){
    await open((resolve:DialogProps<boolean>) => InfoView({message:message, resolve:resolve.resolve}))
}

async function Confirmation(message:string){
    return await open((resolve:DialogProps<boolean>) => ConfirmationView({message:message, resolve:resolve.resolve}))
}

function ConfirmationView({message, resolve}:InfoProps){
    return <div className="dialog-view centered-dialog">
<p className="dialog-title">{strings.Confirmation}</p>
<p>{message}</p>
<DialogHeader
resolve={resolve}
steps={[{
    canGoFoward:() => true,
    onForward:() => resolve(true)
}]} 
/>
    </div>
}

function InfoView({message, resolve}:InfoProps){
    return <div className="dialog-view">
        <div className="centered-dialog">
<p className="dialog-title">{strings.Info}</p>
<p>{message}</p>
</div>
<div className="close-info">
<Button color="primary" variant="outlined" onClick={() => resolve(true)}><Check /></Button></div>
    </div>
}

export default {Info, Confirmation}

