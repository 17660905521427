export default {
    en:{
        Text:[{
            Section:"We will ask and collect next information, if you register by email",
            Details:["Email",
             "Password for you account",
              "Contact Info"
            ]
        },
        {
            Section:"We will ask and collect next information from social provider, if you register by social login",
            Details:["Email",
                    "Picture"]
        },
        {
            Section:"Information Storage and use",
            Details:["All information that you provide will be stored on app database",
                     "Information will be use for indentify in our system",
                    "Your contact info provided by email registere and your picture - provided by social provider(or by your self - by uploading in profile screen) - will be visible to all users",
                "No information is shared with third parties"]
        },
        {
            Section:"We are using cookie",
            Details:["Cookies are use for authorization and authontification in our system"]
        },
    {
        Section:"Your rights",
        Details:["You can modify your contact info anytime and your profile picture(if is not provided by social providers)"]
    },
    {
        Section:"Contact us if there are any questions:",
        Details:["By email:info@play-all-sports.com",
                 "By phone:+373600070685"]
    }]
},
    ro:{
        Text:[{
            Section:"Vom cere și colecta următoarele informații, dacă vă înregistrați prin e-mail",
            Details:["E-mail",
             "Parola pentru contul dvs.",
              "Informatii de contact"
            ]
        },
        {
            Section:"Vom cere și vom colecta următoarele informații de la rețiaua de socializare, dacă vă înregistrați prin ea",
            Details:["E-mail",
                    "Fotografia"]
        },
        {
            Section:"Depozitarea și utilizarea informației",
            Details:["Toate informațiile pe care le furnizați vor fi stocate în baza de date a aplicației",
                     "Informațiile vor fi utilizate pentru identificare în sistemul nostru",
                    "Informațiile dvs. de contact furnizate la înregistarea prin e-mail și fotografia dvs. - furnizată de rețiaua de socializare (sau de dvs. personal - prin încărcarea în pagina de profil) - vor fi vizibile pentru toți utilizatorii",
                "Nu sunt partajate informații cu părțile terță"]
        },
        {
            Section:"Folosim cookie-uri",
            Details:["Cookie-urile sunt utilizate pentru autorizare și autentificare în sistemul nostru"]
        },
    {
        Section:"Drepturile dumneavoastră",
        Details:["Puteți modifica oricând informațiile de contact și fotografia de profil (dacă nu este furnizată de rețiaua de socializare)"]
    },
    {
        Section:"Contactați-ne dacă aveți întrebări:",
        Details:["Prin e-mail:info@play-all-sports.com",
                 "Prin telefon:+373600070685"]
    }]
    },
    ru:{
        Text:[{
            Section:"Мы спросим и соберем следующую информацию, если вы зарегистрируетесь по электронной почте",
            Details:["Электронная почта",
             "Пароль для вашей учетной записи",
              "Контактная информация"
            ]
        },
        {
            Section:"Мы будем запрашивать и собирать следующую информацию у социального провайдера, если вы зарегистрируетесь через социальный логин",
            Details:["Электронная почта",
                    "Фото"]
        },
        {
            Section:"Хранение и использование информации",
            Details:["Вся предоставленная вами информация будет храниться в базе данных приложения",
                     "Информация будет использоваться для идентификации в нашей системе",
                    "Ваша контактная информация, предоставленная по электронной почте, и ваше фото, предоставленное социальным провайдером (или вами самим — путем загрузки на экране профиля) — будут видны всем пользователям",
                "Никакая информация не передается третьим лицам"]
        },
        {
            Section:"Мы используем cookie",
            Details:["cookie используются для авторизации и аутентификации в нашей системе"]
        },
    {
        Section:"Ваши права",
        Details:["Вы можете в любое время изменить свою контактную информацию и изображение своего профиля (если оно не предоставлено социальными провайдерами)"]
    },
    {
        Section:"Свяжитесь с нами, если возникнут вопросы:",
        Details:["По электронной почте:info@play-all-sports.com",
                 "По телефону:+373600070685"]
    }]
    }
}