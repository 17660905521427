import React, { useState } from "react";
import User from "../../entities/user";
import { TextField, Typography } from "@mui/material";
import strings from "../../services/localizationService";
import { DropDown } from "../drop-down";
import './index.css';
interface GroupingProps<TUser extends User> extends ViewProps<TUser>{
    title:string
}

interface ViewProps<TUser extends User>
{
    items:TUser[],
    View:(item:TUser, isSearchModel?:boolean) => JSX.Element,
    AdditionHeader?:() =>  JSX.Element
}
interface UserGroupCondition<TUser extends User>{
    title:string,
    condition:(value: TUser, index: number, array: TUser[]) => unknown
}
interface UsersGroupingViewProps<TUser extends User> extends ViewProps<TUser>{
    groups:UserGroupCondition<TUser>[]
}

export function UsersGroupingView<TUser extends User>({items, View, groups, AdditionHeader}:UsersGroupingViewProps<TUser>){
    const [memberName, setMemeberName] = useState('');
    const filteredMembers = items.filter(x => x.name.toLowerCase().includes(memberName?.toLowerCase()));
return <div className='users-grouping'>
{AdditionHeader?.()}
{items.length > 0 && <><TextField value={memberName} onChange={(e) => setMemeberName(e.currentTarget.value)}
className='users-search-view' variant='outlined' placeholder={strings.SearchMembers}></TextField>
{memberName && UsersContainer({members:filteredMembers, View, isSeachModel:true})}
{!memberName && UsersByGroup({items, View, groups})}</>}
</div>
}

function UsersByGroup<TUser extends User>({items, View, groups}:UsersGroupingViewProps<TUser>){
    return <>
    <Typography variant='h6' style={{marginTop:10}}>{strings.TotalMembers} {items.length}</Typography>
    {groups.map(x => UsersSection({items:items.filter(x.condition), title:x.title, View}))}
    </>
}

function UsersSection<TUser extends User>({title, items, View}:GroupingProps<TUser>){
    return <DropDown 
    key={`users_group_${title}`}
    title={`${title} ${items.length}`}
    defaultState={false}
>{UsersContainer({members:items, View})}</DropDown>
    }

function UsersContainer<TUser extends User>({members, View, isSeachModel}:{members:TUser[], View:(item:TUser, isSearchModel?:boolean) => JSX.Element, isSeachModel?:boolean}){
    return members.length === 0 ? <Typography variant='h6'>{strings.NoMember}</Typography> : <div className='users-container'>
        {members.map(m => <div key={m.id} className='user-item-container'>
        <div className='user-item'>
        <img src={m.picture ?? require('../../assets/avatar.jpg')}/>
        <Typography>{m.name}</Typography>
        {View(m, isSeachModel)}
        </div>
    </div>)}</div>
    }