import { localStorageKeys } from "../../../constants";
import EmailSubscription from "../../../entities/emailSubscription";
import localStorageService from "../../../services/localStorageService";
import http from "../../../services/serverServices/http";
import playerService from "../../../services/serverServices/playerServices";
import apiRequest from "../../../shared/apiRequest";
import { convert } from "../../../shared/cookieToObject"
import { display } from "./email-subscription";

async function requestPermission(){
    if(!playerService.isLoggedIn()) return;
    await apiRequest.execute(() => http.get<EmailSubscription | undefined>("client/emailSubscription"),
    async (emailSubscription) => {
        if(emailSubscription){
            if(emailSubscription.enabled && !localStorageService.getItem(localStorageKeys.notificationAllowed))
            localStorageService.setItem(localStorageKeys.notificationAllowed, "true");
            return;
        }
        const email = convert<{Email:string}>("UserInfo")?.Email ?? '';
        const result = await display({
        email:email,
        emailIsValid:emailIsValid(email),
        enabled:false
    })
    await apiRequest.execute(() => playerService.subscribe(result ?? {
        email:email,
        enabled:false
    }));
    })
}
async function changePermission(){
    await apiRequest.execute(() => http.get<EmailSubscription>("client/emailSubscription"),
    async (result) => {

        const dialogResult = await display({
            email:result.email,
            enabled:result.enabled,
            emailIsValid:emailIsValid(result.email),
        })
        if(dialogResult)
        apiRequest.execute(() => playerService.subscribe(dialogResult));
    })
}
function emailIsValid(email:string | undefined){
return !!email && !email.includes("Facebook")
&& !email.includes("Google");
}
export default {requestPermission, changePermission}