import React from "react";
import AppButton from "../../shared/ui/appButton";
import model from '../../viewModels'
import strings from "../../services/localizationService";
function NotificationGroups(){
    return <AppButton
    label={strings.ManageNotifications}
    onClick={model.user.changePermission}
    color="primary"
    className="user-action-button"
    variant="contained"
     />
}

export default NotificationGroups;