import React, { useState } from "react";
import { DayOfWeek } from "../../entities/enums";
import { Button } from "@mui/material";
import strings from "../../services/localizationService";
import './index.css'
const daysOfWeek = Object.values(DayOfWeek).filter(x => typeof(x) === 'number').map(x => x as DayOfWeek);

export function DaysOfWeek({onDayClicked, itemAditionClasses}:{onDayClicked:(dayOfWeek:DayOfWeek) => void, itemAditionClasses?:string}){
    const [currentDay, setCurrentDay] = useState(DayOfWeek.Monday);
    const daySelected = (dayOfweek:DayOfWeek) =>{
        setCurrentDay(dayOfweek);
        onDayClicked(dayOfweek);
    }
    return <div className="days-of-week">
    {daysOfWeek.filter(x => x > 0).map(x => <DayOfWeekButton key={x} day={x} active={x == currentDay} onClick={() => daySelected(x)} itemAditionClasses={itemAditionClasses} />)}
    {<DayOfWeekButton  day={DayOfWeek.Sunday} active={DayOfWeek.Sunday === currentDay} onClick={() => daySelected(DayOfWeek.Sunday)}
    itemAditionClasses={itemAditionClasses}/>}
</div>
}

function DayOfWeekButton({day, active, onClick, itemAditionClasses}:{day:DayOfWeek, active?:boolean, onClick:(day:DayOfWeek) => void, itemAditionClasses?:string}){
    const classes = `app-option-button days-of-week-button${active ? ' active' : ''}${itemAditionClasses ? ` ${itemAditionClasses}` : ''}`;
    return <Button className={classes}
    onClick={() => onClick(day)}
    >{strings.daysOfWeek[day]}</Button>
}