import { Typography } from '@mui/material'
import React from 'react'
interface Props{
    title:string,
    borderColor?:string,
    fillColor?:string,
    Content:JSX.Element,
    opasity?:number
}
function SectionView({title, borderColor, fillColor, Content, opasity}:Props){
    return <div style={{borderWidth:borderColor ? 1 : 0, borderStyle:"solid",
     borderColor:borderColor, borderRadius:1, backgroundColor:fillColor, opacity:opasity ? opasity : 1,
     width:'100%'}}>
        <Typography style={{textAlign:'center'}}>{title}</Typography>
        {Content}
        </div>
}

export default SectionView;