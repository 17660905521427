import { Button, Fab, Typography } from '@mui/material';
import { AccountBalanceWallet, Close, Remove } from '@mui/icons-material';
import React, {  useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routers } from '../../constants';
import { AdjustType, PlayerStatus, WalletOperation } from '../../entities/enums';
import RegisteredPlayer from '../../entities/registeredPlayer';
import TeamMember from '../../entities/teamMember';
import './index.css';
import GameContext, { GamesContext } from '../../context/gameContext';
import viewModels from '../../viewModels';
import playerService from '../../services/serverServices/playerServices';
import strings from '../../services/localizationService';
import AdjustContext from '../../context/adjustContext';
interface RegisteredPlayerProps{
    item: RegisteredPlayer,
    viewedByAdmin?:boolean
}

export function RegisteredPlayerView(props:RegisteredPlayerProps){
    return props.item.status !== PlayerStatus.AdminGuest ? ClickableItem(props) : NonClickableItem(props);
}
interface TeamMemberProps{
    item:TeamMember,
    position?:string

}

export function TeamMemberView({item, position}:TeamMemberProps){
    const avatar = !!item.picture ? item.picture : require('../../assets/avatar.jpg');
    const {adjust, changeMember, onReplace, setChangeMember} = useContext(AdjustContext);

    const getAdjustType = () => {
        if(!adjust) return AdjustType.None;
        if(!changeMember) return AdjustType.Initiation;
        if(changeMember === item) return AdjustType.Cancel;
        if(changeMember.team === item.team && !position) return AdjustType.None;
        if(changeMember.team === item.team && item.position === changeMember.position) return AdjustType.None;
        if(changeMember.team !== item.team && item.position !== changeMember.position) return AdjustType.None;
        return AdjustType.Replace;
    }

    const adjustType = getAdjustType();
    const label = adjustType === AdjustType.Initiation ? strings.Modify : (adjustType === AdjustType.Replace ? `${strings.Placeholders.ReplaceWith} ${changeMember?.name}` : strings.Cancel);
    const onAdjust = () =>{
        if(adjustType === AdjustType.Initiation) return setChangeMember(item);
        if(adjustType === AdjustType.Cancel) return setChangeMember(undefined);
        onReplace(item);
    }

    return <div><div className="player-item">
    <div className='avatar'>
    <img src={avatar} />
    {position && <p className='player-position'>{position}</p>}
    </div>
    <Typography style={{ textAlign: "center"}}>{item.name}</Typography>
</div>
{adjustType !== AdjustType.None && <Button style={{marginBottom:10, justifySelf:"flex-end"}} onClick={onAdjust} variant='contained' color='primary'>{label}</Button>}
</div>
}

export function NonClickableItem(props:RegisteredPlayerProps){
    return <div className="player-item">
        {Content(props)}
</div>
}

function ClickableItem(props:RegisteredPlayerProps){
    return <Link className="player-item" to={routers.profile.replace(":id", props.item.playerId.toString())}>
        {Content(props)}
</Link>
}

function Content({item}: RegisteredPlayerProps){
    const {picture, name, status, playerId} = item;
    const avatar = !!picture ? picture : require('../../assets/avatar.jpg');
    const {game} = useContext(GameContext);
    const {games} = useContext(GamesContext);
    const onSuccess = () =>{
        const gameIndex = games.indexOf(game);
        const gamesToUpdate = games.filter((x, i) => x.clubId == game.clubId && i >= gameIndex);
         viewModels.game.recalculateFees(gamesToUpdate);
    }
    const remove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
        e.preventDefault();
        return viewModels.game.removePlayer({clubId:game.clubId, date:game.date, start:game.start, player:item, clubName:game.name},
            onSuccess);
    }
    
    return <>
    <div className='avatar'>
    <img src={avatar} />
    {game?.requestedPlayerStatus == PlayerStatus.Administrator || item.responsiblePlayerId === playerService.getPlayerId() ? <Fab onClick={remove} size='small' color="secondary"><Close className='close' /></Fab> : <></>}
    </div>
    <Typography style={{ textAlign: "center"}}>{name}</Typography>
    {item.resposableName && <a href={routers.profile.replace(":id", item.playerId.toString())} style={{ textAlign: "center"}}>({item.resposableName})</a>}
    </>
}