import { SportType } from "../../entities/enums";
import Player from "../../entities/player";
import Skill from "../../entities/skill";
import SkillsBySport from "../../entities/skillsBySport";
import SportSection from "../../entities/sportSection";
import strings from "../../services/localizationService";
import profileService from "../../services/profileService";
import teamService from "../../services/teamService";
import apiRequest from "../../shared/apiRequest";

function get(id:number, onSuccess:(result:Player) => void, onFailure: () => void){
    return apiRequest.execute(() => profileService.get(id), onSuccess, onFailure);
}
function updateInteres(sports:SportType[], onSuccess:() => void){
    return apiRequest.execute(() => profileService.updateInteres(sports), onSuccess);
}
function getSections(playerId:number, onSuccess:(reslut:SportSection[]) => void){
    return apiRequest.execute(() => profileService.getSportsInfo(playerId),onSuccess);
}
function getSkills(playerId:number, onSuccess:(reslut:SkillsBySport[]) => void){
    return apiRequest.execute(() => teamService.getSkillsForFeedback(playerId),onSuccess);
}

export default {get, getSkills, updateInteres, getSections}