import React, { useContext } from "react";
import { FillIndicators} from './fill-indicator';
import GameContext from "../../context/gameContext";
import { SportType } from "../../entities/enums";

export function CountIndicator(){
    return <div className="game-list-item-count-indicator">
        <CountDisplayView />
        <FillIndicators />
    </div>
}
const SportsLabel = Object.values(SportType).filter(x => typeof x === "string").map(x => x as string);
export function CountDisplayView(){
    const {game, registeredPlayers} = useContext(GameContext);
return <div className="registered-player-count">
<div className="body">
<div className="game-list-item-count-indicator-image-container">
    <img src={game.picture ?? require(`../../assets/${SportsLabel[game.type].toLowerCase()}_${game.clubId % 5 +  1}.jpg`)}/>
</div>
<div className="count">
    <p>
        {game.cancelled ? 0 : registeredPlayers.length}
    </p>
</div>
</div>
</div> 
}