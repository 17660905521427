import { Typography } from '@mui/material';
import React from 'react';
interface RestrictionViewProps{
    image:any,
    message:string
}

function RestrictionView({image, message}:RestrictionViewProps){
return <div style={{ marginTop: 50 }}>
<img src={image}></img>
<Typography variant='h4'>{message}</Typography>
</div>
}

export default RestrictionView;