import { Button, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import localizationService from '../../services/localizationService';
import model from './model';
interface EmailConfirmationParam{
    email:string
}
const EmailConfirmationPage = () => {
    const {email} = useParams();
    const resend = () =>{
        model.resend(email ?? '');
    };
    return <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:"center", height: '100vh'}}>
        <Typography variant='h4'>{localizationService.ConfirmEmail}</Typography>
    </div>;
}

export default EmailConfirmationPage;