import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FallbackProps } from "react-error-boundary";
import { toast } from "react-toastify";
import cacheManager from "../cacheManager";
import strings from "../services/localizationService";
import playerService from "../services/serverServices/playerServices";
import apiRequest from "../shared/apiRequest";
import './App.css'

function AppFallbackComponent(props:FallbackProps){
    const [isSend, setIsSend] = useState(true);
  
    useEffect(() =>{
      execute();
    }, []);

    const execute = async () =>{
      if(await cacheManager.clearFull()){
        return window.location.reload();
      }
      await apiRequest.execute(() => playerService.notifyAboutError({name:props.error.name, message:props.error.message, stack:props.error.stack}), () => {
        toast.info(strings.ErrorSend)
      }, () =>{
        setIsSend(false);
      })
    }

    return <div className="Error-Conatiner">
      <img src={require('../assets/appBroken.jpg')} />
    <Typography>{strings.GenericError}</Typography>
    {!isSend && <Typography>{strings.ErrorNotSend} {props.error.name} {props.error.message}</Typography>}
    </div>
  }

  export default AppFallbackComponent;