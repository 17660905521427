import { localStorageKeys } from "../../constants";
import EmailSubscription from "../../entities/emailSubscription";
import localStorageService from "../localStorageService";
import http from "../serverServices/http";

export default interface Subscriber{
    subscribe:() => Promise<unknown>
}

export function emailSubscriber(emailSubscription:EmailSubscription):Subscriber {
return{
    subscribe:() =>{
        return http.post("client/subscribe/email", emailSubscription)
    }
}
}

export const webSubscriber:Subscriber = {
    subscribe:() =>{
        return Notification.requestPermission().then((permission) => {
            if(permission !== "granted") return Promise.resolve();
            
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                  process.env.REACT_APP_PUSH_PUBLIC_KEY,
                ),
              };
              return navigator.serviceWorker.ready.then((registration) => {
                navigator.serviceWorker.controller?.postMessage({notificationIcon:require("../../assets/ball.png")});
                registration.pushManager.subscribe(subscribeOptions).then((subscription) => {
                    if(!localStorageService.getItem(localStorageKeys.notificationAllowed))
                    localStorageService.setItem(localStorageKeys.notificationAllowed, "true");
                    return http.post("client/subscribe", subscription)
                  })
              })
        
              function urlBase64ToUint8Array(base64String: string) {
                var padding = '='.repeat((4 - base64String.length % 4) % 4);
                var base64 = (base64String + padding)
                    .replace(/\-/g, '+')
                    .replace(/_/g, '/');
              
                var rawData = window.atob(base64);
                var outputArray = new Uint8Array(rawData.length);
              
                for (var i = 0; i < rawData.length; ++i) {
                    outputArray[i] = rawData.charCodeAt(i);
                }
                return outputArray;
              }
        })
    }
}