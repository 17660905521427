import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { localStorageKeys, routers } from "../../constants";
import { toast } from 'react-toastify';
import { ApiResponse } from '../../entities/apiResponse';
import localStorageService from '../localStorageService';
import cacheManager, { CachingNames } from '../../cacheManager';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
export const isLocalHost = process.env.REACT_APP_API_URL.includes("localhost");
axios.defaults.withCredentials = true;
axios.defaults.headers["time-zone-offset"] = -(new Date()).getTimezoneOffset();

let token:CancelTokenSource;
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response.data?.errorMessage)
    toast.error(error.response.data.errorMessage);

    if(error.response.status === 401)
    {
        localStorageService.removeItem(localStorageKeys.playerId);
        window.location.replace(routers.login);
    }
    if(error.response.status === 403)
    {
        window.location.assign(routers.accessDenied);
    }
  });
  axios.CancelToken.source();
async function get<Result>(path: string): Promise<Result> {
    token = axios.CancelToken.source();
    return execute(() => axios.get(path, {cancelToken:token.token}));
}
function setLanguage(language:string){
    axios.defaults.headers["Accept-Language"] = language;
}

async function _delete<Result>(path: string): Promise<Result> {
    token = axios.CancelToken.source();
    return execute(() => axios.delete(path, {cancelToken:token.token}));
}
interface CachingRequest{
    cacheName:CachingNames,
    cacheKey:string,
}
function getWithCaching<Result>(caching:CachingRequest, path: string){
    token = axios.CancelToken.source();
    return executeWithCaching<Result>(caching, (headers) => axios.get(path, {cancelToken:token.token, headers:headers}));
}
function postWithCaching<Result>(caching:CachingRequest, path: string, body?: any): Promise<Result>{
    token = axios.CancelToken.source();
    return executeWithCaching(caching, (headers) => axios.post(path, body, {cancelToken:token.token, headers:headers}));
}

async function post<Result>(path: string, body?: any): Promise<Result> {
    token = axios.CancelToken.source();
    return execute(() => axios.post(path, body, {cancelToken:token.token}));
}

async function put<Result>(path: string, body?: any): Promise<Result> {
    token = axios.CancelToken.source();
    return execute(() => axios.put(path, body, {cancelToken:token.token}));
}
async function cancelRequest(){
    if(token)
    token.cancel();
}

async function executeWithCaching<TResult>(request:CachingRequest, axiosRequest:(headers:any) => Promise<AxiosResponse<any>>){
    const cached = await cacheManager.getCacheData(request.cacheName, request.cacheKey);
    const hasCached = !!cached && (!Array.isArray(cached) || Array.isArray(cached) && cached.length > 0);
    try{
        var axiosResult = await  axiosRequest({"Caching-Request":JSON.stringify({...request, hasCached})});
       if(axiosResult === undefined || axiosResult.status === 304)
        return cached;
        const result = axiosResult.data as ApiResponse<TResult>;
        if(result.succeeded)
        {
            await cacheManager.storeCacheData(request.cacheName, request.cacheKey, result.result);
            return result.result;
        }
        toast.error(result.errorMessage, {autoClose:false});
        return Promise.reject({apiRequestFailded: true});
    }catch(error){
        if(hasCached)
        return Promise.resolve(cached);
        return Promise.reject(error);
    }
}

async function execute<TResult>(request:() => Promise<AxiosResponse<any>>){
    try{
        const { data } = await request();
        const apiResponse = data as ApiResponse<TResult>;
        if(apiResponse.succeeded)
        return Promise.resolve(apiResponse.result);
        toast.error(apiResponse.errorMessage, {autoClose:false});
        return Promise.reject({apiRequestFailded: true});
    }
    catch(error){
        return Promise.reject(error);
    }
}

export default {
    get,
    post,
    _delete,
    put,
    setLanguage,
    cancelRequest,
    postWithCaching,
    getWithCaching
}