import { toast } from "react-toastify";
import cacheManager from "../../cacheManager";
import MemberClub, { MemberClubAgenda } from "../../entities/memberClub";
import RegisteredPlayer from "../../entities/registeredPlayer";
import MemberClubService from "../interfaces/memberClubService";
import strings from "../localizationService";
import http from "./http";
import ParticipableAgenda from "../../entities/participableAgenda";
import ParticipationConfirmation from "../../entities/participationConfirmation";
const basePath = '/memberClub';
function getMemberClubs():Promise<ParticipableAgenda[]>{
    return http.get(basePath);
}
async function exludeFromGame(clubId:number, game:any):Promise<string>{
    await cacheManager.clearCache("registered", `/${game.clubId}${game.date}${game.start}`);
    await cacheManager.clearCache("registered", `/count/${game.clubId}${game.date}${game.start}`);
    return http.post(`${basePath}/removeFromGame/${clubId}`, game);
}

async function register(registerModel:ParticipableAgenda) : Promise<RegisteredPlayer>{
    var result = (await http.put(`${basePath}/includInDefult/${registerModel.clubId}`, registerModel)) as any;
    await cacheManager.clearCache("registered");
    if(result.isExcludedFromSomeGames)
    toast.info(strings.NotInAllGamesAddedAsDefault, {autoClose:false});
    return result as RegisteredPlayer;
}

async function unregister(clubId: number, registrationId:number):Promise<any>{
    await cacheManager.clearCache("registered");
    return http.post(`${basePath}/removeFromDefault/${clubId}?registrationId=${registrationId}`)
}

function get():Promise<MemberClub[]>{
    return http.get(`${basePath}/memberClubs`);
}
function toggleQuit(clubId:number){
    return http.post(`${basePath}/toggleQuit/${clubId}`)
}

function getParticipationConfirmations():Promise<ParticipationConfirmation[]>{
    return http.get(`${basePath}/participationConfirmation`);
}
function sendParticipationResponse({id, willBe}:{id:number, willBe:boolean}):Promise<any>{
    return http.post(`${basePath}/sendParticipationResponse`, {id, willBe});
}

function setConfirmationTimeout(clubId:number, confirmationTimeout?:number):Promise<any>{
    let path = `${basePath}/setConfirmationTimeout/${clubId}`;
    if(confirmationTimeout)
        path +=`?confirmationTimeout=${confirmationTimeout}`;
    return http.post(path);
}

const service : MemberClubService = {
    getMemberClubs,
    exludeFromGame,
    register,
    unregister,
    get,
    toggleQuit,
    getParticipationConfirmations,
    sendParticipationResponse,
    setConfirmationTimeout
};

export default service;