import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import Joi from 'joi';
import React from 'react'
import NotificationGroup from '../../../entities/notificationGroup';
import strings from '../../../services/localizationService';
import open, { ContentComponentProps, DialogConfig } from '../dialog';
interface Props{
    notificationGrops:NotificationGroup[]
}

export function display(model:Props){
    return open(show(model));
}

function show(model:Props):
 DialogConfig<Props, NotificationGroup[]> {
    return {
        inputModel:model,
        title:strings.ManageNotifications,
        Content:Content,
        SubmitTitle:strings.Ok,
        CancelTitle:strings.Cancel,
        resolvedResult:(model) => Promise.resolve({succeeded:true, result:model.notificationGrops}),
        validationNotNeeded:true
    };
}

function Content({state}:ContentComponentProps<Props>){
    return <div style={{margin:10, paddingLeft:20, display:'flex', flexDirection:"column"}}>
        <Typography>{strings.Confirmations.AllowedNotifications}</Typography>
        {state.notificationGrops.map(x =><FormControlLabel
        key={x.id}
        control={<Checkbox color='primary'
         defaultChecked={x.enabled} />}
        label={x.description}
        onChange={(event) => {
            x.enabled = !x.enabled;
        }}
        /> )}
        
    </div>
}