export default
{
 en:{
    "string.email":"email is not valid",
    "string.empty":"Field is required",
    'any.required': `Field is required`,
    'number.base': `Must be a number`,
    "any.only":"Values do not match",
    "string.pattern.base":"Value does not meet requirements",
    "string.min":"Value should be longer",
    "date.min":"Can't create game in the past"
 },
 ro:{
    "string.email":"e-mailul nu este valid",
    "string.empty":"Câmpul este obligatoriu",
    'any.required': `Câmpul este obligatoriu`,
    "any.only":"Valorile nu coincid",
    'number.base': `Trebuie sa fie un numar`,
    "string.pattern.base":"Valoarea nu corespunde cerințelor",
    "string.min":"Valoarea trebuie să fie mai lungă",
    "date.min":"Nu se poate crea un joc în trecut"
 },
 ru:{
    "string.email":"Email не является допустимым",
    "string.empty":"Поле обязательно",
    'any.required': `Поле обязательно`,
    "any.only":"Значения не совпадают",
    'number.base': `Должно быть число`,
    "string.pattern.base":"Значение не соответствует требованиям",
    "string.min":"Значение должно быть длиннее",
    "date.min":"Не можете создать игру в прошлом"
 }
}