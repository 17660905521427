import React, { useEffect, useState } from "react";
import Chat from "../../entities/chat";
import apiRequest from "../../shared/apiRequest";
import chatService from "../../services/chatService";
import './index.css'
import { Button, Fab, Input, Typography } from "@mui/material";
import strings from "../../services/localizationService";
import { ChatMessages } from "../../features/chat-messages";
import { Add, ArrowBack, Close, Edit, Send, SendRounded } from "@mui/icons-material";
import viewModels from "../../viewModels";
import { ChatQuestionnair } from "../../features/chat-questionnaires";
import dialogs from "../../components/dialogs";
import playerService from "../../services/serverServices/playerServices";
import events from "../../shared/events";
import { eventNames } from "../../constants";
import ChatListChangeListener from "../../entities/chatListChangeListener";
import { ChatLevel, ChatModificationType } from "../../entities/enums";
import Message from "../../entities/message";
import UnreadedMessages from "../../entities/unreadedMessages";
export function ChatPage(){
    const [chats, setChats] = useState<Chat[]>([]);
    const [currentChatId, setCurrentChatId] = useState<number | undefined>(undefined);
    useEffect(() =>{
        apiRequest.execute(() => chatService.getChats(), setChats);
        events.on(eventNames.chatListChange, chatListChanged);
        events.on(eventNames.messageReceived, onMessageReceived);
        return () => {
        events.off(eventNames.chatListChange, chatListChanged);
        events.off(eventNames.messageReceived, onMessageReceived);
        }
    }, []);

    const onMessageReceived = (e:any) => {
        const message = e.detail as Message;
        if(message.senderId == playerService.getPlayerId()) return;
        let changed = false;
        let unreaded:UnreadedMessages = {importantMessages:0, otherMessages: 0};
        setChats(x => {
            if(changed) return x;
            const chat = x.find(y => y.id === message.chatId);
            if(!chat) return x;
            chat.unreadedMessages += 1;
            if(chat.important)
            unreaded.importantMessages += 1;
            else unreaded.otherMessages += 1;
            changed = true;
            return [...x];
        })
        if(changed)
        events.trigger(eventNames.chatUnreadeCountChanged, unreaded);
    }

    const chatListChanged = (e:any) =>{
        const listener = e.detail as ChatListChangeListener;
        if(!listener.usersToNotify.includes(playerService.getPlayerId())) return;
        if(listener.modificationType === ChatModificationType.Remove)
        {
            const isCurrent = currentChatId === listener.chat.id;
            if(isCurrent)
            setCurrentChatId(undefined);
            return setChats(x => {
                return x.filter(y => y.id !== listener.chat.id);
            })
        }
            
        setChats(x => {
            const exists = x.find(y => y.id === listener.chat.id) !== undefined;
            if(exists) return x;
            return [listener.chat, ...x];
        })
    }

    const itemClicked = async (item:Chat, actionType:ChatItemAction) => {
        if(actionType === ChatItemAction.Click)
        {
            const chat = chats.find(x => x.id == item.id)!;
            updateChatIconIndicator(chat, -chat.unreadedMessages);
            chat.unreadedMessages = 0;
            setChats([...chats]);
            setCurrentChatId(item.id);
            return;
        }
        if(actionType === ChatItemAction.Delete){
            const confirmation = await dialogs.Confirmation("Delete Chat?");
            if(!confirmation) return;
            const isCurrent = currentChatId === item.id;
            const newList = chats.filter(x => x.id !== item.id);
            if(isCurrent)
            setCurrentChatId(undefined);
            return viewModels.chat.deleteChat(item.id, () =>{ 
                setChats(newList)
            }, () => {
                if(isCurrent)
                setCurrentChatId(item.id);
            });
        }
        const chat = await dialogs.ChatDialog.EditChatDialig(item.id).show();
        if(chat){
            const chatIndex = chats.indexOf(item);
            setChats([...chats.slice(0, chatIndex), chat, ...chats.slice(chatIndex+1)])
        }
    }

    const updateChatIconIndicator = (chat:Chat, count:number) =>{
        const unreaded:UnreadedMessages = {importantMessages:chat.important ? count : 0, otherMessages:chat.important ? 0 : count};
        events.trigger(eventNames.chatUnreadeCountChanged, unreaded);
    }

    return <div className="chat-page">
        <div className="chat-page-content">

        <div className="chats">
                {chats.map(x => <ChatItem key={x.id} isSelected={x.id === currentChatId} chat={x} 
                onClick={itemClicked} />)}
                <Fab className="add-chat" size="small"
                onClick={async () => {
                    const newChat = await dialogs.ChatDialog.AddChatDialig.show();
                    if(newChat)
                    {
                        setChats([newChat, ...chats]);
                        setCurrentChatId(newChat.id);
                    }
                }}><Add /></Fab>
            </div>
            {currentChatId && <div className="chat-body">
                <div className="chat-name">
                    <div onClick={() => setCurrentChatId(undefined)}><ArrowBack /></div>
                    <Typography>{chats.find(x => x.id === currentChatId)!.name}</Typography>
                </div>
                   {<Discussions chatId={currentChatId} />}
                   {/*chatTabId === 1 && <ChatQuestionnair />*/}
                </div>}
        </div>
    </div>
}

enum ChatItemAction{
    Click,
    Delete,
    Edit
}

function ChatItem({chat, isSelected, onClick}:{chat:Chat,isSelected:boolean, onClick:(item:Chat, action:ChatItemAction) => void}){
    const ownerAction = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>, actionType:ChatItemAction) =>{
        e.stopPropagation();
        onClick(chat, actionType);
    }

    const chatName = chat.level === ChatLevel.App ? strings.Community : (chat.level === ChatLevel.Sport ? strings.Volleyball.Types[chat.sportType!] : chat.name);

    return <div className={isSelected ? "chat-item selected" : "chat-item"} onClick={() => onClick(chat, ChatItemAction.Click)}>
        {chat.picture && <img className="chat-picture" src={chat.picture}/>}
        {!chat.picture && <div className="chat-picture chat-fake-picture">
            <p>{chatName.toUpperCase().split(' ').map(x => x.at(0)).join('')}</p></div>}
        <p className="chat-name">{chatName}</p>
        <div className="chat-owner">
            
        {chat.unreadedMessages > 0 && <p className={chat.important ? "chat-unreaded-message important" : "chat-unreaded-message"}>{chat.unreadedMessages}</p>}
            {chat.chatOwnerId === playerService.getPlayerId() && <>
            <Button onClick={(e) =>ownerAction(e, ChatItemAction.Edit)}><Edit /></Button>
            <Button onClick={(e) =>ownerAction(e, ChatItemAction.Delete)}><Close /></Button>
            </>}
            </div>
    </div>
}

function ChatBodyHeader({onClick}:{onClick:(id:number) => void}){
    const handleClick = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
        const buttons = Array.from(document.getElementsByClassName("chat-header-button"));
        buttons.forEach(element => element.classList.toggle("active"));
        onClick(+e.currentTarget.id);
    }
return  <div className="chat-header">
    <button id="0" onClick={handleClick} className="chat-header-button active">{strings.Discussions}</button>
    <button id="1" onClick={handleClick} className="chat-header-button">{strings.Surveys}</button>
</div>
}

function Discussions({chatId}:{chatId:number}){
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const sendMessage = async () =>{
        setSending(true);
        await viewModels.chat.sendMessage(chatId, message);
        setSending(false);
        setMessage('');
    }
    return <div className="chat-discussions">
        <div className="chat-discussions-messages">
        <ChatMessages chatId={chatId} />
        </div>
        <div className="chat-discussions-input">
        <Input className="chat-discussions-input-text" multiline 
        value={message} onChange={(e) => setMessage(e.currentTarget.value)}
        inputProps={{style:{maxHeight:50}}}
        />
        <Fab size="small" disabled={!message || sending} onClick={sendMessage}>
        <SendRounded />
        </Fab>
        </div>
        
    </div>
}