import React, { useContext, useState } from "react";
import { eventNames, languages } from '../../constants';
import LanguageContext from "../../context/languageContext";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AppSelect } from "../../widgets/app-select";
import './index.css'
import events from "../../shared/events";
export const Localization = () => {
    const languageContext = useContext(LanguageContext);
    const handleTabChange = (newLanguage:string) => {
        languageContext.updateLanguage(newLanguage);
        events.trigger(eventNames.menuItemClicked, {});
    };

    return <div className="localization">
        {languages.map(x => 
    <img className={x === languageContext.currentLanguage ? "selected" : ""} key={x} onClick={() => handleTabChange(x)} src={require(`../../assets/${x}.png`)} /> )}
    </div> ;
}