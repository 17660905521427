import React from "react";
import GoogleMapReact from 'google-map-react';
import { LocationOnRounded } from "@mui/icons-material";
import './index.css'
function AppMap({lat, lng}){
    
    return <div className="parent">
        <GoogleMapReact
        bootstrapURLKeys={{key:process.env.REACT_APP_MAPS_KEY}}
        defaultCenter={{lat, lng}}
        defaultZoom={15}
        >
            <div lat={lat}
          lng={lng}>
                <LocationOnRounded color="primary" className="map-indicator" />
            </div>
        </GoogleMapReact>
    </div>
}

export default AppMap;