import { eventNames } from "../../constants";
import Game from "../../entities/game";
import clubService from "../../services/clubService";
import gameService from "../../services/gameService";
import apiRequest from "../../shared/apiRequest";
import events from "../../shared/events";

async function execute(state:boolean, game:Game, onSuccess: () => void){
    return apiRequest.execute(() => state ? gameService.cancel(game) : gameService.reactivate(game),
                     () =>{
                        events.trigger(eventNames.gameSateChanged, !state);
                        onSuccess();
                     });
}
function isAdmin() { return clubService.isAdmin()}
export default { execute, isAdmin};