import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

export interface Item{
        label:string,
        selected:boolean,
        id:number
    }

export default function MultiSelectItems({items}:{items:Item[]}){
return <>{items.map(x =><FormControlLabel
    key={x.id}
    control={<Checkbox color='primary'
     defaultChecked={x.selected} />}
    label={x.label}
    onChange={(event) => {
        x.selected = !x.selected;
    }}
    /> )}</>
}