import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Route, redirect, RouteProps, Routes, useNavigate, Outlet } from "react-router-dom";
import { routers } from "../constants";
import LoginPage from "./login";
import RegisterPage from"./register";
import ClubsPage from"./clubs";
import GamesPage from"./games";
import MembersPage from"./members";
import NotFoundPage from"./not-found";
import ResetPage from "./resetPassword";
import EmailConfirmationPage from "./email-confirmation";
import TermsAndConditions from "./terms-and-conditions";
import ProfilePage from "./profile";
import AccessDeniedPage from "./access-denied";
import PrivacyPolicy from "./privacy-policy";
import DeleteProfile from "./delete-profile";
import FeedBackPage from "./feedback";
import playerService from "../services/playerServices";
import RegisterMembersPage from "./register-members";
import { OnBoarding } from "./on-boarding";
import { ChatPage } from "./chat";
import SuggestedTeamsPage from "./suggested-teams";
export const Routing = () => {
    return (
    <Routes>
        <Route element={<AnonymousProtectedRoute />}>
        <Route path={routers.login} Component={LoginPage}/>
        <Route path={routers.register} Component={RegisterPage}/>
        <Route path={routers.reset} Component={ResetPage}/>
        <Route path={routers.confirmEmail} Component={EmailConfirmationPage} />
        <Route path={routers.home} Component={OnBoarding} />
        </Route>
        <Route element={<ProtectedRoute />}>
        <Route path={routers.home} element={<GamesPage />} />
        <Route path={routers.games} element={<GamesPage />} />
        <Route path={routers.clubs} Component={ClubsPage} />
        <Route path={routers.members} Component={MembersPage} />
        <Route path={routers.addMembers} Component={RegisterMembersPage} />
        <Route path={routers.deleteProfile} Component={DeleteProfile} />
        <Route path={routers.profile} Component={ProfilePage} />
        <Route path={routers.feedback} Component={FeedBackPage} />
        <Route path={routers.chat} Component={ChatPage} />
        <Route path={routers.suggestedTeams} Component={SuggestedTeamsPage} />
        </Route>
        <Route path={routers.termsAndConditions} Component={TermsAndConditions} />
        <Route path={routers.privacyPolicy} Component={PrivacyPolicy} />
        <Route path={routers.accessDenied} Component={AccessDeniedPage} />
        <Route path={routers.notFound} Component={NotFoundPage} />
      </Routes>);
}

 function ProtectedRoute(){
    const navigate = useNavigate();
    useEffect(() =>{
        if(!playerService.isLoggedIn())
            navigate(routers.login, {replace:true});
    }, []);

    return <Outlet />;
}
function AnonymousProtectedRoute(){
    const navigate = useNavigate();
    useEffect(() =>{
        if(playerService.isLoggedIn())
            navigate(routers.games, {replace:true});
    }, []);
    return <Outlet />
}