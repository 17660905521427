import React from 'react';
import AppTab from './app-tab';
import { getTabs } from './app-tab/model';

export const AppTabs = () =>{
    const tabs = getTabs();
    return <>
    {tabs.map(x => <AppTab key={x.index} value={x.index} label={x.label} path={x.parent ?? x.paths[0]}
    />)}
    </>
}