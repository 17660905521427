import React from "react";
import './index.css'
import playerService from "../../services/serverServices/playerServices";
import { SportsVolleyball } from "@mui/icons-material";
import { routers } from "../../constants";
function Logo(){
    return <a className={playerService.isLoggedIn() ? "logo" : "logo unauthorized"}
    href={routers.games} onClick={(e) => {
        if(window.location.pathname === routers.games)
        e.preventDefault();
    }}>
        <SportsVolleyball />
    <p> Play All Sports</p>
        </a>
}

export default Logo;