import { toast } from "react-toastify";
import { localStorageKeys } from "../../constants";
import RegistrationModel from "../../models/registrationModel";
import authService from "../../services/authService";
import localStorageService from "../../services/localStorageService";
import apiRequest from "../../shared/apiRequest";
import dialogs from "../../components/dialogs";

async function Register(model:RegistrationModel, onSucces:() => void){
    return apiRequest.execute(() => authService.emailRegistration(model), async (authResult) => {
        if(!authResult.succeeded){
            if(!authResult.succeeded){
                await dialogs.Info(authResult.error);
                return;
            }
        }
        onSucces();
    });
}
export default {Register}