import cacheManager from "../cacheManager";
import Member from "../entities/member";
import MemberClub from "../entities/memberClub";
import ParticipationConfirmation from "../entities/participationConfirmation";
import Player from "../entities/player";
import UpdateStatusModel from "../models/updateStatusModel";
import WalletModel, { GameWalletModel } from "../models/walletModel";
import clubService from "../services/clubService";
import memberClubService from "../services/serverServices/memberClubService";
import playerService from "../services/serverServices/playerServices";
import walletService from "../services/walletService";
import apiRequest from "../shared/apiRequest";

async function getMembers(id:number, onSucces:(members:Member[]) => void, onFailure?:() => void){
    return apiRequest.execute(() => clubService.getMembers(id), onSucces, onFailure);
}

async function getMember(clubId:number,playerId:number, onSucces:(members:Member) => void, onFailure?:() => void){
    return apiRequest.execute(() => clubService.getMembers(clubId), (result) =>{
        onSucces(result.find(x => x.clubMemberId === playerId)!);
    }, onFailure);
}

async function updateStatus(id:number, model:UpdateStatusModel, onSucces:() => void){
    return apiRequest.execute(() => clubService.updateStatus(id, model), onSucces);
}
async function updateWallet(walletModel:WalletModel, onSucces:() => void){
    return apiRequest.execute(() => walletService.executeOperation(walletModel), onSucces);
}
async function payments(clubId:number, payments:WalletModel[], onSucces:() => void){
    return apiRequest.execute(() => walletService.payments(clubId, payments), onSucces);
}
function get(onSucces:(result:MemberClub[]) => void){
    return apiRequest.execute(() => memberClubService.get(), onSucces);
}
function toggleQuit(clubId:number, onSucces:() => void){
    return apiRequest.execute(() => memberClubService.toggleQuit(clubId), onSucces);
}

function getParticipationConfirmations(onSucces:(result:ParticipationConfirmation[]) => void){
    if(!playerService.isLoggedIn()) return;
    return apiRequest.execute(() => memberClubService.getParticipationConfirmations(), onSucces);
}

function sendParticipationResponse({id, willBe}:{id:number, willBe:boolean}, onFailure:() => void){
    return apiRequest.execute(() => memberClubService.sendParticipationResponse({id, willBe}), () => {}, onFailure);
}

function setConfirmationTimeout(clubId:number,onSucces:() => void, confirmationTimeout?:number){
    console.log(confirmationTimeout);
    return apiRequest.execute(() => memberClubService.setConfirmationTimeout(clubId,confirmationTimeout), onSucces);
}

export default {getMembers, updateStatus, updateWallet, getMember, get, toggleQuit, payments, getParticipationConfirmations, sendParticipationResponse, setConfirmationTimeout};