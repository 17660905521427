import React, { useEffect, useState } from 'react';
import { eventNames } from '../../../constants';
import events from '../../events';
import './index.css';
interface LoadableProps{
    id:string,
    startOnRendering?:boolean,
    View:React.ComponentType<any>,
    screenCentered?:boolean
}
function Loadable({id, View, screenCentered, startOnRendering}:LoadableProps){
    const [isLoading, setIsLoading] = useState(startOnRendering);
    const imageClass = screenCentered ? "screenCentered" : "";
    useEffect(() =>{
        events.on(eventNames.loading, loadingStateChanged);
        return () =>{
        events.off(eventNames.loading, loadingStateChanged);
        }
    }, []);
    const loadingStateChanged = (e:any) =>{
        const {viewId, loading} = e.detail;
        if(id == viewId)
        setIsLoading(loading);
    }
    return isLoading ? <div className='loadingContainer'>
                            <img src={require('../../../assets/ball.png')} className={imageClass} />
                        </div> : 
                        <View />
}
export default Loadable;