import React from 'react';
import Team from '../../../../entities/team';
import { TeamSkillsView } from '../../../../features/skills';
import strings from '../../../../services/localizationService';
import open, { ContentComponentProps, DialogConfig } from '../../dialog';
import './index.css'
import SkillContext from '../../../../context/skillContext';
export function show(teams:Team[]){
    return open(Config(teams));
}

function Config(teams:Team[]):DialogConfig<Team[], boolean>{
    console.log(teams);
    return {
        Content:Content,
        resolvedResult:(model) => Promise.resolve({succeeded:true}),
        cancelOnOutside:true,
        CancelTitle:strings.Ok,
        inputModel:teams,
        title:strings.Volleyball.SkillsLabelReadOnly
    }
}

function Content({state}:ContentComponentProps<Team[]>){
    return <div><div className='team-level-dialog'>{state.map(x => <SkillContext.Provider  key={x.id} value={{playerId:0, readonly:true, skills:x.skills, sportType:x.sportType}}>
<TeamSkillsView team={x} />
    </SkillContext.Provider>)}</div></div>
}