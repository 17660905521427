import React, { useState } from "react";
import { ClubAccesability, ClubPayment, ClubScope, SportType, GuestsRegistrationStrategy, WalletOperation, ClubOption, PaymentType, RegularsRegistartionType } from "../../entities/enums";
import './index.css'
import { Button, Typography } from "@mui/material";
import strings from "../../services/localizationService";
export type APP_OPTIONS = "Type" | "Scope" | "Payment" | "RegistrationStrategy" |
 "SportType" | "Interested" | "Wallet" | "ClubOption" | "TimeoutConfirmation" | "PlayerPayment"
 | "PaymentType" | "RegularsOpenRegistrationHours"
 | "TelegramFilter" | "GamesFilter";
interface AppOptionModelProps{
    items:number[],
    optionText:(index:number) => string,
    title:string
}
interface Generic{
    optionType:APP_OPTIONS
}
interface AppOptionsProps extends Generic{
    defaultValue?:number,
    onStateChanged?:(newState:number) => void
}

interface AppOptionsWithStateProps{
    state:AppOptionModelProps,
    defaultValue?:number,
    onStateChanged?:(newState:number) => void
}
interface AppMultiOptionsProps extends Generic{
    defaultValues?:number[],
    onStateChanged?:(optionIndex:number, added:boolean) => void
}

export default function AppOptions({ optionType, onStateChanged, defaultValue}:AppOptionsProps){
    return <AppOptionsWithSupprtedItems
    state={AppOptionModel(optionType)}
    defaultValue={defaultValue}
    onStateChanged={onStateChanged}
     />
}

export function AppOptionsWithSupprtedItems({onStateChanged, defaultValue, state}:AppOptionsWithStateProps){
    const {items, optionText, title} = state;
    const [selectedOption, setSelectedOption] = useState( defaultValue ?? 0);
    const onStateChangedHandler = (index:number) =>{
        setSelectedOption(index);
        onStateChanged?.(index);
    }
    return <div className="options-container">
        <Typography>{title}</Typography>
        <div className="option-items">
            {items.map(x => <Button key={x} onClick={() => onStateChangedHandler(x)} className={x === selectedOption ? "app-option-button active" : "app-option-button"}>{optionText(x)}</Button>)}
        </div>
    </div>
}

export function AppMultyOptions({ optionType, onStateChanged, defaultValues}:AppMultiOptionsProps){
    const {items, optionText, title} = AppOptionModel(optionType);
    const [selectedOptions, setSelectedOptions] = useState(items.filter(x => defaultValues?.find(y => y == x)));
    const onStateChangedHandler = (index:number) =>{
        const added = selectedOptions.find(x => x === index) === undefined;
        if(added)
        setSelectedOptions([...selectedOptions, index]);
        else setSelectedOptions(selectedOptions.filter(x => x !== index));

        onStateChanged?.(index, added);
    }
    return <div className="options-container">
        <Typography>{title}</Typography>
        <div className="option-items">
            {items.map(x => <Button key={x} onClick={() => onStateChangedHandler(x)} color="secondary"
             className={selectedOptions.includes(x) ? "app-option-button active" : "app-option-button"}>{optionText(x)}</Button>)}
        </div>
    </div>
}

export function AppOptionModel(option:APP_OPTIONS){
    return{
        items:getOptionsArray(option),
        title:getTitle(option),
        optionText:getOptionsTitle(option)
    }
}

function getOptionsTitle(option:APP_OPTIONS){
    if(option === "Scope")
    return (index:number) => strings.ClubScopes[index];
    if(option === "Type" || option === "Interested")
    return (index:number) => strings.Volleyball.Types[index];
    if(option === "Payment")
    return (index:number) => strings.PaymentTypes[index];
    if(option === "RegistrationStrategy")
    return (index:number) => strings.GuestRegistrationStrategies[index];
    if(option === "SportType")
    return (index:number) => strings.Volleyball.SportTypes[index];
    if(option === "Wallet")
    return (index:number) => strings.WalletOperations[index];
    if(option === "ClubOption")
    return (index:number) => strings.ClubOptions[index];
    if(option === "TimeoutConfirmation")
        return (index:number) => strings.ConfirmationTimeoutOptions[index];
    
    if(option === "PlayerPayment")
        return (index:number) => strings.PlayerPlaymentOptions[index];
    if(option === "PaymentType")
        return (index:number) => strings.FeeTypes[index];
    if(option === "RegularsOpenRegistrationHours")
        return (index:number) => strings.RegularsRegistrationTypes[index];
    if(option === "TelegramFilter")
        return (index:number) => strings.TelegramFilterOptions[index];
    if(option === "GamesFilter")
        return (index:number) => strings.GamesFilterOptions[index];
    return (index:number) => '';
}

function getTitle(option:APP_OPTIONS){
    if(option === "Scope")
    return strings.ClubScope;
    if(option === "Type")
    return strings.ClubType;
    if(option === "Payment")
    return strings.Fee;
    if(option === "RegistrationStrategy")
    return strings.GuestRegistrationStrategy;
    if(option === "SportType")
    return strings.Volleyball.Sport;
    if(option === "Interested")
    return strings.InterestedIn;
    if(option === "Wallet")
    return strings.Wallet;
    if(option === "PlayerPayment")
        return strings.PlayerPaymentOffer;
    if(option === "PaymentType")
        return strings.FeeType;
    if(option === "RegularsOpenRegistrationHours")
        return strings.WhenRegularsCanRegister;
    return '';
}

function getOptionsArray(option:APP_OPTIONS){
    if(option === "Scope")
    return Object.values(ClubScope).filter(x => typeof(x) == "number").map(x => x as ClubScope);
    if(option === "Type" || option === "Interested")
    return Object.values(SportType).filter(x => typeof(x) == "number").map(x => x as SportType);
    if(option === "Payment")
    return Object.values(ClubPayment).filter(x => typeof(x) == "number").map(x => x as ClubPayment);
    if(option === "RegistrationStrategy")
    return Object.values(GuestsRegistrationStrategy).filter(x => typeof(x) == "number").map(x => x as GuestsRegistrationStrategy);
    if(option === "SportType")
    return strings.Volleyball.SportTypes.map((x, i) => i);

    if(option === "Wallet")
    return Object.values(WalletOperation).filter(x => typeof(x) == "number").map(x => x as WalletOperation);
    
    if(option === "ClubOption")
    return Object.values(ClubOption).filter(x => typeof(x) == "number").map(x => x as ClubOption);
    if(option === "TimeoutConfirmation")
        return strings.ConfirmationTimeoutOptions.map((x, i) => i);
    if(option === "PlayerPayment")
        return strings.PlayerPlaymentOptions.map((x, i) => i);
    if(option === "PaymentType")
    return Object.values(PaymentType).filter(x => typeof(x) == "number").map(x => x as PaymentType);
    if(option === "RegularsOpenRegistrationHours")
        return Object.values(RegularsRegistartionType).filter(x => typeof(x) == "number").map(x => x as RegularsRegistartionType);
    
    if(option === "TelegramFilter")
        return strings.TelegramFilterOptions.map((x, i) => i);
    if(option === "GamesFilter")
        return strings.GamesFilterOptions.map((x, i) => i);
    return [];
}