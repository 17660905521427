import React, { useEffect, useState } from 'react';
import strings from '../../services/localizationService';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Model from '../../features/club-list/Model';
import ClubList from '../../features/club-list';
import './index.css'
import dialogs from '../../components/dialogs';
import Club from '../../entities/club';
import viewModels from '../../viewModels';
import { ClubsContext } from '../../context/clubContext';
import AppOptions from '../../widgets/app-options';
import MemberClub from '../../entities/memberClub';
import { ClubOption } from '../../entities/enums';
import MemberClubs from '../../features/member-clubs';

const ClubsPage = () => {
    const [clubs, setClubs] = useState<Club[]>([]);
    const [memberClubs, setMemberClubs] = useState<MemberClub[]>([]);
    const [option, setOption] = useState(ClubOption.Admin);
    const history = useNavigate();
    useEffect(() =>{
        viewModels.club.getClubs(setClubs, () => history(-1));
        viewModels.members.get(setMemberClubs);
    }, []);

    const addClubHandle = async () => {
        const result = await dialogs.Club.Create.show();
        if (result) setClubs([...clubs, result]);
    }

    return <div className='clubs-page'>
        <div className='club-page-options'>
        <div> <AppOptions optionType='ClubOption' onStateChanged={(state) => setOption(state)} /> </div>
        </div>
        {option === ClubOption.Admin && <><div className='club-page-content'>
            <Button endIcon={<img src={require('../../assets/edit.svg').default}/>}
            className='add-club'
             onClick={(enent: any) => addClubHandle()}>{strings.AddClub}</Button></div>
             <ClubsContext.Provider value={{clubs, deleteClub:(clubId) => setClubs(clubs.filter(x => x.id !== clubId))}}>
             <ClubList />
             </ClubsContext.Provider></>}
             {option === ClubOption.Member && <MemberClubs items={memberClubs} />}
    </div>;
}

export default ClubsPage;