export default{
    en:{
        VolleyballSkills:["Serve", "Defence", "Set", "Hit", "Block"],
        SoccerSkills:[ "Goalkeeping","Dribbling", "Defence", "Passing", "Shooting"],
        BasketballSkills:["Dribbling", "Defence", "Rebounding", "Shooting", "Passing"],
        Positions:["Setter", "Outside Hitter", "Middle Blocker", "Opposite Hitter", "Libero"],
        SkillsLabelReadOnly:"Skills",
        SkillsLabel:"For Skills",
        Evaluate:"Evaluate",
        Evaluated:"Evaluated",
        EvaluatePlayers:"Evaluate Players",
        BasedEvaluation:"Based on evaluation of players",
        YourEvaluation:"Your evaluation",
        NotEvaluated:"Not Evaluated",
        SelectForEvaluation:"Select a player",
        PlayablePositions:"Playable positions",
        NoPositions:"Positions not specified",
        PositionsLabel:"Positions",
        IndicatePositions:"Indicate positions you can play",
        RotationLabel:"Rotation",
        Rotations:["No Specific Positions","Rotation 4-2", "Rotation 5-1"],
        WithLibero:"With Libero",
        SuggestedTeams:"Suggested teams",
        ViewTeams:"View",
        Team:"Team",
        TeamLevel:"Team Level",
        Sport:"Sport",
        ViewTeamsLevel:"View Teams Levels",
        SportTypes:["Volleyball", "Soccer", "Basketball"],
        Types:["Classic Volleyball", "Beach Volleyball", "Soccer", "Basketball"],
        OnBoarding:"Do you play any Sport?",
        OnBoardingContent:"Play All Sports provides opportunities for all sport lovers to share their loved sport. Join available events or create your own and find people to share your favorite sport with.",
        Notifications:["Game in {0} at {1} was Cancelled", "Game in {0} at {1} is available again",
    "Registration for Game in {0} at {1} is opened","You are moved in list of active players for game in {0} at {1}","You were moved to reserve for game in {0} at {1}","You were removed from the game list in {0} at {1}"]
    },
    ro:{
        VolleyballSkills:["Servire", "Apărare", "Pasarea", "Atac", "Bloc"],
        SoccerSkills:["Portar","Dribling", "Apărare", "Pasarea", "Țintire"],
        BasketballSkills:["Dribling", "Apărare", "Redonbândire", "Țintire", "Pasarea"],
        Positions:["Pasator", "Atacant din afară", "Centru", "Atacant Opus", "Libero"],
        SkillsLabelReadOnly:"Aptitudini",
        SkillsLabel:"Aptitudinilor",
        Evaluate:"Evaluează",
        Evaluated:"Evaluat",
        EvaluatePlayers:"Evaluează jucătorii",
        BasedEvaluation:"Pe baza evaluării jucătorilor",
        YourEvaluation:"Evaluarea ta",
        NotEvaluated:"Neevaluat",
        SelectForEvaluation:"Selectați un jucător",
        PlayablePositions:"Pozițiile de joc",
        NoPositions:"Pozițiile nu sunt specificate",
        PositionsLabel:"Pozițiile",
        IndicatePositions:"Indicați pozițiile pe care le puteți juca",
        RotationLabel:"Rotația",
        Rotations:["Fără poziții specifice", "Rotația 4-2", "Rotația 5-1"],
        WithLibero:"Cu Libero",
        SuggestedTeams:"Echipele sugerate",
        ViewTeams:"Vezi",
        Team:"Echipa",
        TeamLevel:"Nivelul Echipei",
        Sport:"Sportul",
        ViewTeamsLevel:"Vedeți nivelurile echipelor",
        SportTypes:["Volei", "Fotbal", "Baschet"],
        Types:["Volei Clasic", "Volei de Plajă", "Fotbal", "Baschet"],
        OnBoarding:"Joci careva tip de sport?",
        OnBoardingContent:"Play All Sports oferă tuturor iubitorilor de sport oportunități de a împărtăși sportul iubit. Alăturați-vă evenimentelor disponibile sau creați-vă propriile și găsiți persoane cu care să împărtășiți sportul preferat.",
        Notifications:["Jocul la {0} pe data de {1} a fost anulat", "Jocul la {0} pe data de {1} este disponibil din nou",
    "Înregistrarea pentru Joc în {0} la {1} este deschisă","Sunteți mutat în lista jucătorilor activi pentru jocul din {0} la {1}","Ai fost mutat în rezervă pentru jocul din {0} la {1}","Ai fost eliminat din lista la jocul în {0} la {1}"],
    },
    ru:{
        VolleyballSkills:["Подача", "Защита", "Пас", "Атака", "Блок"],
        SoccerSkills:["Вратарь","Дриблинг", "Защита", "Пас", "Удар"],
        BasketballSkills:["Дриблинг", "Защита", "Подбор", "Бросок", "Пас"],
        Positions:["Связующий", "Доигровщик", "Центральный", "Диагональный", "Либеро"],
        SkillsLabelReadOnly:"Навыки",
        SkillsLabel:"Навыкам",
        Evaluate:"Оценить",
        Evaluated:"Оценено",
        EvaluatePlayers:"Оценить игроков",
        BasedEvaluation:"На основе оценки игроков",
        YourEvaluation:"Твоя оценка",
        NotEvaluated:"Не оценено",
        SelectForEvaluation:"Выберите игрока",
        PlayablePositions:"Игровые позиции",
        NoPositions:"Позиции не указаны",
        PositionsLabel:"Позиции",
        IndicatePositions:"Укажите позиции, на которых вы можете играть",
        RotationLabel:"Расстановку",
        Rotations:["Без конкретных позиций","Расстановка 4-2", "Расстановка 5-1"],
        WithLibero:"С Либеро",
        SuggestedTeams:"Рекомендуемые команды",
        ViewTeams:"Посмотреть",
        Team:"Команда",
        Sport:"Спорт",
        TeamLevel:"Уровень команды",
        ViewTeamsLevel:"Посмотреть уровни команд",
        SportTypes:["Волейбол", "Футбол", "Баскетбол"],
        Types:["Классический волейбол", "Пляжный волейбол", "Футбол", "Баскетбол"],
        OnBoarding:"Вы играете в какой-нибудь спорт?",
        OnBoardingContent:"Play All Sports предоставляет возможность всем любителям спорта поделиться своим любимым видом спорта. Присоединяйтесь к доступным событиям или создайте свое собственное и найдите людей, с которыми можно поделиться своим любимым видом спорта.",
        Notifications:["Игра в {0} на {1} была отменена", "Игра в {0} на {1} снова доступна",
    "Регистрация на игру в {0} на {1} открыта","Вы перемещены в список активных игроков для игры {0} на {1}","Вы были перемещены в резерв для игры в {0} на {1}",
    "Вы были удалены из списка на игру в {0} на {1}"]
    }
}