import http from './http';
import { localStorageKeys } from "../../constants";
import Player from "../../entities/player";
import localStorageService from "../localStorageService";
import PlayerService from '../interfaces/playerService';
import MemberShipModel from '../../models/membershipModel';
import { getCookieValue } from '../../shared/cookieToObject';
import { PlayerStatus } from '../../entities/enums';
import { emailSubscriber, webSubscriber } from '../subscriber/subscriber';
import EmailSubscription from '../../entities/emailSubscription';
import NotificationGroup from '../../entities/notificationGroup';

const basePath = 'player/';

function getPlayerId(){
    let id = -1;
    if(localStorageService.getItem(localStorageKeys.playerId))
    id = +(localStorageService.getItem(localStorageKeys.playerId) ?? -1);
    return id;
}

function registerToMembership(model:MemberShipModel): Promise<PlayerStatus> {
    return http.post<PlayerStatus>(`${basePath}memberShip`, model);
}
function isLoggedIn(){
    return !!localStorageService.getItem(localStorageKeys.playerId);
}

function notifyAboutError(error:{message:string, name:string, stack:string | undefined}){
    return http.post(`${basePath}error`, error);
}

function unSubscribe():Promise<unknown>{
    if(!isLoggedIn() || !window.Notification || !navigator.serviceWorker.controller) return Promise.resolve();
    
    return navigator.serviceWorker.ready.then((registration) => {
        
        registration.pushManager.getSubscription().then(async (subscription) => {
            if(subscription)
            {
                await http.post("client/unsubscribe", subscription);
                await subscription.unsubscribe();
            }
          })
      }).finally(() => {
      })
}

function subscribe(emailSubscription?:EmailSubscription):Promise<unknown>{
    if(!isLoggedIn()) return Promise.resolve();
    if(emailSubscription) return emailSubscriber(emailSubscription).subscribe();

    if((!window.Notification || !navigator.serviceWorker)) return Promise.resolve();
    
    return webSubscriber.subscribe();
}

function getNotificationGroups(){
    return http.get<NotificationGroup[]>(`${basePath}notifications`);
}

function updateNotificationGroups(groups:NotificationGroup[]){
    return http.post(`${basePath}notifications`, groups);
}

const playerService:PlayerService = {
    registerToMembership,
    getPlayerId,
    isLoggedIn,
    subscribe,
    unSubscribe,
    notifyAboutError,
    getNotificationGroups,
    updateNotificationGroups
};

export default playerService;