import React from "react";
import AppButton from "../../shared/ui/appButton";
import model from '../../dialogs/ui/email-subscription/model'
import strings from "../../services/localizationService";
function EmailSubscription(){
    return <AppButton
    label={strings.SubscribeOrUnsubscribe}
    onClick={model.changePermission}
    color="primary"
    variant="contained"
    className="user-action-button"
     />
}

export default EmailSubscription;