import { Button, Typography } from '@mui/material'
import { string } from 'joi';
import React, { useContext, useEffect, useState } from 'react'
import { Positions, SportType } from '../../entities/enums';
import strings from '../../services/localizationService';
import playerService from '../../services/playerServices';
import './index.css'
import ProfileSportSectionContext from '../../context/profileSportSectionContext';
import dialogs from '../../components/dialogs';
import viewModels from '../../viewModels';
function PositionsView(){
    const {sport} = useContext(ProfileSportSectionContext);
    const [positions, setPositions] = useState<number[]>(sport.positions);
    const updatePosition = async () =>{
        const allPositions = getPositionsLabel().map((v, i) =>{
            return {
                id:i,
                label:v,
                selected:positions.find(x => x === i) !== undefined
            }
        });
        const newPosition = await dialogs.SelectableItems(allPositions, strings.Volleyball.PlayablePositions).show();

        if(newPosition){
            const positionsToSet = newPosition.filter(x => x.selected).map(x => x.id);
            viewModels.user.setPositions(sport.sportType, positionsToSet, () =>{
                setPositions(positionsToSet);
            })
        }
    }
    
    function getPositionsLabel(){
        if(sport.sportType <= SportType.BeachVolleyball)
        return strings.Volleyball.Positions;
        if(sport.sportType == SportType.Soccer)
        return strings.Soccer.Positions;
        return strings.Basketball.Positions;
    }

    const positionsLabel = ''.concat(...positions?.map(x => `${getPositionsLabel()[x]}, `));
    return <div className='playablePositions-contaier'>
           <p className='playablePositions title'>{strings.Volleyball.PlayablePositions}</p>
           <div className='positions'>
           {positions.length === 0 && <p className='playablePositions content'>{strings.Volleyball.NoPositions}</p>}
           {positions.length !== 0 && <p className='selected-positions playablePositions content'>{positionsLabel.substring(0, positionsLabel.length - 2)}</p>}
           {sport.playerId == playerService.getPlayerId() && <Button variant='contained' color='primary' onClick={updatePosition}>{`${strings.Update} ${strings.Volleyball.PositionsLabel}`}</Button>}
           </div>
       </div>
}

export default PositionsView;