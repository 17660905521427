export default{
    en:{
        email:"E-mail address",
        password:"Password",
    },
    ro:{email:"Adresa de e-mail",
    password:"Parola"
},
    ru:{email:"Адрес электронной почты",
    password:"Пароль"}
}