import React, { useContext, useEffect, useRef, useState } from "react";
import Player from '../../entities/player';
import Model from './model';
import { useNavigate, useParams } from "react-router-dom";
import './index.css';
import { Image, InfoOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import strings from "../../services/localizationService";
import { UpdateProfile } from "../../features/update-profile";
import { UserFeedbackView } from "../../features/skills";
import PositionsView from "../../features/positions/ui";
import playerService from "../../services/serverServices/playerServices";
import EmailSubscription from "../../features/email-subscription";
import NotificationGroups from "../../features/notification-group";
import localStorageService from "../../services/localStorageService";
import { eventNames, localStorageKeys } from "../../constants";
import TelegramCode from "../../features/telegram-code";
import UserContext from "../../context/userContext";
import { UserActions } from "../../features/user-actions";
import SkillContext from "../../context/skillContext";
import Skill from "../../entities/skill";
import events from "../../shared/events";
import Loadable from "../../shared/ui/loadable";
import loading from "../../shared/ui/loadable/loading";
import { SportType } from "../../entities/enums";
import dialogs from "../../components/dialogs";
import SportSection from "../../entities/sportSection";
import { DropDown } from "../../widgets/drop-down";
import viewModels from "../../viewModels";
import ProfileSportSection from "../../features/profile-sport-section";
import ProfileSportSectionContext from "../../context/profileSportSectionContext";
import SkillsBySport from "../../entities/skillsBySport";

const ProfilePage = () => {
    const { id } = useParams<any>();
    var history = useNavigate();
    const [player, setPlayer] = useState({} as Player);
    const [skills, setSkills] = useState<SkillsBySport[]>([]);
    const [evaluatedSkils, setEvaluatedSkils] = useState<SportSection[]>([]);
    if(!id || Number.isNaN(+id)) history(-1);
    useEffect(() => {
        Model.get(+id!, (result) => setPlayer(result), () => history(-1));
        if(+id! !== playerService.getPlayerId())
        Model.getSkills(+id!, setSkills);
        events.on(eventNames.skillsEvaluated, getEvaluatedSkiils);
        return () =>{
        events.off(eventNames.skillsEvaluated, getEvaluatedSkiils);
        }

    }, [id]);

    useEffect(() => {
        if(player)
        Model.getSections(+id!, setEvaluatedSkils);

    }, [player?.sports]);

    const getEvaluatedSkiils = async (e:any) =>{
        const eventData = e.detail;
        if(+eventData.id == +id!){
            loading.start(`skills_${eventData.sportType}`);
            await Model.getSections(+id!, (result) =>{
                setEvaluatedSkils(result);
                loading.stop(`skills_${eventData.sportType}`);
            });
        }
    }
    const sportLabel = (sportType:SportType) =>{
        if(sportType <= SportType.BeachVolleyball)
        return strings.Volleyball.SportTypes[0];
        return strings.Volleyball.SportTypes[sportType - 1];
    }
    return <UserContext.Provider value={{player, updatePlayer:setPlayer}}>

<div className="profile-page">
        <div className="info-conatiner">
            <img src={player.picture ?? require('../../assets/avatar.jpg')} />
        <div className="info">
            <p className="name">{player.name}</p>
            <p className="contact">{player.contactInfo}</p>
           {+id! == playerService.getPlayerId() && <UserActions />}
           
           {+id! != playerService.getPlayerId() && skills.map(x => <DropDown 
           defaultState={false}
           title={`${strings.Volleyball.YourEvaluation}: ${sportLabel(x.sportType)}`}
           ><SkillContext.Provider value={{readonly:false, playerId:+id!, sportType:x.sportType, skills:x.skills, updateSkills:(result) =>{
            x.skills.forEach(item =>{
                const exists = result.find(x => x.skill === item.skill);
                if(exists)
                item.level = exists.level;
            })
            setSkills([...skills]);
        }}} >
        <UserFeedbackView />
    </SkillContext.Provider></DropDown>)}
        </div>
        </div>
        <div className="profile-page skills position">
            <div className="intereset-in">
                <p><em>{strings.InterestedIn}</em> {player.sports?.map(x => strings.Volleyball.Types[x]).join(",")}
                <em style={{cursor:"pointer"}} onClick={() =>{
            dialogs.Info(strings.Placeholders.OnlyThisSports);
        }}><InfoOutlined /></em></p>
                {playerService.getPlayerId() === +id! && <Button variant="contained"
                onClick={async () =>{
                    await viewModels.user.setSports(player.sports, (result) => setPlayer({...player, sports:result}));
                }}
                 color="primary"
                 >{strings.Update}</Button>}
            </div>

        {evaluatedSkils.map(x => <ProfileSportSectionContext.Provider key={x.playerId + x.sportType} value={{sport:x}}><ProfileSportSection /></ProfileSportSectionContext.Provider>)}
        </div>
        
    </div>
    </UserContext.Provider>;
}

export default ProfilePage;