import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import clubService from '../../services/clubService';
import { useNavigate, useParams } from 'react-router-dom';
import Player from '../../entities/player';
import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import localizationService from '../../services/localizationService';
import AppButton from '../../shared/ui/appButton';
import { PlayerStatus } from '../../entities/enums';
import UpdateStatusModel from '../../models/updateStatusModel';
import { routers } from '../../constants';
import Loadable from '../../shared/ui/loadable';
import loading from '../../shared/ui/loadable/loading';
import './index.css'
import { AppSelect } from '../../widgets/app-select';
import { UsersGroupingView } from '../../widgets/users-grouping-view';
import Member from '../../entities/member';
import { AccountBalanceWallet, Casino, Check, Close, Money } from '@mui/icons-material';
import dialogs from '../../components/dialogs';
import Wallet from '../../entities/wallet';
import viewModels from '../../viewModels';
import strings from '../../services/localizationService';
import AppOptions from '../../widgets/app-options';
import EditInviteLimit from '../../features/edit-invite-limit';
const modifiableStatuses = Object.values(PlayerStatus).filter(x => x !== PlayerStatus.AdminGuest && typeof(x) === 'number').map(x => x as PlayerStatus);

const MembersPage = () => {
    const [members, setMembers] = useState([] as Member[]);
    const[onlyTelegramProfiles, setOnlyTelegramProfiles] = useState(false);

    const history = useNavigate();
    const { id } = useParams() as any;
    useEffect(() => {
        viewModels.members.getMembers(id, (members) => {setMembers(members);
        loading.stop("members")}, () => history(routers.clubs, {replace:true}));
    }, []);

    const updateStatus = (model:UpdateStatusModel) =>{
        viewModels.members.updateStatus(id, model, () =>{
            const member = members.find(x => x.id == model.memberId) as Member;
            const index = members.indexOf(member);
            member.status = model.memberStatus;
            setMembers([...members.slice(0, index), member, ...members.slice(index +1, members.length)]);
        })
    }
    const filteredMemebers = onlyTelegramProfiles ? members.filter(x => x.isTelegramAccount) : members;
    const membersExists = filteredMemebers?.length > 0;
    const conditions = modifiableStatuses.map(x => { return {
        title:localizationService.StatusesForSection[x],
        condition:(y:Player) => y.status === x
    }})
    return <Loadable 
    id='members'
    startOnRendering={true}
    screenCentered={true}
    View={() => <div className='membersPage-content'>

    {!membersExists && <Typography variant='h4' style={{marginTop:30}}>{localizationService.NoMember}</Typography>}
    {UsersGroupingView({items:filteredMemebers, groups:conditions, View:(x, isSeachModel) =>
         <MemberView key={x.id} item={x} updateStatus={updateStatus} displayStatus={isSeachModel} />,
         AdditionHeader:() => <div className='telegram-club-option'><AppOptions optionType='TelegramFilter'
         defaultValue={onlyTelegramProfiles ? 1 : 0}
          onStateChanged={(index) => setOnlyTelegramProfiles(index === 1)} /></div>
        }
         )}
    
</div>}
    />
     ;
}

interface MemberProps{
    item:Member,
    displayStatus?:boolean
    updateStatus:(model:UpdateStatusModel) => void
}

function MemberView({item, displayStatus, updateStatus} : MemberProps){
    const history = useNavigate();
    const [state, setItem] = useState(item);
    const requestAction = async (accept:boolean) =>{
        await viewModels.club.completeTransfer(item.clubId, item.clubMemberId, accept, (result) =>{
            if(result > 0)
            state.points = result;
            setItem({...state, requestPointsTransfer:false});
        });
    }
    return <>
        {displayStatus && <Typography>{localizationService.Statuses[item.status!]}</Typography>}
        <WalletView item={state} setItem={setItem} />
        <Button className='view-profile' variant='outlined' color='primary' onClick={() =>{
            history(routers.profile.replace(":id", item.clubMemberId.toString()));
        }}>{strings.Home}</Button>
        {state.requestPointsTransfer && <div className='points-transfer'>
        <Button color='primary' variant='outlined' onClick={async () =>requestAction(true)}>{strings.Placeholders.PointsTransfer} <Check /></Button>
        <Button color='secondary' variant='outlined' onClick={async () =>requestAction(false)}
        >{strings.Placeholders.PointsTransfer} <Close /></Button>
        </div>}
        <EditInviteLimit clubId={item.clubId} playerId={item.clubMemberId} invitationLimit={item.inviteLimit} isDefault={false} />
        <MemberStatusView item={item} updateStatus={updateStatus} />
    </>
}

function WalletView({item, setItem}:{item:Member, setItem:(item:Member) => void}){
    const updateWallet = async () =>{
        const result = await dialogs.ClubWallet(item).show();
        if(result)
        viewModels.members.updateWallet(result, () => setItem({...item, points:result.updateValue}))
    }
return <Button color='primary' variant='outlined' className='wallet'
onClick={updateWallet}
><Typography>{item.points}</Typography><AccountBalanceWallet /></Button>
}

function MemberStatusView({item, updateStatus} : MemberProps){
    return <AppSelect
    View={() => <div className='member-status-view'>
    <p>{localizationService.UpdateStatus}</p>
    <img src={require('../../assets/edit.svg').default}/></div>}
    OptionsView={() => <>{modifiableStatuses.filter(x => x !== item.status && x !== PlayerStatus.AdminGuest)
        .map(x => <Button variant='contained' key={x}
        onClick={() => updateStatus({memberId:item.id, memberStatus:x})}>{localizationService.Statuses[x]}</Button>)}</>}
     />
}

export default MembersPage;