import Club from "../../entities/club";
import Chat from "../../entities/chat";
import Message from "../../entities/message";
import ChatService from "../interfaces/chatService";
import http from "./http";
import Survey from "../../entities/survey";
import ChatSettings from "../../entities/chatSettings";
import UnreadedMessages from "../../entities/unreadedMessages";
import apiRequest from "../../shared/apiRequest";
const baseUrl = "chat";

function add(connectionID:string, clubId?:number){
    return http.post(`${baseUrl}/add?connectionId=${connectionID}&clubId=${clubId}`);
}
function remove(connectionID:string, clubId?:number){
    return http.post(`${baseUrl}/remove?connectionId=${connectionID}&clubId=${clubId}`);
}

function getChats():Promise<Chat[]>{
    return http.get(`${baseUrl}`);
}

function say(clubId:number, text:string):Promise<Message>{
    return http.put(`${baseUrl}/say/${clubId}?text=${text}`);
}

function getMessage(clubId:number, pageNumber:number):Promise<Message[]>{
    return http.get(`${baseUrl}/${clubId}?page=${pageNumber}`);
}
function markAsUnread(cludId:number){
    return http.post(`${baseUrl}/read/${cludId}`)
}
function getSurveys(chatId:number):Promise<Survey[]>{
    return http.get(`${baseUrl}/surveys`);
}
function ask(clubId:number, adminId:number, message:string):Promise<any>{
    return http.post(`${baseUrl}/ask/${clubId}?adminId=${adminId}&message=${message}`);
}
function edit(messageId:number, text:string):Promise<Message>{
    return http.post(`${baseUrl}/edit/${messageId}?text=${text}`);
}
function deleteMessage(messageId:number):Promise<any>{
    return http._delete(`${baseUrl}/delete/${messageId}`);
}
function create(model:any):Promise<Chat>{
    return http.post(`${baseUrl}/create`, model);
}
function deleteChat(chatId:number):Promise<any>{
    return http._delete(`${baseUrl}/${chatId}`);
}
function editChat(chatId:number, model:any):Promise<Chat>{
    return http.post(`${baseUrl}/${chatId}`, model);
}
function chatSettings(chatId:number):Promise<ChatSettings>{
    return http.get(`${baseUrl}/settings/${chatId}`);
}
function didReadMessage(message:Message){
    return apiRequest.execute(() => http.post(`${baseUrl}/didReadMessage/${message.chatId}`, message));
}
function getUnreaded():Promise<UnreadedMessages>{
    return http.get(`${baseUrl}/unreaded`);
}
const service:ChatService = {
    add,
    remove,
    getChats: getChats,
    say,
    getMessage,
    getSurveys,
    markAsRead: markAsUnread,
    ask,
    edit,
    deleteMessage,
    create,
    deleteChat,
    editChat,
    chatSettings,
    didReadMessage,
    getUnreaded
};

export default service;