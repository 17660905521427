import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import './index.css';
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Fab } from "@mui/material";
interface VerticalScrollProps{
    style?: CSSProperties,
    noItemsLabel?:string,
    items:ReactElement<any, any>[],
    onCanScroll?:(isScrollabled:boolean) => void
}
enum ScrollDirection{
    LEFT,
    RIGHT
}
function HorizontalScrollView({style, items, noItemsLabel, onCanScroll}:VerticalScrollProps){
    const content = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", verifyCanScroll);
        return () => window.removeEventListener("resize", verifyCanScroll);
    }, []);

    useEffect(() =>{
        if(content){
            verifyCanScroll();
        }
    }, [content, items]);

    const scrollContent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
        const scrollLeft = e.currentTarget.parentElement!.classList.contains("left");
        const scrollBy = !scrollLeft ?  content.current?.offsetWidth! : -content.current?.offsetWidth!;
        content.current?.scrollBy({behavior:"smooth", left:scrollBy});
        verifyCanScroll();
    }

    const verifyCanScroll = () => {
        verifyCanScrollOnDirection(ScrollDirection.LEFT);
        verifyCanScrollOnDirection(ScrollDirection.RIGHT);
    }

    const verifyCanScrollOnDirection = (direction:ScrollDirection) =>{
        const contentRect = content.current?.getBoundingClientRect();
        const left = direction === ScrollDirection.LEFT;
        var child = left ? content.current?.firstElementChild : content.current?.lastElementChild;
        const childBound =  child?.getBoundingClientRect();
        const canScroll = left ? contentRect?.left! > childBound?.left! : Math.round(contentRect?.right!) < Math.round(childBound?.right!);
        if(left)
        setCanScrollLeft(canScroll);
        else
        setCanScrollRight(canScroll);
        onCanScroll?.(canScrollLeft || canScrollRight || canScroll);
    }

    return <div className="horizontal-scroll-view card" style={style}>
        {items.length === 0 && <p className="noContent">{noItemsLabel}</p>}
        {items.length > 0 && <>
    <div ref={content} className="content" onScroll={verifyCanScroll}>
        {items.map((x, i) => <div key={i} className="item">{x}</div>)}
    </div>
    {canScrollLeft && <div className="scroll left"> <Fab size="small" onClick={scrollContent} > <ArrowBack /> </Fab></div>}
    {canScrollRight && <div className="scroll right"><Fab size="small"  onClick={scrollContent}><ArrowForward /></Fab></div>}
        </>}
    </div>
}

export default HorizontalScrollView;