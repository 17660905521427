import { TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import './index.css'
interface Time{
    start:string,
    end:string,
    startTimeSpan:TimeSpan,
    endTimeSpan:TimeSpan
}
interface TimeSpan{
    hours:number,
    minutes:number,
    seconds:number
}

let times:{id:string, time:Time}[] = [];
export default function TimeInput({id}:{id:string}){
    useEffect(() =>{
        if(!times.find(x => x.id === id))

        times.push({id, time:{start:"18:00:00", end:"20:00:00", startTimeSpan:GetTimeSpan("18:00:00"), endTimeSpan:GetTimeSpan("20:00:00")}})
        return () => {
            times = times.filter(x => x.id === id);
        }
    }, []);
    const updateEnd = (input:string) =>{
        getTimeById(id).time.end = input;
        getTimeById(id).time.endTimeSpan = GetTimeSpan(input);
    }
    const updateStart = (input:string) =>{
        getTimeById(id).time.start = input;
        getTimeById(id).time.startTimeSpan = GetTimeSpan(input);
    }
    return <div className="time-input">
        <TextField type="time" variant="outlined" defaultValue={"18:00:00"} onChange={(e) => updateStart(e.currentTarget.value) } />
        -
        <TextField type="time" variant="outlined" defaultValue={"20:00:00"} onChange={(e) => updateEnd(e.currentTarget.value)} />
    </div>
}

function GetTimeSpan(input:string):TimeSpan{
    var values = input.split(':');
    return {
        hours:+values[0],
        minutes:+values[1],
        seconds:+values[2]
    }
}
export function getTimeById(id:string){
    return times.find(x => x.id === id)!;
}