import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routers } from '../../constants';
import Reset from '../../forms/resetPasswordForm';

function ResetPage(){
    var requestParams = {email:'', passwordToken:''}
    var history = useNavigate();
    try{
    var query = window.location.search.substring(window.location.search.indexOf('=') + 1);
    requestParams.email = query.substring(0, query.indexOf('&'));
    query = query.substring(query[0].indexOf('&') + 1)
    requestParams.passwordToken = query.substring(query.indexOf('=') + 1);}
    catch(error){
        history(routers.login, {replace:true});
    }
    return<div style={{display:'flex', justifyContent:'center', alignItems:"center", height: '100vh'}}>
    <Reset {...requestParams}/>
</div>
}

export default ResetPage;