
function convert<TResult>(cookie:string){
    var value_or_null = getCookieValue(cookie);
    if(!value_or_null) return null;
    value_or_null = value_or_null.replaceAll("%7B", "{").replaceAll("%7D", "}")
                                   .replaceAll("%22", '"').replaceAll("%3A", ":")
                                   .replaceAll("%2C", ",")
                                   .replaceAll("%40", '@')
                                   .replaceAll("%20", " ");
    return JSON.parse(value_or_null) as TResult;
}

function getCookieValue(cookie:string){
    var regex = RegExp(`^(?:.*;)?\\s*${cookie}\\s*=\\s*([^;]+)(?:.*)?$`);
    var value_or_null = (document.cookie.match(regex)||[,null])[1];
    if(!value_or_null) return null;
    return value_or_null;
}

export { convert, getCookieValue };