import React, { useEffect, useState } from "react";
import Player from "../../entities/player";
import apiRequest from "../../shared/apiRequest";
import clubService from "../../services/serverServices/clubService";
import { useParams } from "react-router-dom";
import './index.css'
import { Button, TextField, Typography } from "@mui/material";
import strings from "../../services/localizationService";
import { PlayerStatus } from "../../entities/enums";

const RegisterMembersPage = () => {
    const { id } = useParams() as any;
    const [memberName, setMemeberName] = useState('');
    const [members, setMembers] = useState<Player[]>([]);
    const filteredMembers = members.filter(x => !memberName || x.name.toLowerCase().includes(memberName?.toLowerCase()));

    useEffect(() =>{
        apiRequest.execute(() => clubService.getMembersToRegister(id), setMembers);
    }, []);
    const registerMember = (playerId:number, status:PlayerStatus) =>{
        apiRequest.execute(() => clubService.registerMember(id, playerId, status), () => {
            setMembers(members.filter(x => x.id !== playerId));
        })
    }
    return <div className="register-members-page">
        <TextField value={memberName} onChange={(e) => setMemeberName(e.currentTarget.value)}
className='register-members-page-search' variant='outlined' placeholder={strings.SearchMembers}></TextField>
<div className="register-members-content">
    {filteredMembers.map(x => <MemberView item={x} add={registerMember} />)}
</div>
    </div>
}

function MemberView({item, add}:{item:Player, add:(playerId:number, status:PlayerStatus) => void}){
    return <div className="register-member-item">
        <img  src={item.picture ?? require("../../assets/avatar.png")}/>
        <Typography>{item.name}</Typography>
        <Button color="primary" variant="outlined" onClick={() => add(item.id, PlayerStatus.Regular)}>{`${strings.Add} ${strings.As} ${strings.Statuses[PlayerStatus.Regular]}`}</Button>
        <Button color="primary" variant="outlined" onClick={() => add(item.id, PlayerStatus.Guest)}>{`${strings.Add} ${strings.As} ${strings.Statuses[PlayerStatus.Guest]}`}</Button>
    </div>
}

export default RegisterMembersPage;