import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import localizationService from '../../services/localizationService';
import RestrictionView from '../../widgets/restriction-view';

const NotFoundPage = () => {
    return <RestrictionView image={require('../../assets/notFound.jpg')}
                            message={localizationService.NotFound}
     />;
}

export default NotFoundPage;