import { Button } from "@mui/material";
import React, { useState } from "react";
import Game from "../../entities/game";
import strings from "../../services/localizationService";
import dialogs from "../../components/dialogs";
import clubService from "../../services/serverServices/clubService";
import viewModels from "../../viewModels";

export default function EditInviteLimit({playerId,
    clubId,
    game,
    invitationLimit,
    isDefault, registrationId}:{playerId:number,clubId:number, game?:Game, invitationLimit?:number, isDefault:boolean, registrationId?:number}){
    const [invitationLimitCount, setInvitationLimitCount] = useState(invitationLimit);
    const label = strings.InviteLimit + (invitationLimitCount ? `(${invitationLimitCount})` : '');
    const onLimitationChange = async () =>{
        var result = await dialogs.InvitationLimitationDialog(invitationLimitCount).show();
        if(!result) return;
        await viewModels.club.setInviteLimitation({playerId, clubId, limitation:result, game, isDefault, registrationId}, setInvitationLimitCount);
    }
    return invitationLimitCount ? <Button style={{marginBottom:10}} color="secondary" variant="outlined" onClick={onLimitationChange}>{label}</Button> : <></>
}