import { Tab } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { eventNames } from '../../../constants';
import events from '../../../shared/events';
import { getSelectedTab, tabSelected } from './model';
import './index.css';
interface AppTabProps{
    value:number,
    label:string,
    path:string
}
const ACTIVE = 'active';
function AppTab({value, label, path}:AppTabProps){
    const ref = useRef<HTMLAnchorElement>(null);
    const classes = getSelectedTab().index === value ? 'app-tab active' : 'app-tab';
    const onTabClick = () => {
        tabSelected(path);
        events.trigger(eventNames.menuItemClicked, {});
    };

    useEffect(() => {
        events.on(eventNames.tabSelected, onSelectedTabChanged);
        return () =>{
            events.off(eventNames.tabSelected, onSelectedTabChanged);
        }
    }, []);

    const onSelectedTabChanged = () =>{
        var shouldNotBeActive = isActive() && getSelectedTab().index !== value;
        const shouldBeActie = !isActive() && getSelectedTab().index === value;
        if(shouldBeActie || shouldNotBeActive)
        ref.current!.classList.toggle(ACTIVE);
    }

    const isActive = () =>{
        return ref.current!.classList.contains(ACTIVE);
    }

    return <div className='app-tab-container'><Link ref={ref} to={path} className={classes} onClick={onTabClick}>{label}</Link></div>
}
export default AppTab;