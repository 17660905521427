import dialogs from "../../components/dialogs"
import { localStorageKeys } from "../../constants"
import authService from "../../services/authService"
import strings from "../../services/localizationService"
import localStorageService from "../../services/localStorageService"
import apiRequest from "../../shared/apiRequest"

function deleteProfile(){
    return apiRequest.execute(() => authService.deleteAccount(),async () => {
        localStorageService.removeItem(localStorageKeys.playerId)
        await dialogs.Info(strings.Confirmations.ProfileDeleted);
        window.location.reload();
    })
}
export default {deleteProfile}