import React from "react";
import IDialog from "../dialogs";
import { DialogProps, open } from "../dialogHelpers";
import strings from "../../../services/localizationService";
import { Button } from "@mui/material";
import { Check } from "@mui/icons-material";
import viewModels from "../../../viewModels";
import dialogs from "..";
import Game from "../../../entities/game";

function RezervInfo(confirmationTimeout:number): IDialog<boolean> {
    return {
        show:() => open((resolve) => <RezervInfoView confirmationTimeOut={confirmationTimeout} resolve={resolve.resolve} />)
    }
}

interface Props extends DialogProps<boolean>{
    confirmationTimeOut?:number
}

function RezervInfoView({confirmationTimeOut, resolve}:Props){

    return <div className="dialog-view">
    <div className="centered-dialog">
<p className="dialog-title">{strings.Info}</p>
<p>{strings.RezervNotification.replace("{0}", `${confirmationTimeOut} ${strings.Placeholders.Minutes}`)}</p>
<Button color="primary" variant="outlined"
onClick={async () => {
    if(await dialogs.Confirmation(strings.Confirmations.ConfirmationTimeout))
resolve(true);
}}
>{strings.ConfirmationTimeoutOptions[1]}</Button>
</div>
<div className="close-info">
<Button color="primary" variant="outlined" onClick={() => resolve(false)}><Check /></Button></div>
</div>
}


export default RezervInfo;