import { Button, Dialog, ThemeProvider } from "@mui/material";
import { createRoot } from "react-dom/client";
import { theme } from "../..";
export interface DialogProps<TResult>{
    resolve:(value: TResult | PromiseLike<TResult | undefined> | undefined) => void
}
export async function open<TResult>(Content:({resolve}:DialogProps<TResult>) => JSX.Element): Promise<TResult | undefined> {
    
    const _p = document.createElement('div');
    const root = createRoot(_p);
    let dialog = new Promise<TResult | undefined>((resolve) => {
         document.body.appendChild(_p);
         root.render(
            <ThemeProvider theme={theme}>
                <DialogView Content={() => Content({resolve})} />
            </ThemeProvider>);
    });
    var dialogResult = await dialog;
    root.unmount();
    document.body.removeChild(_p);
    return dialogResult;
}

interface DialogViewProps{
    Content:() => JSX.Element
}
function DialogView({Content}:DialogViewProps){
    return <Dialog open color="primary">
        {<Content />}
    </Dialog>
}