import React, { ChangeEvent, useRef } from 'react';
import { Button, TextField } from '@mui/material/';
import { ContentComponentProps, DialogConfig } from '../dialog';
import localizationService from '../../../services/localizationService';
import MemberShipModel from '../../../models/membershipModel';
import Joi from 'joi';
import * as Helper from '../../../shared/ui/AppFormHelper';
import Model from './model';
import UpdateProfile from '../../../models/updateProfile';
import Player from '../../../entities/player';

export const UpdateProfileDialog = (model:UpdateProfile):DialogConfig<UpdateProfile,Player> => {
    return {
    Content: Content,
    inputModel: model,
    resolvedResult : Model.onOk,
    title: `${localizationService.Update} ${localizationService.Home}`,
    SubmitTitle: localizationService.Update,
    CancelTitle: localizationService.Cancel,
    schema:Joi.object({
        name:Joi.string().required(),
        contactInfo:Joi.string().required()
    })
}
}

function Content(props: ContentComponentProps<UpdateProfile>) {
    const fileInput = useRef<HTMLInputElement>(null);
    const selectFile = () => {
        fileInput.current?.click();
    };
    const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = e.target.files?.item(0);
            if (file) {
                props.setState({file} as UpdateProfile);
                
            }
        } catch (error) {

        }
    }
    const { name, contactInfo } = props.state;

    return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", minWidth:400 }}>
        <Helper.InputWithValidation
        errorMessage={props.errors?.name}
        textFieldProps={{label:localizationService.Placeholders.CallBy, name:'name', defaultValue:name, InputLabelProps:{shrink:true}, 
        onChange:props.onInputChange
        }}
         />
         <Helper.InputWithValidation
        errorMessage={props.errors?.contactInfo}
        textFieldProps={{label:localizationService.Placeholders.ContactInfo, name:'contactInfo', defaultValue:contactInfo, InputLabelProps:{shrink:true}, 
        onChange:props.onInputChange
        }}
         />
         <Button variant="contained" color="primary"
         style={{ marginBottom: 15, marginTop: 15 }}
         onClick={selectFile}>{localizationService.Select} {localizationService.Pic}</Button>
     <input type="file" accept="image, .jpg, .png" style={{ display: "none" }} ref={fileInput} onChange={onFileSelected} />
    </div>;
}