import { Button, Slider, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SkillContext from '../../context/skillContext';
import { Skills, SportType } from '../../entities/enums';
import Skill from '../../entities/skill'
import strings from '../../services/localizationService'
import model from './model';
import './index.css';
import events from '../../shared/events';
import { eventNames } from '../../constants';
import Team from '../../entities/team';

interface SkillItemProps{
    skill:Skill,
    update:(item:Skills, level:number) => void
}

export function TeamSkillsView({team}:{team:Team}){
    const {skills, id} = team;
    return <SkillsView
    Header={() => <><Typography style={{textAlign:"center"}}>{strings.Volleyball.Team} {id}</Typography></>}
    items={skills}
    Footer={() => <Typography>{strings.Volleyball.TeamLevel} {team.teamLevel}</Typography>}
     />
}

export function UserFeedbackView(){
    const {readonly, skills, playerId,sportType, updateSkills:updateContext} = useContext(SkillContext);
    const container = useRef<HTMLDivElement>(null);
    const[canEvaluate, setCanEvaluate] = useState(false);
    const updateSkills = (skill:Skills, level:number) =>{
        const newValues = getInputSkills();
        const hasEmptyValues = newValues.length === 0 || newValues.find(x => x.level === 0) !== undefined;
        setCanEvaluate(!hasEmptyValues);
    }

    const getInputSkills = () =>{
        const skillInputs = Array.from(container.current!.getElementsByTagName("input"));
        let newValues = skillInputs.map((x, i) => {return {
            skill:i as Skills,
            id:skills.find(y => y.skill === i)?.id ?? 0,
            level:+x.value
        }});
        return newValues.filter(x => skills.find(y => y.skill === x.skill && y.level !== x.level));
    }

    const update = () =>{
        model.giveFeedback({playerId, skills:getInputSkills(), sportType}, updateContext);
        setCanEvaluate(false);
    }

    return <div ref={container} className='skills-container'>
    {<>
    {readonly && <Typography>{strings.Volleyball.BasedEvaluation}</Typography>}</>}
{skills.map(x => <SkillItem key={x.skill} skill={x} update={updateSkills}
/>)}
{!readonly && <Button disabled={!canEvaluate}
    onClick={update} >{strings.Volleyball.Evaluate}</Button>}
</div>
}

interface SkillsViewProps{
    items:Skill[],
    Header:() => JSX.Element,
    Footer:() => JSX.Element
}

function SkillsView({items, Header, Footer}:SkillsViewProps){

    return <div className='skills-container'>
        {<Header />}
        {items.map(x => <SkillItem key={x.skill} skill={x} update={() => {}}
/>)}
        {<Footer />}
    </div>
}

export function SkillItem({skill, update}:SkillItemProps){
    const {readonly, sportType} = useContext(SkillContext);
    const [level,setLevel] = useState(skill.level);
    const updateHandler = (event: Event, value: number | number[], active:number) =>{
        setLevel(+value);
        update(skill.skill, level);
    } 
    const labels = getSportLabels(sportType);
    return <div className='skill-container'>
<div className='level-container'>
<Slider value={level} min={0} max={10}
id={skill.skill.toString()}
step={1}
disabled={readonly}
onChange={updateHandler}
 color='primary' />
<Typography>{labels[skill.skill]} {level > 0 ? `(${level}/10)` : `(${strings.Volleyball.NotEvaluated})`}</Typography>
</div>
 </div>
}

function getSportLabels(sport:SportType){
    if(sport < SportType.Soccer)
    return strings.Volleyball.VolleyballSkills;
    if(sport === SportType.Basketball)
    return strings.Volleyball.BasketballSkills;
    if(sport === SportType.Soccer)
    return strings.Volleyball.SoccerSkills;
    return [];
}
