import LocalizedStrings from 'react-localization';
import localStorageService from './localStorageService';
import { localStorageKeys } from '../constants';
import http from './serverServices/http';
import validationErrors from './localizations/validationErrors';
import validationErrorsByField from './localizations/validationErrorsByField';
import placeholders from './localizations/placeholders';
import clubsPage from './localizations/clubsPage';
import gamesPage from './localizations/gamesPage';
import confirmations from './localizations/confirmations';
import termsAndConditions from './localizations/termsAndConditions';
import privacyPolicy from './localizations/privacy-policy';
import volleyball from './localizations/volleyball';
import inputHeader from './localizations/inputHeader';
import soccer from './localizations/soccer';
import basketball from './localizations/basketball';

const strings = new LocalizedStrings({
    en: {
        CanRemoveTillHours:"The limit in hours before the game to remove from the list",
        Volleyball:volleyball.en,
        ...clubsPage.en,
        ...gamesPage.en,
        InputHeader:inputHeader.en,
        Day: "Date",
        StartAt: "Start at",
        EndAt: "End at",
        Games: "Games",
        Home: "Profile",
        MyClus: "My Clubs",
        Status:"Status",
        Statuses:["Administrator",
            "Special Guest",
            "Regular",
            "Guest",
            "Blocked"],
        StatusesForSection:["Other Administrators",
            "",
            "Regulars",
            "Guests",
            "Blocked"],
            TotalMembers:"Total number of participants",
        validationErrorsByField:validationErrorsByField.en,
        validationErrors:validationErrors.en,
        Placeholders:placeholders.en,
        Confirmations:confirmations.en,
        NotFound:"Page not found",
        ConfirmEmail:"To complete process - please, access your email and confirm registration",
        UpdateStatus:"Update Status",
        Address:"Address",
        Update:"Update",
        Modify:"Modify",
        Or:"Or",
        Login:"Login",
        Reset:"Reset password",
        Register:"Register",
        Add:"Add",
        As:"as",
        Cancel:"Cancel",
        Enter:"Enter",
        Select:"Select",
        Code:"Code",
        Request:"Request",
        MemberShip:"MemberShip",
        Confirmation:"Confirmation",
        Info:"Info",
        Yes:"Yes",
        No:"No",
        Ok:"Ok",
        And:"and",
        UseFacebook:"Continue with Facebook",
        Logout:"Logout",
        ResetLinkSend:"Link for reset password send to your email - please check",
        EmailConfirmed:"Email Confirmed - you can now login using email and password",
        ForgotPassowrd:"Forgot Password?",
        OpenRegistration:"Open Registrations for Guests",
        RegistrationOpened:"Registration Opened",
        AgreeWith:'I agree with',
        TermsAndConditions:"Terms and Conditions",
        TermsAndConditionsText:termsAndConditions.en,
        PrivacyPolicy:"Privacy Policy",
        PrivacyPolicyText:privacyPolicy.en,
        Pic:"photo",
        AccessDenied:"Access Denied",
        OpenGame:"Open Game",
        Cancelled:"Game is cancelled",
        NoEmailConfirmation:"Didn't recieve any email?",
        Resend:"Resend",
        NoMember:"No Members here",
        AdminWillOpen:"Admin will Open",
        GameOpenTime:"Registration for Guests Will Open on ",
        TimeForGameCome:"Make sure to refresh, when time passed",
        GenericError:"Something went wrong",
        ErrorSend:"Error was send",
        Notify:"Send Notifications",
        EmailNotification:"Email Notifications",
        SubscribeOrUnsubscribe:"(Un)subscribe (from)to Notifications",
        ErrorNotSend:"Error could not be sended - please send manual to info@play-all-sports.com. Error Details:",
        ManageNotifications:"Manage Notifications",
        NotInAllGamesAddedAsDefault:"You have not been added to all games - because they overlap with other games in which you are already on the list",
        TelegramCode:"View Telegram Code",
        LoginWithTelegram: "Continue with Telegram",
        Welcome: "Welcome",
        UseGoogle: "Continue with Google",
        LoginWith:"Or sign in using:",
        NoAccountYet:"Don’t have an account yet?",
        RegisterHere:"Register Here",
        AlreadyAnAccount:"Already have an account?",
        Discussions:"Discussions",
        Surveys:"Surveys",
        Edited:"edited",
        InterestedIn:"Interested in",
        Community:"Community",
        Soccer:soccer.en,
        Basketball:basketball.en,
        Wallet:"Operation Type",
        WalletOperations:["Deposit", "Withdraw"],
        ClubOptions:["Administered Clubs", "Membership Clubs"],
        WillCome:"Will come",
        OtherPlans:"Have already other plans",
        ConfirmationTimeoutOptions:["Minutes after moved in participation list", "I do not need a timeout"],
        PlayerPaymentOffer:"Player offer",
        PlayerPlaymentOptions:["Amount", "Exact"],
        Adjust:"Ajust"
    },
    ro: {
        CanRemoveTillHours:"Limita in ore inainte de joc de a se sterge din lista",
        Volleyball:volleyball.ro,
        ...clubsPage.ro,
        ...gamesPage.ro,
        InputHeader:inputHeader.ro,
        Day: "Data",
        StartAt: "Începe",
        EndAt: "Finisează",
        Games: "Jocuri",
        Home: "Profil",
        MyClus: "Cluburile mele",
        Status:"Statut",
        Statuses:["Administrator",
        "Invitat Special",
        "Membru",
        "Invitat",
        "Blocat"],
        StatusesForSection:["Alți Administratori",
        "",
        "Membri",
        "Invitați",
        "Blocați"],
        TotalMembers:"Numărul total de participanți",
        validationErrorsByField:validationErrorsByField.ro,
        validationErrors:validationErrors.ro,
        Placeholders:placeholders.ro,
        Confirmations:confirmations.ro,
        NotFound:"Pagina nu a fost găsită",
        ConfirmEmail:"Pentru a finaliza procesul - vă rugăm să vă accesați e-mailul și să confirmați înregistrarea",
        UpdateStatus:"Actaualizează statutul",
        Address:"Adresa",
        Update:"Actualizare",
        Modify:"Modifică",
        Or:"Ori",
        Login:"Loghinare",
        Reset:"Resetează parola",
        Register:"Înregistrare",
        Add:"Adaugă",
        As:"ca",
        Cancel:"Anulare",
        Enter:"Introduceți",
        Select:"Selectați",
        Code:"Cod",
        Request:"Solicită",
        MemberShip:"Calitatea de membru",
        Confirmation:"Confirmare",
        Info:"Informare",
        Yes:"Da",
        No:"Nu",
        Ok:"Ok",
        And:"și",
        UseFacebook:"Continuați cu Facebook",
        Logout:"Deconectare",
        ResetLinkSend:"Link-ul pentru resetarea parolei e transmis pe e-mailul indicat. Verificați",
        EmailConfirmed:"E-mail confirmat - acum vă puteți loghina folosind e-mailul și parola",
        ForgotPassowrd:"Ați uitat parola?",
        OpenRegistration:"Deschide înregistrarea pentru invitați",
        RegistrationOpened:"Înregistrarea e deschisă",
        AgreeWith:'Sunt de acord cu',
        TermsAndConditions:"Termenii de utilizare",
        TermsAndConditionsText:termsAndConditions.ro,
        PrivacyPolicy:"Politica de Confidențialitate",
        PrivacyPolicyText:privacyPolicy.ro,
        Pic:"fotografia",
        AccessDenied:"Acces interzis",
        OpenGame:"Deschide Jocul",
        Cancelled:"Jocul este anulat",
        NoEmailConfirmation:"Nu ați primit niciun e-mail?",
        Resend:"Retrimiteți",
        NoMember:"Niciun membru aici",
        AdminWillOpen:"Administratorul va deschide",
        GameOpenTime:"Înregistrarea pentru Invitați se va deschide ",
        TimeForGameCome:"Asigurați-vă că faceți reload la pagină, când timpul a trecut",
        GenericError:"Ceva n-a mers bine",
        ErrorSend:"Eroare a fost trimisă",
        Notify:"Transmite notificări",
        EmailNotification:"Notificări pe email",
        SubscribeOrUnsubscribe:"(Dez)abonează-te (de) la Notificări",
        ErrorNotSend:"Eroarea nu a putut fi trimisă - vă rugăm să trimiteți manual la info@play-all-sports.com. Detalii despre eroare:",
        ManageNotifications:"Gestionează notificările",
        NotInAllGamesAddedAsDefault:"Nu ați fost adăugat la toate jocurile - deoarece acestea se suprapun cu alte jocuri în care sunteți deja pe listă",
        TelegramCode:"Vezi Telegram Cod",
        LoginWithTelegram: "Continua cu Telegram",
        Welcome: "Bine Ați venit",
        UseGoogle: "Continuați cu Google",
        LoginWith:"Sau conectați-vă folosind:",
        NoAccountYet:"Nu aveți încă un cont?",
        RegisterHere:"Înregistrează-te aici",
        AlreadyAnAccount:"Ai deja un cont?",
        Discussions:"Discuții",
        Surveys:"Sondaje",
        Edited:"editat",
        InterestedIn:"Interesat de",
        Community:"Comunitate",
        Soccer:soccer.ro,
        Basketball:basketball.ro,
        Wallet:"Tipul Operațiunii",
        WalletOperations:["Depozitare", "Extragere"],
        ClubOptions:["Cluburi administrate", "Cluburi de membru"],
        WillCome:"Voi fi",
        OtherPlans:"Am deja alte planuri",
        ConfirmationTimeoutOptions:["Minute de la mutarea în lista de participare", "Nu am nevoie de un timeout"],
        PlayerPaymentOffer:"Jucătorul a oferit",
        PlayerPlaymentOptions:["Suma", "Exact"],
        Adjust:"Ajustează"
    },
    ru: {
        CanRemoveTillHours:"Лимит в часах до игры для удаления из списка",
        Volleyball:volleyball.ru,
        ...clubsPage.ru,
        ...gamesPage.ru,
        InputHeader:inputHeader.ru,
        Day: "Дата",
        StartAt: "Начало",
        EndAt: "Конец",
        Games: "Игры",
        Home: "Профиль",
        MyClus: "Мой клубы",
        Status:"Статус",
        Statuses:["Администратор",
        "Особый гость",
        "Член",
        "Гость",
        "Заблокирован"],
        StatusesForSection:["Другие Администраторы",
        "Особый гость",
        "Члены",
        "Гости",
        "Заблокированые"],
        TotalMembers:"Общее количество участников",
        validationErrorsByField:validationErrorsByField.ru,
        validationErrors:validationErrors.ru,
        Placeholders:placeholders.ru,
        Confirmations:confirmations.ru,
        NotFound:"Страница не найдена",
        ConfirmEmail:"Для завершения процесса - пожалуйста, войдите в свою электронную почту и подтвердите регистрацию",
        UpdateStatus:"Обновить статус",
        Address:"Адрес",
        Update:"Обновить",
        Modify:"Изменить",
        Or:"Или",
        Login:"Вход",
        Reset:"Сброс пароля",
        Register:"Регистрация",
        Add:"Добавить",
        As:"как",
        Cancel:"отмена",
        Enter:"ВВедите",
        Select:"Выберите",
        Code:"Код",
        Request:"Запросить",
        MemberShip:"Членство",
        Confirmation:"Подтверждение",
        Info:"Информация",
        Yes:"Да",
        No:"нет",
        Ok:"Ок",
        And:"и",
        UseFacebook:"Продолжить с Facebook",
        Logout:"выйти",
        ResetLinkSend:"Ссылка для сброса пароля отправлена на вашу электронную почту - пожалуйста, проверьте",
        EmailConfirmed:"Электронная почта подтверждена - теперь вы можете войти, используя адрес электронной почты и пароль",
        ForgotPassowrd:"Забыли пароль?",
        OpenRegistration:"Открыть гостевую регистрацию",
        RegistrationOpened:"Регистрация открыта",
        AgreeWith:'Я согласен с',
        TermsAndConditions:"Условиями использования",
        TermsAndConditionsText:termsAndConditions.ru,
        PrivacyPolicy:"Политика конфиденциальности",
        PrivacyPolicyText:privacyPolicy.ru,
        Pic:"Фото",
        AccessDenied:"Доступ запрещен",
        OpenGame:"Oткрыть игру",
        Cancelled:"Игра отменена",
        NoEmailConfirmation:"Не получили электронное письмо?",
        Resend:"Отправить повторно",
        NoMember:"Нет участников",
        AdminWillOpen:"Админ откроет",
        GameOpenTime:"Регистрация для Гостей откроется в ",
        TimeForGameCome:"Не забудьте обновить, когда время прошло",
        GenericError:"Что-то пошло не так",
        ErrorSend:"Ошибка отправлена",
        Notify:"Отправляйте уведомления",
        EmailNotification:"Уведомления на емайл",
        SubscribeOrUnsubscribe:"(От/Под)писаться (от/на) уведомления",
        ErrorNotSend:"Ошибка не может быть отправлена — пожалуйста, отправьте вручную по адресу info@play-all-sports.com. Сведения об ошибке:",
        ManageNotifications:"Управление уведомлениями",
        NotInAllGamesAddedAsDefault:"Вы не добавлены во все игры - потому что они пересекаются с другими играми, в которых вы уже есть в списке",
        TelegramCode:"Просмотреть Telegram Код",
        LoginWithTelegram: "Продолжить с Telegram",
        Welcome: "Добро пожаловать",
        UseGoogle: "Продолжить с Google",
        LoginWith:"Или войдите, используя:",
        NoAccountYet:"У вас еще нет аккаунта?",
        RegisterHere:"Зарегистрируйтесь здесь",
        AlreadyAnAccount:"У вас уже есть аккаунт?",
        Discussions:"Обсуждения",
        Surveys:"Опросы",
        Edited:"отредактировано",
        InterestedIn:"Заинтересован в",
        Community:"Сообщество",
        Soccer:soccer.ru,
        Basketball:basketball.ru,
        Wallet:"Тип операции",
        WalletOperations:["Пополнеть", "Снять"],
        ClubOptions:["Управляемые клубы", "Членские клубы"],
        WillCome:"Буду",
        OtherPlans:"Уже есть другие планы",
        ConfirmationTimeoutOptions:["Минуты после перемещения в список участников", "Мне не нужен тайм-аут"],
        PlayerPaymentOffer:"Игрок дал",
        PlayerPlaymentOptions:["Сумму", "Точную сумму"],
        Adjust:"Корректировать"
    }
});
setLanguage(localStorageService.getItem(localStorageKeys.language) ?? 'en');

export function setLanguage(countryCode: string) {
    localStorageService.setItem(localStorageKeys.language, countryCode);
    strings.setLanguage(countryCode);
    if(window.Notification && navigator.serviceWorker)
    navigator.serviceWorker.controller?.postMessage({language:countryCode});
    http.setLanguage(countryCode);
}

export default strings;