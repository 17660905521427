import React from 'react';
import { ContentComponentProps, DialogConfig } from '../dialog';
import localizationService from '../../../services/localizationService';
import Joi from 'joi';
import * as Helper from '../../../shared/ui/AppFormHelper';
import Model, { ResetPasswordProps } from './model';



export const ResetPassword: DialogConfig<ResetPasswordProps, boolean> = {
    Content: ReguestContent,
    inputModel: {email:''},
    resolvedResult : Model.onOk,
    title: ``,
    SubmitTitle: localizationService.Reset,
    CancelTitle: localizationService.Cancel,
    schema:Joi.object({
        email:Joi.string().required().email({ tlds: { allow: false } })
    })
}

function ReguestContent(props: ContentComponentProps<ResetPasswordProps>) {
    const email = props.state.email;

    return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop:30, marginLeft:30, marginRight:30 }}>
        <Helper.InputWithValidation
        errorMessage={props.errors?.email}
        textFieldProps={{placeholder:localizationService.Placeholders.Email, name:'email', defaultValue:email, InputLabelProps:{shrink:true}, 
        onChange:props.onInputChange
        }}
         />
    </div>;
}