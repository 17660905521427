import Game from "./entities/game";
import playerService from "./services/playerServices";

const gameRelatedNames = ['game','registered'];
export type CachingNames = 'game'|'club' | 'members' | 'registered' | 'player';
const cacheNames = [...gameRelatedNames,'club', 'members'];
interface GameRelatedCache{
    clubId:number,
    date:string,
    start:string
}
const getGameRelatedCache = (model:GameRelatedCache) => {
    const dateString = model.date.match(/\d{4}-\d{2}-\d{2}T00:00:00/)!;
    return `/${model.clubId}&${dateString[0]}&${model.start}`;
}
const getCacheData = async function(cacheName:CachingNames, resourceName:string) {
    const cache = await caches.open(cacheName);
    const response = await cache.match(resourceName);
    if(!response) return undefined;
    var result = await response.json();
    return result;
}
const storeCacheData =  async function (cacheName:CachingNames, resourceName:string, data:any) {
    try{
        const cache = await caches.open(cacheName);
        const options = {
            headers: {
              'Content-Type': 'application/json'
            }
          }

        const jsonResponse = new Response(JSON.stringify(data), options);
        await cache.add(resourceName);
        await  cache.put(resourceName, jsonResponse);
    }
        catch(error){
            
        }
    
    
}

const clearOutdatedCache = async() => {
    await removeOutdateGames();
    for(const name of gameRelatedNames){
        if(!await caches.has(name)) continue;
        const cache = await caches.open(name);
        for(const request of await cache.keys()){
            if(shoudNotBeExtented(name as CachingNames, request.url))
            await clearCache(name as CachingNames, request.url);
        }
    }
}

const clearCache = async (cacheName:CachingNames, cacheKey?:string) => {
    if(!cacheKey && cacheName === "club")
    return clearClubCache();
    if(!cacheKey)
    return caches.delete(cacheName);

    const cache = await caches.open(cacheName);
    
    await cache.delete(cacheKey!);
}

async function clearClubCache(){
    await caches.delete("club");
    await caches.delete("game");
}

const clearFull = async () => {
    let deleted = false;
    for(const key of cacheNames)
    {
        if(await caches.has(key))
        {
            await caches.delete(key);
            deleted = true;
        }
    }
    return deleted;
}

function shoudNotBeExtented(cacheName:CachingNames, cacheKey:string){
    if(!gameRelatedNames.includes(cacheName)) return false;

    const dateString = cacheKey.match(/\d{4}-\d{2}-\d{2}T00:00:00/);
    if(!dateString) return false;
    return gameRelatedCacheShouldNotBeExtended(dateString[0]);
}
async function removeOutdateGames(){
    const hasGames = await caches.has("game");
    if(!hasGames) return;
    const gamesCache = await caches.open("game");
    const gamesReponse = await gamesCache.match("/items/1");
    if(!gamesReponse) return;
    const requestData = await gamesReponse.json();
    if(requestData.ownerId)
    return clearFull();

    const firstGame = (requestData as Game[])[0];
    if(!firstGame) return;
    if(!gameRelatedCacheShouldNotBeExtended(firstGame.date)) return;
    for(const request of await gamesCache.keys()){
        if(request.url.match(/items/))
        await gamesCache.delete(request.url);
    }
}
function gameRelatedCacheShouldNotBeExtended(dateString:string){
    const gameDate = new Date(dateString).valueOf();
    const now = new Date(Date.now()).setHours(0,0,0,0);
    return gameDate < now;
}
export default {getCacheData, storeCacheData, clearCache, clearFull, getGameRelatedCache, clearOutdatedCache}