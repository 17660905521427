export default {
    en:{
        Logout:"Are you sure you want to logout?",
        DeleteRegistration:["Remove you from list?", "Remove player from game?"],
        CancelGame:"Are you sure you want to cancel this game?",
        ActivateGame:"Are you sure you want to open this game?",
        DeleteProfile:"Are you sure you want to delete profile? Your will not be able to view and register to games anymore",
        ProfileDeleted:"Profile was deleted",
        AddedToClub:"You are in club now as",
        Default:"Are you sure?",
        AllowedNotifications:"What notifications do you want to receive?",
        ExtractGameFee:"Extract Game Fee from this amount?",
        AskTransfer:"Are you sure you want to transfer his/her accoulated points in clubs administered by you in your wallet for this club?",
        ConfirmationRequired:"You are moved from Reserve to participation List, please confirm that you will be at the events",
        AutoRemoveReserv:"You will be auto-removed from the event in",
        ConfirmationTimeout:"I am aware that - if I will be moved to participation list by system, I ignore or block notifications and will not check by myself status and will not come to the event - I will pay penalty and risk to be blocked from this club",
        SendList:"Do you want to send list to players?"
    },
    ro:{
        Logout:"Sunteți siguri că doriți să faceți logout?",
        DeleteRegistration:["Să te excludem din listă?", "Exclude jucătorul din listă?"],
        CancelGame:"Sigur vrei să anulezi acest joc?",
        ActivateGame:"Sigur vrei să deschizi acest joc?",
        DeleteProfile:"Sunteți sigur că doriți să ștergeți profilul? Nu veți mai putea vedea și înregistra la jocuri",
        ProfileDeleted:"Profilul a fost șters",
        AddedToClub:"Acum ești în club ca ",
        Default:"Ești sigur?",
        AllowedNotifications:"Ce notificări doriți să primiți?",
        ExtractGameFee:"Extrageți taxa de joc din această sumă?",
        AskTransfer:"Sigur vrei să transferi punctele lui/ei acumulate în cluburile administrate de tine în portofelul tău pentru acest club?",
        ConfirmationRequired:"Sunteți mutat din Rezervă în Lista de participare, vă rugăm să confirmați că veți fi la evenimente",
        AutoRemoveReserv:"Veți fi eliminat automat din eveniment în",
        ConfirmationTimeout:"Sunt conștient că - dacă voi fi mutat pe lista de participare de sistemă, Ignorez sau blochez notificările și nu voi verifica singur statutul și nu voi veni la eveniment - voi plăti penalizare și voi risca să fiu blocat din acest club",
        SendList:"Doriți să trimiteți lista jucătorilor?"
    },
    ru:{
        Logout:"Вы действительно хотите выйти?",
        DeleteRegistration:["Исключить вас из списка?", "Исключить игрока из списка?"],
        CancelGame:"Вы уверены, что хотите отменить эту игру?",
        ActivateGame:"Вы уверены, что хотите открыть эту игру?",
        DeleteProfile:"Вы уверены, что хотите удалить профиль? Вы больше не сможете просматривать и регистрироваться на играх",
        ProfileDeleted:"Профиль удален",
        AddedToClub:"Теперь ты в клубе как ",
        Default:"Ты уверен?",
        AllowedNotifications:"Какие уведомления вы хотите получать?",
        ExtractGameFee:"Извлечь игровую плату из этой суммы?",
        AskTransfer:"Вы уверены, что хотите перевести его/ее накопленные баллы в клубах, администрируемых вами, на свой кошелек для этого клуба?",
        ConfirmationRequired:"Вы переведены из резерва в список участников, пожалуйста, подтвердите свое присутствие на мероприятиях",
        AutoRemoveReserv:"Вы будете автоматически удалены с мероприятия через",
        ConfirmationTimeout:"Я осознаю, что - если я буду перемещен системой в список участников,Я игнорирую или блокирую уведомления не проверю свой статус и не приду на мероприятие - я заплачу штраф и рискну быть заблокированным в этом клубе",
        SendList:"Хотите отправить список игрокам?"
    }
}