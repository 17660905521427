import React from 'react';
import { ClubScope } from '../entities/enums';
import Game from '../entities/game';
import RegisteredPlayer from '../entities/registeredPlayer';
export interface GameItemProps{
    game:Game,
    updateGame:(newState:Game) => void
}
export interface GamesProps{
    games:Game[],
    updateGames:(games:Game[]) => void
}
export interface GameContextValue extends GameItemProps {
    registeredPlayers:RegisteredPlayer[]
}
const GameContext = React.createContext({} as GameContextValue);
GameContext.displayName = "GameContext";
export const GamesContext = React.createContext({} as GamesProps);
GameContext.displayName = "GamesContext";

export default GameContext;