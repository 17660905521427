import { eventNames, routers } from "../../../constants";
import localization from "../../../services/localizationService";
import playerService from "../../../services/playerServices";
import events from "../../../shared/events";
const registerTabPaths = [routers.register, routers.termsAndConditions, routers.privacyPolicy];
export interface TabItem{
    index:number,
    paths:string[],
    parent?:string,
    label:string,
    selected:boolean
}
let tabs = getTabs();
const refreshTabs = () => {
tabs = getTabs();
}
let selectedTab = tabs.find(x => x.selected) ?? {index: 0, label:'', paths:'', selected:true};
const tabSelected = (path:string) =>{
    if(selectedTab)
    selectedTab.selected = false;
    selectedTab = tabs.find(x => x.paths.find(y => path.includes(y)) !== undefined )!;
    selectedTab.selected = true;
    events.trigger(eventNames.tabSelected, selectedTab);
}
function getSelectedTab(){
    return selectedTab;
}
function getTabs():TabItem[]{
    if(playerService.isLoggedIn())
    return [
        {
            index: 0,
            label:localization.Home,
            paths:[routers.profile.replace(":id", playerService.getPlayerId().toString())],
            selected:window.location.pathname.includes(routers.profile.replace(":id", ""))
        },
        {
        index:1,
        label:localization.Games,
        paths:[routers.games, routers.suggestedTeams.replace(":clubId&:date&:start", '')],
        selected: window.location.pathname === '/' || window.location.pathname.indexOf(routers.games) > -1
    },
    {
        index:2,
        label:localization.MyClus,
        paths:[routers.clubs],
        selected:window.location.pathname.indexOf(routers.clubs) > -1
    },
    {
        index:3,
        label:localization.Volleyball.EvaluatePlayers,
        paths:[routers.feedback],
        selected:window.location.pathname.indexOf(routers.feedback) > -1
    }
]
return [];
}

export { selectedTab, tabSelected, refreshTabs, getSelectedTab, getTabs}