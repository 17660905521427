import dialogs from "../components/dialogs";
import loading from "./ui/loadable/loading";

async function execute<TResult>(request:() => Promise<TResult>, onSuccess?:(apiResult:TResult) => void, onFailer?:() => void){
    try{
        const result = await request();
        onSuccess?.(result);
    }
    catch {
        onFailer?.();
    }
}
async function executeWithLoading<TResult>(request:() => Promise<TResult>, onSuccess?:(apiResult:TResult) => void, onFailer?:() => void){
await dialogs.Loading(execute(request, onSuccess, onFailer)).show();
}

export default { execute, executeWithLoading };