import React from "react";
import { DialogProps, open } from "../dialogHelpers";
import IDialog from "../dialogs";
import { Checkbox, FormControlLabel } from "@mui/material";
import DialogHeader from "../dialog-header";
import '../index.css'
import './index.css'
import MultiSelectItems from "../../multi-select";
interface Item{
    label:string,
    selected:boolean,
    id:number
}

interface Props extends DialogProps<Item[]>{
    items:Item[],
    label:string,
    mandatory?:boolean
}

export default function SelectableItems(items:Item[], label:string, mandatory?:boolean):IDialog<Item[]>{
    return {
        show:() => open((resolve) => <View label={label} items={items} mandatory={mandatory} resolve={resolve.resolve} />)
    }
}

function View({label, items, mandatory, resolve}:Props){
    return <div className="dialog-view interested-in-dialog">
        <p className="dialog-title">{label}</p>
        <MultiSelectItems items={items} />
        <DialogHeader resolve={resolve} steps={[{
            canGoFoward:() => true,
            mandatory:mandatory,
            onForward: () =>{
                resolve(items.filter(x => x.selected))
            }
        }]} />
    </div>
}