import Player from "../../../entities/player";
import UpdateProfile from "../../../models/updateProfile"
import profileService from "../../../services/profileService";
import apiRequest from "../../../shared/apiRequest";
import { DialogResult } from "../dialog";

async function onOk(model:UpdateProfile):Promise<DialogResult<Player>>{
    var data = new FormData();
    if(model.file)
    data.append("File", model.file);
    data.append("name", model.name);
    if(model.contactInfo)
    data.append("contactInfo", model.contactInfo);
    data.append("Picture", 'pic');
    let result:Player | undefined = undefined;
    await apiRequest.execute(() => profileService.update(data), (update) => result = update);
    return {succeeded:!!result, result}
}

export default {onOk}