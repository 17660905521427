import React, { useState } from "react";
import IDialog from "../dialogs";
import ParticipationConfirmation from "../../../entities/participationConfirmation";
import { DialogProps, open } from "../dialogHelpers";
import strings from "../../../services/localizationService";
import DialogHeader from "../dialog-header";
import CountDown from "../../../widgets/count-down";
import './index.css'
import { Button } from "@mui/material";
import viewModels from "../../../viewModels";
interface ParticipationConfirmationProps extends DialogProps<boolean> {
    input:ParticipationConfirmation[]
}

function ParticipationConfirmationDialog(input:ParticipationConfirmation[]):IDialog<boolean> {
    return {
        show : () => open((resolve) => <ParticipationConfirmationView resolve={resolve.resolve} input={input} />)
    }
}


function ParticipationConfirmationView({resolve, input}:ParticipationConfirmationProps){
    const [items, setItems] = useState(input);
    if(items.length === 0)
        resolve(true);
    const onItemStateChange = async (id:number, willBe:boolean, ingoreApi?:boolean) => {
        const copy = [...items];
        setItems(items.filter(x => x.id !== id))
        if(!ingoreApi){
            await viewModels.members.sendParticipationResponse({id, willBe}, () =>{
                setItems(copy);
            })
        }
    }
 return <div className="dialog-view">
    <div className="centered-dialog">
    <p className="dialog-title">{strings.Confirmations.ConfirmationRequired}</p>
    <div>{items.map(x => <ItemView key={x.id} item={x} execute={onItemStateChange} />)}</div>
    </div>
 </div>
}

interface ItemViewProps{
    item:ParticipationConfirmation,
    execute:(itemId:number, willCome:boolean, ingoreApiCall?:boolean) => void
}

function ItemView({item, execute}:ItemViewProps){
    const date = new Date(item.date);
    return <div className="card participationItem">
        <div className="participationItemDetails">
        <p className="time">{item.clubName}</p>
        <p className="time">{date.toLocaleDateString(strings.getLanguage(), {dateStyle:'full'})}</p>
        <p className="time">{item.start.substring(0,5)} - {item.end.substring(0,5)}</p>
        
        <CountDown deadLine={item.confirmationDLL}
        textColor="secondary"
        title={strings.Confirmations.AutoRemoveReserv}
        onTimeOut={() => execute(item.id, false, true)} 
        />
        <div className="confirmation-buttons">
            <Button color="primary" variant="outlined" onClick={() => execute(item.id, true)}>{strings.WillCome}</Button>
            <Button color="secondary" variant="outlined" onClick={() => execute(item.id, false)}>{strings.OtherPlans}</Button>
        </div>
        </div>
    </div>
}

export default ParticipationConfirmationDialog;