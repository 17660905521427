import dialogs from "../../components/dialogs";
import Game from "../../entities/game";
import PaginationModel from "../../models/paginationModel"
import gameService from "../../services/gameService";
import apiRequest from "../../shared/apiRequest";
import loading from "../../shared/ui/loadable/loading";

let pageModel:PaginationModel;
function setPageModel(value:PaginationModel){
    pageModel = value;
}
function getNexPage(games?:Game[]):PaginationModel{
    if(!games){
        pageModel = {page: 1, pageSize: 10};
         return pageModel;
}
    const lastGame = games[games.length - 1];
    const hour = lastGame.start.split(":")[0];
    pageModel.page += 1;
    pageModel.startDate = new Date(lastGame.date.replace("T00", "T"+hour));
    return pageModel;
}
async function loadGames(onSuccess:(games:Game[]) => void, onFailer?:() => void){
    await dialogs.Loading(apiRequest.execute(() => gameService.get(pageModel), onSuccess, onFailer)).show();
 
}

export default {setPageModel, getNexPage, loadGames}