function on(eventType:string, listener:EventListenerOrEventListenerObject) {
    document.addEventListener(eventType, listener);
  }
  
  function off(eventType:string, listener:EventListenerOrEventListenerObject) {
    document.removeEventListener(eventType, listener);
  }
  
  function trigger<T>(eventType:string, data:T) {
    const event = new CustomEvent<T>(eventType, { detail: data });
    document.dispatchEvent(event);
  }
  
  export default { on, off, trigger };