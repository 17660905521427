import cacheManager from "../../../cacheManager";
import { PlayerStatus } from "../../../entities/enums";
import MemberShipModel from "../../../models/membershipModel";
import playerServices from "../../../services/playerServices";
import apiRequest from "../../../shared/apiRequest";
import { DialogResult } from "../dialog";

async function onOk(model:MemberShipModel){
    let result:DialogResult<PlayerStatus> = {succeeded:false, closeIfsucceeded:true};
    await apiRequest.execute(() => playerServices.registerToMembership(model),
    (status) => {
        result = {succeeded: true, result:status}
    })
    if(result.succeeded){
        await cacheManager.clearCache("game");
    }
    return result;
}
export default {onOk}