import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { routers } from '../../constants';
import LoginForm from '../../forms/login-form';
import playerService from '../../services/playerServices';

function LoginPage(){
    const history = useNavigate();
    if(playerService.isLoggedIn())
    history(routers.games);
    return <div className='auth-page'>
        <img src={require('../../assets/auth-background.svg').default} />
        <LoginForm />
    </div>
}

export default LoginPage;