import React, { useState } from "react";
import IDialog from "../dialogs";
import { DialogProps, open } from "../dialogHelpers";
import strings from "../../../services/localizationService";
import DialogHeader from "../dialog-header";
import { TextField } from "@mui/material";
import { InputWithValidation } from "../../../shared/ui/AppFormHelper";
import './index.css'
interface Props extends DialogProps<string>{
    register?:boolean
}

function TelegramAuth(register?:boolean):IDialog<string>
{
    return {
        show : () => open((resolve) => <View resolve={resolve.resolve} register={register} />)
    }
}

function View({register, resolve}:Props){
    const [code, setCode] = useState('');
    return <div className="dialog-view telegram-auth">
        {register && <p>{strings.Placeholders.CreateTelegram}</p>}
        {!register && <p>{strings.Placeholders.TelegramBot}</p>}
        <InputWithValidation
        errorMessage=""
        textFieldProps={{placeholder:strings.Placeholders.TelegramCode, defaultValue:code, InputLabelProps:{shrink:true}, 
        onChange:(e) => setCode(e.currentTarget.value)
        }}
         />
        <DialogHeader 
        resolve={resolve}
        steps={[
            {
                onForward() {
                    resolve(code);
                },
                canGoFoward:() => !!code
            }
        ]}
        />
    </div>
}

export default TelegramAuth;