import { Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { eventNames } from '../../constants';
import { GuestsRegistrationStrategy, PlayerStatus } from '../../entities/enums';
import strings from '../../services/localizationService';
import events from '../../shared/events';
import AppButton from '../../shared/ui/appButton';
import Loadable from '../../shared/ui/loadable';
import {register, remove} from './model';
import Game from '../../entities/game';
import RegisteredPlayer from '../../entities/registeredPlayer';
import playerService from '../../services/serverServices/playerServices';
import GameContext, { GamesContext } from '../../context/gameContext';
import viewModels from '../../viewModels';
import CountDown from '../../widgets/count-down';

export const RegisterOrRemoveFromGame = () =>{
    const {game, registeredPlayers} = useContext(GameContext);
    const {games} = useContext(GamesContext);
    const alreadyRegistered = registeredPlayers.find(x => x.playerId === playerService.getPlayerId() && x.status !== PlayerStatus.AdminGuest);
    const {clubId, date, start, guestsRegistrationStrategy, gameOpenTime, canRegister} = game;
    const registartionId = alreadyRegistered?.registrationId ?? -1;
    const isInDefaultList = alreadyRegistered?.byDefault ?? false;
    const onSuccess = () =>{
        const gameIndex = games.indexOf(game);
        const gamesToUpdate = games.filter((x, i) => x.clubId == clubId && i >= gameIndex);
         viewModels.game.recalculateFees(gamesToUpdate);
    }
    return <div>
    {(registartionId < 0 && game.isAvialable) && <div>
    <Button variant="contained"
        color={canRegister ? "primary" : "secondary"}
        className="button"
        style={{opacity: canRegister ? 1 : 0.5, color:"white"}}
        disabled={!canRegister}
        onClick={() =>{
            register({
                clubId:clubId,
                date:date,
                start:start,
                confirmationTimeout:game.confirmationTimeout
            }, onSuccess);
        }}>{canRegister ? strings.RegisterToGame : strings.RegistrationNotAvailable}</Button>
        {!canRegister && <RegistrationDisabled openStrategy={guestsRegistrationStrategy} gameOpenTime={gameOpenTime} />}
        </div>}
        {registartionId > 0 && <AppButton variant="contained"
        label={strings.Unregister}
        confirmationmessage={strings.Confirmations.DeleteRegistration[0]}
        color="secondary"
        onClick={() =>{
            remove({clubId, date, start, isDefault: isInDefaultList, registrationId: registartionId}, onSuccess);
        }}/>}
</div>
}

interface RegistrationDisabledProps{
    openStrategy?:GuestsRegistrationStrategy,
    gameOpenTime?:string
}
function RegistrationDisabled({openStrategy, gameOpenTime}:RegistrationDisabledProps){
    const {updateGame, game} = useContext(GameContext);
    return openStrategy == GuestsRegistrationStrategy.Manual ? <ManuallyOpen />
           : <Loadable id="timer"
           View = {() => <CountDown 
            deadLine={gameOpenTime!} 
            title={strings.RegistationWillOpenIn} 
            textColor='initial'
            onTimeOut={() => updateGame({...game, canRegister:true})}
             />} />
}

function ManuallyOpen(){
    return <Typography>{strings.AdminWillOpen}</Typography>
}