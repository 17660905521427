import Axios from "axios";
import AppNotification from "../../entities/notification";
import NotificationService from "../interfaces/notificationService";
const baseUrl = 'client/';
function send(notification:AppNotification){
    return Axios.post(`${baseUrl}notify`, notification);
}

const implementation:NotificationService = {
    send
}

export default implementation;