import { toast } from "react-toastify";
import dialogs from "../components/dialogs";
import Club from "../entities/club";
import ParticipableAgenda from "../entities/participableAgenda";
import RegisteredPlayer from "../entities/registeredPlayer";
import Wallet, { NamedWallet } from "../entities/wallet";
import CreateClub from "../models/createClub";
import strings from "../services/localizationService";
import clubAgendaService from "../services/serverServices/clubAgendaService";
import clubService from "../services/serverServices/clubService";
import memberClubService from "../services/serverServices/memberClubService";
import walletService from "../services/walletService";
import apiRequest from "../shared/apiRequest";
import InviteLimitModel from "../models/inviteLimitModel";

function getParticipableAgendas(onSuccess:(items:ParticipableAgenda[]) => void){
    return apiRequest.execute(() => memberClubService.getMemberClubs(), onSuccess);
}
function registerToDefault(item:ParticipableAgenda, onSuccess:(player:RegisteredPlayer) => void, onFailer:() => void){
    return apiRequest.execute(() => memberClubService.register(item), onSuccess, onFailer);
}
function excludeFromDefault(item:ParticipableAgenda, onSuccess:() => void, onFailer:() => void){
    return apiRequest.execute(() => memberClubService.unregister(item.clubId, item.defaultRegistrationId!),
     async (result) =>{
        onSuccess();
     if(result)
        await dialogs.Info(result);
     }, onFailer);
}

async function getClubs(onSuccess:(items: Club[]) => void, onFailer:() => void){
    return apiRequest.execute(() => clubService.get(), onSuccess, onFailer);
}
async function deleteClub(id:number, onSuccess:(items: Club[]) => void, onFailer:() => void){
    return apiRequest.execute(() => clubService.delete(id), onSuccess, onFailer);
}

async function getWallets(onSuccess:(wallets:NamedWallet[]) => void) {
    return apiRequest.execute(() => walletService.get(), onSuccess);
}

async function askForTransfer(clubId:number, adminId:number) {
    var sure = await dialogs.Confirmation(strings.Confirmations.AskTransfer);
    if(!sure) return;
    await apiRequest.execute(() => walletService.askForTransfer(clubId, adminId), () =>{
        toast.info(strings.Placeholders.OperationCompleted);
    });
}

async function completeTransfer(clubId:number, memberId:number, accept:boolean, onSucces:(result:number) => void) {
    return apiRequest.execute(() => walletService.completeTransfer(clubId, memberId, accept), (result) =>{
        toast.info(strings.Placeholders.OperationCompleted);
        onSucces(result);
    });
}

function create(model:CreateClub){
    const clubPromise = new Promise<Club | undefined>(async (resolve) => {
        await apiRequest.execute(() => model.id ? clubService.put(model.id as number, model) : clubService.post(model),
        async (apiResult) => {
            if(model.file){ 
                const postData = new FormData();
                postData.append('File', model.file);
                apiResult.picture = await clubService.updatePicture(apiResult.id, postData);
            }
            resolve(apiResult);
        }, () => resolve(undefined))
    })
    return clubPromise;
}
async function setInviteLimitation(model:InviteLimitModel, onSucces:(result:number) => void) {
    return apiRequest.execute(() => clubService.setInviteLimitation(model), (result) =>{
        toast.info(strings.Placeholders.OperationCompleted);
        onSucces(result);
    });
}
export default {getParticipableAgendas, registerToDefault, excludeFromDefault, create, getClubs, deleteClub, getWallets, askForTransfer, completeTransfer, setInviteLimitation}