import React, { createRef, useEffect, useState } from 'react';
import { SocialPlatform } from '../../services/interfaces/authService';
import SocialLoginProps from '../../shared/ui/socailLoginProps';
import Model from './model';
import './index.css'
import strings from '../../services/localizationService';
import dialogs from '../../components/dialogs';
export const SocialAuth = ({register}:{register?:boolean}) => {
    return <>
    <p className='loghin-with'>{strings.LoginWith}</p>
    <div className='social-auth-methods'>
        <FacebookComponent onSocialLogin={() => window.location.reload()} />
        <GoogleComponent onSocialLogin={() => window.location.reload()} />
    <TelegramComponent onSocialLogin={() => window.location.reload()} register={register} />
    </div>
    </>
}

const FacebookComponent = ({onSocialLogin}:SocialLoginProps)  => {
    const onClick = async (window: any) => {
        window.FB.login(function(response: any){
            if(response.authResponse)
            Model.execute({
                accessToken:response.authResponse.accessToken,
                plaform:SocialPlatform.Facebook,
                onSuccess:onSocialLogin
            });
        });
    }

    return <div onClick={() => onClick(window)}
    className="auth-button social-auth-facebook-container">
<img src={require('../../assets/facebook-icon.svg').default}/>
    </div>;
}
interface TelegramComponentProps extends SocialLoginProps{
    register?:boolean
}
const TelegramComponent = ({onSocialLogin, register}:TelegramComponentProps)  => {
    const onClick = async (window: any) => {
        var code = await dialogs.TelegramAuth(register).show();
        if(code)
        Model.execute({
            accessToken:code,
            plaform:SocialPlatform.Telegram,
            onSuccess:onSocialLogin
        });
    }

    return <div className='auth-button telegram' onClick={() => onClick(window)} >
<img src={require('../../assets/telegram-icon.svg').default}/>
    </div>;
}

const GoogleComponent = ({onSocialLogin}: SocialLoginProps) => {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
    const googleButtonRef = createRef<HTMLDivElement>();
    const handleGoogleSignIn =async (res: CredentialResponse) => {
        if (!res.clientId || !res.credential) return;
        Model.execute({
            accessToken:res.credential,
                plaform:SocialPlatform.Google,
                onSuccess:onSocialLogin
        })
    };

    const initializeGsi = () => {
        if (!window.google || gsiScriptLoaded) return;
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_Id,
            callback: handleGoogleSignIn
        });
        setGsiScriptLoaded(true);
    }

    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = initializeGsi;
      script.async = true;
      script.defer = true;
      script.id = "google-client-script";
      document.querySelector("body")?.appendChild(script);
      return () => {
        window.google?.accounts.id.cancel();
        document.getElementById("google-client-script")?.remove();
      }
    }, [gsiScriptLoaded]);
  
  return <div>
    <div className="auth-button social-auth-google-container"
    onClick={() =>{
         var handler = googleButtonRef.current?.firstChild?.firstChild?.firstChild as HTMLDListElement;
         handler.click();
    }}
    >
        <img src={require('../../assets/google-icon.svg').default}/>
    </div>
<div className="g_id_signin social-auth-google" ref={googleButtonRef} />
  </div>
}