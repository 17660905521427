import React, { useEffect } from "react";
import './index.css'
import strings from "../../services/localizationService";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routers } from "../../constants";
import { ArrowForward, Forward } from "@mui/icons-material";
import playerService from "../../services/serverServices/playerServices";
export function OnBoarding(){
    const history = useNavigate();

    return <div className="on-boarding-page">
        <img className="on-boarding-background" src={require('../../assets/onBoarding_desktop.png')} />
        <div className="on-boarding-page-text">
            <p className="on-boarding-header">{strings.Volleyball.OnBoarding}</p>
            <p className="on-boarding-content">{strings.Volleyball.OnBoardingContent}</p>
            <div className="on-boarding-buttons-container">
                <Button className="on-boarding-button loghin" onClick={() => {history(routers.login)}}>{strings.Login}</Button>
                <Button className="on-boarding-button register" onClick={() => {history(routers.register)}}>{strings.Register}
                <ArrowForward /></Button>
            </div>
        </div>
    </div>
}