import React, { useState } from "react";
import IDialog from "../dialogs";
import { ClubScope, GameRotation, PlayerStatus, SportType } from "../../../entities/enums";
import strings from "../../../services/localizationService";
import { Checkbox, FormControlLabel, MenuItem, Select } from "@mui/material";
import { DialogProps, open } from "../dialogHelpers";
import Game from "../../../entities/game";
import DialogHeader from "../dialog-header";
interface Result{
    rotation:GameRotation,
    withLibero?:boolean
}
function RotationType(game:Game):IDialog<Result>
{
    return {
        show : () =>  {
            if(game.type !== SportType.ClassicVolleyball)
           return Promise.resolve({rotation:GameRotation.NoSpecificPositions}); 
        if(game.registeredPlayers.find(y => y.status === PlayerStatus.AdminGuest))
            return Promise.resolve({rotation:GameRotation.NoSpecificPositions}); 
        return open((resolve) => <View resolve={resolve.resolve} registeredCount={game.registeredPlayers.length} />)
        }
    }
}

interface Props extends DialogProps<Result>{
registeredCount:number
}

function View({resolve, registeredCount}:Props){
    const [rotation, setRotation] = useState(GameRotation.NoSpecificPositions);
    
    const [withLibero, setWithLibero] = useState(false);
    const optionEnabled = (index:number) =>{
        if(index === GameRotation.NoSpecificPositions) return true;
        if(index === GameRotation.Rotation_4_2) return registeredCount > 7;
        if(index === GameRotation.Rotation_5_1) return registeredCount > 11;
    }
    return <div className="dialog-view">
    <div className="centered-dialog">
    <p className="dialog-title">{`${strings.Select} ${strings.Volleyball.RotationLabel}`}</p>
    <Select
    value={rotation}
    onChange={(e) => {
        setRotation(e.target.value as GameRotation)
    }}
    >
        {strings.Volleyball.Rotations.map((v, i) => <MenuItem key={i} value={i} disabled={!optionEnabled(i)}>{v}</MenuItem>)}
    </Select>
    {rotation == GameRotation.Rotation_5_1 && <FormControlLabel
            control={
                <Checkbox
                    checked={withLibero}
                    onChange={(event) => setWithLibero(event.target.checked)}
                    color="primary"
                />
            }
            label={strings.Volleyball.WithLibero}
        />}
    </div>
    <DialogHeader
    resolve={resolve}
    steps={[{
        canGoFoward:() => true,
        mandatory:true,
        onForward:() => resolve({rotation, withLibero})
    }]} 
    />
    </div>
}

export default RotationType;