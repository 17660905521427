import { Button } from "@mui/material";
import React from "react";
import strings from "../../services/localizationService";
import profileService from "../../services/profileService";
import apiRequest from "../../shared/apiRequest";
import dialogs from "../../components/dialogs";

function TelegramCode(){

    return <Button variant="contained" color="primary"
    className="user-action-button"
     onClick={async () =>{
        await apiRequest.execute(() => profileService.getTelegramCode(), async (result) =>{
            await dialogs.Info(result);
        });
    }}>{strings.TelegramCode}</Button>
}

export default TelegramCode;