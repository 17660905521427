import React, { useContext } from "react";
import { DropDown } from "../../widgets/drop-down";
import ProfileSportSectionContext from "../../context/profileSportSectionContext";
import { SportType } from "../../entities/enums";
import strings from "../../services/localizationService";
import PositionsView from "../positions/ui";
import Loadable from "../../shared/ui/loadable";
import SkillContext from "../../context/skillContext";
import { UserFeedbackView } from "../skills";

export default function ProfileSportSection(){
    const {sport} = useContext(ProfileSportSectionContext);
return <DropDown
title={getTitle(sport.sportType)}
 ><><PositionsView />
 <div className="profile-page skills">
 <Loadable View={() => <SkillContext.Provider value={{readonly:true, ...sport , updateSkills:(result) =>{ }}} >
 <UserFeedbackView />
 </SkillContext.Provider>}
 id={`skills_${sport.sportType}`}
 />
 </div></></DropDown>
}

function getTitle(sportType:SportType){
    if(sportType === SportType.ClassicVolleyball || sportType === SportType.BeachVolleyball)
    return strings.Volleyball.SportTypes[0];
    return strings.Volleyball.SportTypes[sportType - 1];
}