import GameRegisterModel from "../../../models/gameRegisterModel";
import clubService from "../../../services/clubService";
import events from "../../../shared/events";
import {eventNames as eventNames} from "../../../constants";
import { UpdatePlayerListArgs } from "../../../shared/eventArgs";
import apiRequest from "../../../shared/apiRequest";
import dialogs from "../../../components/dialogs";
import viewModels from "../../../viewModels";

const register = async (input:GameRegisterModel, onSuccess:() => void) =>{
    apiRequest.execute(() => clubService.registerGuest({
        clubId: input.clubId,
        date: input.date,
        start:input.start,
        playerName:input.playerName
    }), async (result) =>{
        
        events.trigger<UpdatePlayerListArgs>(eventNames.updatePlayers, {add:true, game:{...input}, registrationId:result.registrationId, record:result});
        onSuccess();
        if(result.isRezerv && input.confirmationTimeout &&
            await dialogs.RezervInfo(input.confirmationTimeout).show())
            viewModels.members.setConfirmationTimeout(input.clubId, () => {});
    } );
}

export {register};