import AgendaDialog from "./add-agenda/addAgenda"
import GameDialog from "./add-game"
import AppInfo from './info'
import Club from './club'
import ChatDialog from "./add-chat"
import ViewAll from "./view-all"
import SelectableItems from "./selectable-items"
import {ClubWallet, UserWallets, RegisteredPlayerWallet} from './wallet';
import TelegramAuth from "./telegra-auth"
import ParticipationConfirmationDialog from "./participation-confirmation"
import TimeoutConfirmation from "./timeout-confirmation"
import GamePaymentsDialog from "./game_payments"
import RezervInfo from "./rezerv-info"
import Loading from "./loadingDialog"
import InvitationLimitationDialog from "./invitation-limit-dialog"
import RotationType from "./rotation-type"
export default {AgendaDialog, GameDialog, ...AppInfo, Club, ChatDialog, ViewAll,
    SelectableItems, ClubWallet, UserWallets, RegisteredPlayerWallet, TelegramAuth, ParticipationConfirmationDialog,
    TimeoutConfirmation, GamePaymentsDialog, RezervInfo, Loading, InvitationLimitationDialog, RotationType}