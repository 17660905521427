import { Fab, Typography } from '@mui/material';
import { ArrowBackRounded, ArrowDropDown, ArrowDropUp, ArrowUpwardRounded } from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import './index.css';
export interface DropDownProps{
    defaultState?:boolean,
    title:string,
    children: React.ReactElement,
    onStateChanged?:(state:boolean) => void
}
export const DropDown = (props:DropDownProps) => {
    const [isOpen, setIsOpen] = useState(props.defaultState);
    const classes = props.defaultState ? 'content open' : 'content';
    const content = useRef<HTMLDivElement>(null);
    const changeState = () =>{
        content.current?.classList.toggle('open');
        props.onStateChanged?.(content.current?.classList.contains('open')!);
        setIsOpen(!isOpen);
    }
    return <div className='drop-down'>
        <div className='header' onClick={changeState}>
        <Typography>{props.title}</Typography>
        {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </div>
        <div className={classes} ref={content}>
        {props.children}
        </div>
    </div>
}
