import React from "react";
import './index.css';

export function InfinitScrollingView({onEndReached, body}:{ onEndReached:() => void, body:() => JSX.Element}){

    return <div className="infinit-scroll-view"
    onScroll={(e) => {
        if(e.currentTarget.scrollTop === 0) onEndReached();
    }}>
        {body()}
    </div>
}