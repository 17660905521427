import { Checkbox, FormControlLabel } from '@mui/material';
import Joi from 'joi';
import React from 'react'
import EmailSubscription from '../../../entities/emailSubscription';
import strings from '../../../services/localizationService';
import * as Helper from '../../../shared/ui/AppFormHelper';
import open, { ContentComponentProps, DialogConfig } from '../dialog';
interface Props{
    email:string, enabled:boolean, emailIsValid:boolean
}

export function display(model:Props){
    return open(show(model));
}

function show(model:Props):
 DialogConfig<Props, EmailSubscription> {
    return {
        inputModel:model,
        title:strings.EmailNotification,
        Content:Content,
        SubmitTitle:strings.Ok,
        CancelTitle:strings.Cancel,
        resolvedResult:(model) => Promise.resolve({succeeded:true, result:model}),
        schema:Joi.object({
            email:Joi.string().required().email({ tlds: { allow: false } })
        })
    };
}

function Content(props:ContentComponentProps<Props>){
    const {email, enabled, emailIsValid} = props.state;
    return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop:30, marginLeft:30, marginRight:30 }}>
    {!emailIsValid && <Helper.InputWithValidation
    errorMessage={props.errors?.email}
    textFieldProps={{placeholder:strings.Placeholders.Email, name:'email', defaultValue:email, InputLabelProps:{shrink:true}, 
    onChange:props.onInputChange
    }}
     />}
     <FormControlLabel
     control={
         <Checkbox
             checked={enabled}
             onChange={(event) => props.setState({
                 enabled:event.target.checked
             } as Props)}
             color="primary"
         />
     }
     label={strings.Notify}
 />
</div>
}