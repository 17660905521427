import { Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { eventNames } from "../../constants";
import Message from "../../entities/message";
import playerService from "../../services/serverServices/playerServices";
import events from "../../shared/events";
import model from "../../viewModels";
import { InfinitScrollingView } from "../../widgets/Infinit-scrolling-view";
import './index.css'
import { Check, Close, Edit } from "@mui/icons-material";
import dialogs from "../../components/dialogs";
import strings from "../../services/localizationService";
import viewModels from "../../viewModels";
export function ChatMessages({chatId}:{chatId:number}){
    const [messages, setMessages] = useState<Message[]>([]);
    const ref = useRef<HTMLDivElement>(null);
    const onMessageReceived = (event:any) => {
        const message = event.detail as Message;
        let exists = false;
        setMessages(x =>{ 
            if(message.chatId !== chatId) return x;
            const exitingMessage = x.find(y => y.id === message.id);
            exists = exitingMessage !== undefined;
            if(exists && exitingMessage?.message === message.message) return x;
            if(exists && exitingMessage?.senderId === playerService.getPlayerId()) return x;
            
            if(exists && exitingMessage){
                exitingMessage.message = message.message;
                exitingMessage.sendTime = message.sendTime;
                exitingMessage.editedTime = message.editedTime;
                return [...x]
            }
            return[...x, message];
        });
        if(!exists)
        {
            ref.current?.scrollIntoView({behavior: "smooth" });
            if(message.senderId !== playerService.getPlayerId())
            viewModels.chat.markAsRead(message.chatId);
        }
    }

    const deleteMessage = (event:any) =>{
        setMessages(x => {
            return x.filter(y => y.id !== event.detail);
        })
    }

    useEffect(() => {
        events.on(eventNames.messageReceived, onMessageReceived);
        events.on(eventNames.deleteMessage, deleteMessage);
        setTimeout(() => {
        ref.current?.scrollIntoView();
        }, 100);
        return () => {
            events.off(eventNames.messageReceived, onMessageReceived);
            events.off(eventNames.deleteMessage, deleteMessage);
        }
    }, []);

    useEffect(() => {
        model.chat.getMessages(chatId, (result) => {
            setMessages(result);
            ref.current?.scrollIntoView();
        });
    }, [chatId]);

    return <InfinitScrollingView 
    body={() => <div className="chat-messages-container">{messages.map(x => <MessageView
         key={x.id} deleteItem={async (id:number) =>{
        await viewModels.chat.deleteMessage(id, () => setMessages(messages.filter(x => x.id !== id)));
         }} message={x} 
         updateItem={(newValue) => {
            const toUpdate = messages.find(x => x.id == newValue.id)!;
            const index = messages.indexOf(toUpdate);
            setMessages([...messages.slice(0, index), newValue, ...messages.slice(index + 1, messages.length)])
         }}
         />)}
    <div ref={ref}></div></div>}
    onEndReached={() => model.chat.loadMore(chatId, (result) =>{
        const previousFirstItem = ref.current!.parentElement?.firstElementChild;
        setMessages([...result, ...messages]);
        previousFirstItem?.scrollIntoView();
    })}
    />
}

function MessageView({message, deleteItem,  updateItem}:{message: Message, deleteItem?:(id:number) => void,  updateItem:(newValue:Message) => void}){
    return message.senderId === playerService.getPlayerId() ? <TextView message={message} updateItem={updateItem} deleteItem={deleteItem} />
    : <OtherMessageView message={message} />
}

function TextView({message, deleteItem, updateItem}:{message:Message, deleteItem?:(id:number) => void, updateItem?:(newValue:Message) => void}){
    const isOwner = message.senderId === playerService.getPlayerId();
    const [editModel, setEditModel] = useState(false);
    const editRef = useRef<HTMLTextAreaElement>(null);
    return <div className={isOwner ? "chat-message-owner-content chat-message-text owner" : "chat-message-owner-content chat-message-text"}>
    <p className="message-time">{`${message.editedTime ? `${strings.Edited} ` : ''}${new Date(message.editedTime ?? message.sendTime).toLocaleString()}`}</p>
    {!editModel && <p dangerouslySetInnerHTML={{__html:message.message}} />}
    {editModel && <AutoReziedTextArea textAreaRef={editRef} defaultValue={GetMessage(message.message)} />}
    {isOwner && <div className="edit-message-buttons">
<div onClick={async (e)=>{ 
    if(editModel && editRef.current?.value && editRef.current.value !== message.message){
        await viewModels.chat.edit(message.id, editRef.current?.value, updateItem!);
    }
    setEditModel(!editModel);
}}>{!editModel && <Edit />} {editModel && <Check />}</div>

<div onClick={async () => {
    if(editModel) return setEditModel(false);
    const confirmed = await dialogs.Confirmation("Delete this message?");
    if(confirmed)
    deleteItem?.(message.id) }}><Close /></div>
</div>}
    </div> 
}

function AutoReziedTextArea({defaultValue, textAreaRef}:{defaultValue:string, textAreaRef:React.RefObject<HTMLTextAreaElement>}){
    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        if (textAreaRef.current) {
          textAreaRef.current.style.height = "0px";
          const scrollHeight = textAreaRef.current.scrollHeight;
          textAreaRef.current.style.height = scrollHeight + "px";
        }
      }, [textAreaRef, value]);
    return <textarea autoFocus ref={textAreaRef} rows={1} className="edit-message-input" value={value} onChange={(e) => setValue(e.target.value)} />
}

function GetMessage(input:string){
    if(!input.includes("</a>"))
    return input;
    input = input.replace(/<a.+>http/, 'http');
    input = input.replace(/<a.+>www/, 'www');
    input = input.replace('</a>', '');
    return input;
}

function OtherMessageView({message}:{message: Message}){
    return <div className="chat-message">
        <div className="chat-message-owner">
        <img src={message.senderPicture ?? require("../../assets/avatar.jpg")} />
        <p className="chat-message-text">{message.senderName}</p>
        </div>
       <TextView message={message} />
    </div>
}