import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import { BrowserRouter } from 'react-router-dom';
import {ThemeProvider, createTheme } from '@mui/material/styles'
import { register } from './serviceWorkerRegistration';
export const theme = createTheme({
  palette:{
    primary:{
      main:"#72CD84",
      dark:"#5d9669",
      light:"#86e399",
    },
    secondary:{
      main:"#D1425C",
      dark:"#9e2c41",
      light:"#f04a69"
    },
    
  },
  typography:{
    allVariants:{color:"#453721", textTransform:"none"},
    fontFamily:'Manrope',
  },
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register();