import React from "react";
import RegisteredPlayer from "../../../entities/registeredPlayer";
import '../index.css'
import './index.css'
import { NonClickableItem, RegisteredPlayerView } from "../../../features/player-view";
import IDialog from "../dialogs";
import { DialogProps, open } from "../dialogHelpers";
import { Button } from "@mui/material";
import { Check, CheckBox } from "@mui/icons-material";

function ViewAllDialog({players , label}:{players:RegisteredPlayer[], label:string}):IDialog<boolean>{
    return {
        show:() => open((resolve) => <ViewAll resolve={resolve.resolve} players={players} label={label} />)
    }
}
interface Props extends DialogProps<boolean>{
    players:RegisteredPlayer[],
    label:string
}
function ViewAll({players, label, resolve}:Props){
    return <div className="dialog-view centered-dialog">
        <p className="dialog-title">{label}</p>
        <div className="all-players">
            {players.map(x => <div><NonClickableItem key={x.playerId + x.registrationId + x.registeredTime} item={x} /></div>)}
        </div>
        <Button variant="outlined" color="primary" onClick={() => resolve(true)}><Check /></Button>
    </div>
}

export default {ViewAllDialog}