import React from "react";
import { AppOptionsWithSupprtedItems } from "../app-options";
import strings from "../../services/localizationService";

export default function YesNoOption({title, defaultValue, onStateChanged}:{title:string, defaultValue?:boolean, onStateChanged?:(newState:boolean) => void}){
    
    return <AppOptionsWithSupprtedItems
    state={{
        items:[1, 0],
        title:title,
        optionText:(index) => index === 1 ? strings.Yes : strings.No
    }}
    defaultValue={defaultValue ? 1 : 0}
    onStateChanged={(index) => onStateChanged?.(index === 1)}
     />
}