import authService from "../services/authService"
import apiRequest from "../shared/apiRequest"
import playerService from "../services/playerServices"
import { display } from "../dialogs/ui/notification-groups/notification-gruops"
import Player from "../entities/player"
import profileService from "../services/profileService"
import { SportType } from "../entities/enums"
import strings from "../services/localizationService"
import dialogs from "../components/dialogs"

async function changePermission(){
    await apiRequest.execute(() => playerService.getNotificationGroups(),
    async (result) => {
        const dialogResult = await display({
            notificationGrops:result
        })
        if(dialogResult)
        apiRequest.execute(() => playerService.updateNotificationGroups(dialogResult));
    })
}
async function setPositions(sportType:SportType, positions:number[], onSucces:() => void){
    await apiRequest.execute(() => profileService.updatePositions(sportType, positions), () =>{
        onSucces();
    })
}
async function logout(onSuccess: () => void){
    await apiRequest.execute(() => playerService.unSubscribe());
    await apiRequest.execute(() => authService.logout(), onSuccess);
}

function getAll(onSuccess:(result:Player[]) => void){
    return apiRequest.execute(() => profileService.getAll(), onSuccess);
}

async function setSports(initialSports:SportType[], onSuccess:(sports:SportType[]) => void, mandatory?:boolean){
    var sports = Object.values(SportType).filter(x => typeof x === "number").map(x => x as SportType).map(x => {
        return {
            label:strings.Volleyball.Types[x],
            id:x,
            selected:initialSports.includes(x)
        }
    });
    const result = await dialogs.SelectableItems(sports, strings.InterestedIn, mandatory).show();
    if(!result) return;
    var selectedSports = result.map(x => x.id);
    if(selectedSports.length === initialSports.length && !selectedSports.find(x => !initialSports.includes(x)))
    return;
    await apiRequest.execute(() => profileService.updateInteres(selectedSports), () =>{
        onSuccess(selectedSports);
    });
}

export default {logout, changePermission, getAll, setSports, setPositions}