import React from 'react';
import { Skills, SportType } from '../entities/enums';
import Skill from '../entities/skill';
import UnreadedMessages from '../entities/unreadedMessages';
export interface UnreadedMessagesValue {
    unreadedMessages: UnreadedMessages;
}
const UnreadedMessagesContext = React.createContext<UnreadedMessagesValue>({} as UnreadedMessagesValue);
UnreadedMessagesContext.displayName = "UnreadedMessagesContext";

export default UnreadedMessagesContext;