import http from "./http";
import ClubService from '../interfaces/clubService';
import CreateClub from "../../models/createClub";
import Club from "../../entities/club";
import ClubSettings from "../../entities/clubSettings";
import Player from "../../entities/player";
import UpdateStatusModel from "../../models/updateStatusModel";
import GameRegisterModel from "../../models/gameRegisterModel";
import RegisteredPlayer from "../../entities/registeredPlayer";
import { getCookieValue } from "../../shared/cookieToObject";
import RemoveFromGame from "../../models/removeFromGame";
import strings from "../localizationService";
import ClubLocation from "../../entities/clubLocation";
import cacheManager from "../../cacheManager";
import { PlayerStatus } from "../../entities/enums";
import dialogs from "../../components/dialogs";
import Member from "../../entities/member";
import InviteLimitModel from "../../models/inviteLimitModel";

const baseUrl = "clubAdministrator";

function get(): Promise<Club[]> {
    return http.getWithCaching({cacheName:'club', cacheKey:'/items'},`${baseUrl}`);
}
async function post(createClub : any): Promise<Club>{
    const result = http.post<Club>(`${baseUrl}`, createClub);
    await cacheManager.clearCache("club");
    return result;
}
function updatePicture(clubId:number, uploadedImage:string):Promise<string>{
    return http.put(`${baseUrl}/picture/${clubId}`, uploadedImage);
}
async function put(clubId:number, settings:any): Promise<Club>{
    var result = (await http.put(`${baseUrl}/${clubId}`, settings)) as any;
    if(result.someGamesHaveOtherScope)
    await dialogs.Info(strings.GameHaveOtherScope);
    await cacheManager.clearCache("club");
    return result;
}

async function remove(clubId:number):Promise<any>{
    await http._delete(`${baseUrl}/${clubId}`);
    await cacheManager.clearCache("club");
}

function getSettings(clubId:number):Promise<ClubSettings>{
    return http.getWithCaching({cacheName:'club', cacheKey:`/settings/${clubId}`},`${baseUrl}/settings/${clubId}`);
}

function getMembers(clubId:number) : Promise<Member[]>{
    return http.getWithCaching({cacheName:'members', cacheKey:`/${clubId}`},`${baseUrl}/members/${clubId}`);
}

async function updateStatus(clubId:number, model:UpdateStatusModel) : Promise<any>{
    await http.post(`${baseUrl}/status/${clubId}`, model);
    const membersCachedData = await cacheManager.getCacheData("members", `/${clubId}`) as Player[];
    const updateMember = membersCachedData.find(x => x.id == model.memberId)!;
    updateMember.status = model.memberStatus;
    await cacheManager.storeCacheData("members", `/${clubId}`, membersCachedData);
}

async function registerGuest(model:GameRegisterModel) : Promise<RegisteredPlayer>{
    const result = http.post<RegisteredPlayer>(`${baseUrl}/register/${model.clubId}`, model);
    await cacheManager.clearCache("registered", cacheManager.getGameRelatedCache(model));
    return result;
}
async function removeRegistration(clubId:number, isGuest:boolean, model:RemoveFromGame){
    await http.post(`${baseUrl}/remove/${clubId}?isGuest=${isGuest}`, model);
    await cacheManager.clearCache("registered", cacheManager.getGameRelatedCache(model));
}
function isAdmin(){
    return getCookieValue('ClubAdmin') == "True"
}

function getLocations(address:string):Promise<ClubLocation[]>{
    return http.get(`${baseUrl}/locations?address=${address}`);
}
function getMembersToRegister(clubId:number):Promise<Player[]>{
    return http.get(`${baseUrl}/membersToRegister/${clubId}`);
}
function registerMember(clubId:number, playerId:number, status:PlayerStatus){
    return http.post(`${baseUrl}/member/register/${clubId}?playerId=${playerId}&status=${status}`);
}
function getRules(clubId:number){
    return http.getWithCaching({cacheKey:"rules", cacheName:"club"}, `${baseUrl}/rules/${clubId}`);
}
function setInviteLimitation(model:InviteLimitModel):Promise<number>{
    return http.post(`${baseUrl}/invitationLimitation/${model.clubId}`, model);
}
const clubService:ClubService = {
    get,
    post,
    put,
    delete:remove,
    getSettings,
    getMembers,
    updateStatus,
    registerGuest,
    removeRegistration,
    isAdmin,
    getLocations,
    getMembersToRegister,
    registerMember,
    updatePicture,
    getRules,
    setInviteLimitation
};
export default clubService;