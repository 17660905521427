import React, { useEffect, useState } from 'react';
import {ErrorBoundary } from 'react-error-boundary'

import './App.css';
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from 'react-toastify';
import NavigationBar from '../widgets/navigation-bar/navigationBar';
import LanguageContext from '../context/languageContext';
import strings, { setLanguage } from '../services/localizationService';
import {Routing} from '../pages';
import 'core-js/actual/array/at'
import playerService from '../services/playerServices';
import AppFallbackComponent from './AppFallbackComponent';
import Model from '../dialogs/ui/email-subscription/model';
import { construct } from '../signalR';
import { useServiceWorker } from '../hooks/useServiceWorker';
import dialogs from '../components/dialogs';
import viewModels from '../viewModels';
import { Button } from '@mui/material';

function App() {
  const [language, setLanguageState] = useState(strings.getLanguage());
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    playerService.subscribe();
    Model.requestPermission();
    viewModels.members.getParticipationConfirmations((result) => dialogs.ParticipationConfirmationDialog(result).show());
    construct();
  }, []);
  const updateLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
    setLanguageState(newLanguage);
  };
  return (
      <LanguageContext.Provider value={{ currentLanguage: language, updateLanguage: updateLanguage }}>
        <div className="App">
          <div className='background' />
          <div className="App-header">
            {waitingWorker && showReload && <div className='new-version-container'><div className='new-version card-parent card'>
              <p>{strings.Placeholders.NewVersion}</p>
              <Button color='primary' variant='outlined' onClick={reloadPage}>{strings.Update}</Button>
            </div></div>}
            <ToastContainer />
            <NavigationBar />
            <ErrorBoundary FallbackComponent={AppFallbackComponent}>
              <div className='app-body'>
            <Routing />
            </div>
            </ErrorBoundary>
            </div>
        </div>
    
    </LanguageContext.Provider>
  );
}

export default App;
