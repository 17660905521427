import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { eventNames } from "../constants";
import Chat from "../entities/chat";
import Message from "../entities/message";
import chatService from "../services/chatService";
import apiRequest from "../shared/apiRequest";
import events from "../shared/events";
import ChatListChangeListener from "../entities/chatListChangeListener";
import playerService from "../services/serverServices/playerServices";
import { ChatModificationType } from "../entities/enums";
import ChatModel from "../models/chatModel";
import User from "../entities/user";
import profileService from "../services/profileService";
import ChatSettings from "../entities/chatSettings";
import UnreadedMessages from "../entities/unreadedMessages";


var page:number;
var canLoadMore:boolean;
let connectionId:string;
function init(connetion:string){
    if(!playerService.isLoggedIn()) return;
    connectionId = connetion;
    chatService.add(connectionId);
}
function getClubs(onSucces:(clubs:Chat[]) => void){
    apiRequest.execute(() => chatService.getChats(), onSucces);
}
let timer:NodeJS.Timeout;
function sendMessage(chatId:number, text:string){
    return apiRequest.execute(() => chatService.say(chatId, text), (message) => {
        events.trigger(eventNames.messageReceived, message);
        if(timer)
        clearTimeout(timer);
        timer = setTimeout(() =>{
            chatService.didReadMessage(message);
        }, 2000);
    });
}

function getMessages(chatId:number, onSucces:(messages:Message[]) => void){
    if(chatId <= 0) {
        onSucces([]);
        return;
    }
    page = 1;
    return apiRequest.execute(() => chatService.getMessage(chatId, page), (result) =>{
        canLoadMore = result.length > 0;
        onSucces(result);
    })
}

function markAsRead(id:number){
    if(id <= 0) return;
    apiRequest.execute(() => chatService.markAsRead(id), () => {
        events.trigger(eventNames.onMessageStateChanged, {chatId:id, read:true});
    });
}

async function loadMore(chatId:number, onSucces:(messages:Message[]) => void){
    if(!canLoadMore) return;
    if(chatId <= 0) {
        onSucces([]);
        page = 1;
        return;
    }
    page = page + 1;
    await apiRequest.execute(() => chatService.getMessage(chatId, page), (result) => {
        
        canLoadMore = result.length > 0;
        if(!canLoadMore){
            page = page - 1;
            return;
        }
        onSucces(result);
    })
}

function onChatListChangedHanlder(listener:ChatListChangeListener){
    const shouldNotify = listener.usersToNotify.find(x => x === playerService.getPlayerId()) != undefined;
    if(!shouldNotify) return;
    apiRequest.execute(() => listener.modificationType === ChatModificationType.Add ? chatService.add(connectionId, listener.chat.id)
    : chatService.remove(connectionId, listener.chat.id), () => {
    })
}
function ask(chatId:number, adminId:number, question:string){
    return apiRequest.execute(() => chatService.ask(chatId, adminId, question));

}
async function edit(messageId:number, text:string, onSucces:(newValue:Message) => void){
    return apiRequest.execute(() => chatService.edit(messageId, text), onSucces);
}
async function deleteMessage(messageId:number, onSucces:() => void){
    return apiRequest.execute(() => chatService.deleteMessage(messageId), onSucces);
}
async function deleteChat(chatId:number, onSuccess:() => void, onFailed:() => void){
    return apiRequest.execute(() => chatService.deleteChat(chatId), onSuccess, onFailed);
}
async function create(model:ChatModel, onSucces:(chat:Chat) => void) {
    const postData = new FormData();

    postData.append("Name", model.name);
    postData.append("ParticipantsIds", model.participantsIds.join(','));
    if(model.chatId)
    postData.append("ChatId", model.chatId.toString());
    if(model.file)    
    postData.append("File", model.file);
    console.log(postData);
    console.log(model.chatId);
    return apiRequest.execute(() => model.chatId ? chatService.editChat(model.chatId, postData) : chatService.create(postData), onSucces)
}
function getToAdd(onSuccess:(participants:User[]) => void){
    return apiRequest.execute(() => profileService.getAll(), (result) => {
        const participants = result.filter(x => x.id !== playerService.getPlayerId());
        onSuccess(participants);
    })
}
function chatSettings(chatId:number){
    const request = new Promise<ChatSettings | undefined>(async (resolve) =>{
    await apiRequest.execute(() => chatService.chatSettings(chatId), resolve, () => resolve(undefined))
    })
    return request;
}

function getUnreaded(onSuccess:(result:UnreadedMessages) => void){
    return apiRequest.execute(() => chatService.getUnreaded(), onSuccess);
}

export default {getClubs, sendMessage,
     getMessages, loadMore,
      onChatListChangedHanlder, markAsRead,
       init, ask, edit, deleteMessage,
        create, getToAdd, deleteChat, chatSettings, getUnreaded}