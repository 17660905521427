import React, { PropsWithChildren, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, ThemeProvider } from '@mui/material/';
import { createRoot, Root } from 'react-dom/client';
import * as Helper from '../../shared/ui/AppFormHelper';
import Joi from 'joi';
import { theme } from '../..';
import { Check, Close } from '@mui/icons-material';

interface DialogComponentProps<TModel, TResult> extends DialogConfig<TModel, TResult> {
    resolve: (value?: TResult | PromiseLike<TResult> | undefined) => void,
    parent:Root
}

export interface DialogConfig<TModel, TResult> {
    title?: string,
    cancelOnOutside?:boolean,
    validationNotNeeded?:boolean,
    SubmitTitle?: string,
    CancelTitle?: string,
    dialogColor?:string,
    schema?:Joi.Schema,
    OnCancel?:() => void,
    resolvedResult:(model:TModel) => Promise<DialogResult<TResult>>,
    inputModel?: TModel,
    Content: React.ComponentType<ContentComponentProps<TModel>> | React.ComponentType<any>,
}
export interface DialogResult<T>{
    succeeded:boolean,
    closeIfsucceeded?:boolean,
    result?:T
}

export interface ContentComponentProps<TModel> {
    state: TModel,
    errors?:any,
    cancel:() => void,
    onOk: () => void,
    onInputChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    setState: (newModel: TModel) => void
}

function open<TModel, TResult>(Config: DialogConfig<TModel, TResult>): Promise<TResult | undefined> {
    let dialog = new Promise<TResult | undefined>((resolve) => {
         const _p = document.createElement('div');
         document.body.appendChild(_p);
         const root = createRoot(_p);
         root.render(
            <ThemeProvider theme={theme}>

<DialogComponent resolve={resolve} parent={root}
            {...Config}
             />
            </ThemeProvider>);
    });

    return dialog;
}

function DialogComponent<TModel, TResult>(props: PropsWithChildren<DialogComponentProps<TModel, TResult>>) {
    const [open, setOpen] = useState(true);
    const [model, setModel] = useState(props.inputModel ?? {} as TModel);

    const [errors, setErrors] = useState({});
    const { title, SubmitTitle, CancelTitle, resolvedResult, parent } = props;

    const onInputChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) =>{
        setModel(Helper.GetUpdatedState(model, e));
        setErrors(Helper.GetErrorsWithClearedField(errors, e));
    };

    const remove = () =>{
        setOpen(false);
        parent.unmount();
        //unmountComponentAtNode(parent);
        //document.body.removeChild(parent);
    };

    const cancel = () =>{
        props.OnCancel?.();
        props.resolve(undefined);
        remove();
    }
    

    const onOk = async (model: TModel) => {
        if(!props.validationNotNeeded)
        Helper.Execute({
            errors:errors,
            onFail:setErrors,
            onSucces: execute,
            schema:props.schema as Joi.Schema,
            state:model
        });
        else
        execute();
    }

    const execute = async () =>{
        const dialogResult = await resolvedResult(model);
        if(dialogResult.closeIfsucceeded && !dialogResult.succeeded)  return;
        props.resolve(dialogResult.result);
        remove();
    }

    const handleModalChange = (newModel:TModel) =>{
        const _model = {...model, ...newModel};
        setModel(_model);
    }

    return <Dialog open={open} color="primary">
        {title && <DialogTitle style={{backgroundColor:props.dialogColor}}>{title}</DialogTitle>}
        <props.Content cancel={cancel}
        onOk={() => onOk(model)}
        errors={errors}
        state={model}
        setState={handleModalChange}
        onInputChange={onInputChange}
        />
        <DialogActions style={{backgroundColor:props.dialogColor}}>
            {CancelTitle && <Button onClick={cancel} color='secondary' variant='outlined'><Close /></Button>}
            {SubmitTitle && <Button color='primary' variant='outlined' onClick={() => onOk(model)}><Check /></Button>}
        </DialogActions>
    </Dialog>;
}
export default open;