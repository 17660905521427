import React, { useState } from 'react';
import Joi from 'joi';
import { SocialAuth} from '../../features/social-auth';
import { Checkbox, Typography } from '@mui/material';
import AppButton from '../../shared/ui/appButton';
import * as _ from '../../shared/ui/AppFormHelper';
import localizationService from '../../services/localizationService';
import { useNavigate, Link } from 'react-router-dom';
import { routers } from '../../constants';
import model from './model';
import './index.css'
import '../forms.css'
import { AppMultyOptions } from '../../widgets/app-options';
import PasswordInput from '../password-input';
const schema = Joi.object({firstName:Joi.string().required().regex(/^\D*$/),
lastName:Joi.string().required().regex(/^\D*$/),
  contactInfo:Joi.string().required(),
    email:Joi.string().email({ tlds: { allow: false } }).required(),
    password:Joi.string().required().regex(/(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/).min(8),
    repeatedPassword:Joi.ref('password'),
    agreed:Joi.boolean().valid(true)
});

function RegisterForm(){
    const[state, setState] = useState({email:'', password:'',repeatedPassword:'',firstName:'',lastName:'', contactInfo:'', agreed:false});
    const[errors, setErrors] = useState({email:' ', password:' ',repeatedPassword:' ',firstName:' ',lastName:'', contactInfo:' ', agreed:' '});
    const [interestedSports, setInterestedSports] = useState<number[]>([]);
    const history = useNavigate();
    const register = () =>{
        model.Register({...state,interestedSports, name:`${state.firstName} ${state.lastName}`}, () => history(routers.confirmEmail.replace(":email", state.email), {replace:true}));
    };

    const onChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) =>{
        setState(_.GetUpdatedState(state, e));
        setErrors(_.GetErrorsWithClearedField(errors, e));
    };

    const onCheckBoxChanged = (e:React.ChangeEvent<HTMLInputElement> | undefined, checked:boolean) => {
        setState({...state, agreed:checked});
        setErrors({...errors, agreed:''});
    }

    return <form className='form'>
        <div className='form-content'>
        
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className='common-auth-fields'>
            <_.InputWithValidation textFieldProps={{placeholder:localizationService.Placeholders.Name.split(' ')[0], name:'firstName', style:{ marginBottom: 15 }, value:state.firstName, onChange:onChange}}
                                   errorMessage={errors.firstName}
            />
            <_.InputWithValidation textFieldProps={{placeholder:localizationService.Placeholders.Name.split(' ')[1], name:'lastName', style:{ marginBottom: 15 }, value:state.lastName, onChange:onChange}}
                                   errorMessage={errors.lastName}
            />
            </div>
            <_.InputWithValidation textFieldProps={{type:"email", placeholder:localizationService.Placeholders.Email, name:'email', style:{ marginBottom: 15 }, value:state.email, onChange:onChange}}
                                   errorMessage={errors.email}
            />
            <_.InputWithValidation textFieldProps={{placeholder:localizationService.Placeholders.ContactInfo, name:'contactInfo', style:{ marginBottom: 15 }, value:state.contactInfo, onChange:onChange}}
                                   errorMessage={errors.contactInfo}
            />
            <div className='common-auth-fields'>
                <PasswordInput
                errorMessage={errors.password} 
                name='password'
                onChange={onChange}
                password={state.password}
                hideHeader
                />
                <PasswordInput
                errorMessage={errors.repeatedPassword} 
                name='repeatedPassword'
                onChange={onChange}
                password={state.repeatedPassword}
                hideHeader
                />
            </div>
            <AppMultyOptions 
            optionType='Interested'
            onStateChanged={(option, added) =>{
                if(added) setInterestedSports([...interestedSports, option])
                else setInterestedSports(interestedSports.filter(x => x !== option))
            }}
            />
            <div className='terms-and-contions'>
                <Checkbox
                    checked={state.agreed}
                    onChange={onCheckBoxChanged}
                    name='agreed'
                    color="primary"
                />
                <p onClick={() => onCheckBoxChanged(undefined, !state.agreed)}>
                    {`${localizationService.AgreeWith} `}
                    <a href={routers.termsAndConditions}>{localizationService.TermsAndConditions}</a>
                    {` ${localizationService.And} `}
                    <a href={routers.privacyPolicy}>{localizationService.PrivacyPolicy}</a>
                </p>
            </div>
                <Typography color='secondary'>{errors.agreed}</Typography>
        </div>
        <AppButton disabled={_.HasErrors(errors) || interestedSports.length === 0} label={localizationService.Register} variant='contained' color='primary' onClick={() => _.Execute({
            errors:errors,
            onFail:setErrors,
            onSucces:register,
            schema:schema,
            state:state
        })} />
        
        <SocialAuth register />
        <div className='link-to'>
        <p>{localizationService.AlreadyAnAccount}</p>
        <Link to={routers.login}>{localizationService.Login}</Link>
        </div>
    </div>
    </form>
}

export default RegisterForm;