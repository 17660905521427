import { Button, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Player from '../../entities/player';
import { UserFeedbackView } from '../../features/skills';
import strings from '../../services/localizationService';
import model from './model';
import './index.css'
import PlayerForFeedback from '../../entities/playerForFeedback';
import { UsersGroupingView } from '../../widgets/users-grouping-view';
import { AppSelect } from '../../widgets/app-select';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import Loadable from '../../shared/ui/loadable';
import loading from '../../shared/ui/loadable/loading';
import SkillContext from '../../context/skillContext';
import { SportType } from '../../entities/enums';
import AppOptions, { AppOptionModel, AppOptionsWithSupprtedItems } from '../../widgets/app-options';
import FeedbackGroup from '../../entities/FeedbackGroup';

function FeedBackPage(){
    const [players, setPlayers] = useState<FeedbackGroup[]>([]);
    const [sport, setSport] = useState(SportType.ClassicVolleyball);
    const currentPlayers = players.find(x => x.sportType === sport || (x.sportType === SportType.BeachVolleyball && sport === SportType.ClassicVolleyball));
    useEffect(() => {
        model.getPlayers((result) =>{
            setPlayers(result);
            loading.stop('feedback');
        });
    }, [])
    const filterConditions = [{
        title:strings.Volleyball.NotEvaluated,
        condition:(x:PlayerForFeedback) => x.skills.find(y => y.level > 0) === undefined
    },
    {
        title:strings.Volleyball.Evaluated,
        condition:(x:PlayerForFeedback) => x.skills.find(y => y.level > 0) !== undefined
    }];

    const updateItem = (item:PlayerForFeedback) => {
        const existing = currentPlayers!.players.find(x => x.id === item.id);
        if(!existing) return;
        existing.skills = item.skills;
        setPlayers([...players]);
    }

    return <div className='feedback-page'>
        {players.length > 0 && <div className='sport-options'>
            <SportOptions setSport={setSport} sport={sport} supportedSports={players.map(x => x.sportType)} />
            </div>}
        <Loadable 
       View={() => <>
       {UsersGroupingView({groups:filterConditions, items:currentPlayers?.players ?? [], View:(x) => <EvaluatePlayerView
        item={x} 
        sport={currentPlayers?.sportType ?? 0}
        updateItem={updateItem}
        key={x.id}/>})}
       </>}
       id='feedback'
       screenCentered
       startOnRendering
       /> 
    </div>
}

function SportOptions({ setSport, supportedSports}:{sport:SportType, setSport:(sportType:SportType) => void, supportedSports:SportType[]}){
    const model = AppOptionModel('SportType');
    model.items = supportedSports.map(x => x <= SportType.BeachVolleyball ? 0 : x - 1);
    return <AppOptionsWithSupprtedItems state={model} 
    onStateChanged={(newState) => {
        setSport(newState === 0 ? newState : newState + 1);
    }} />
}

function EvaluatePlayerView({item, sport, updateItem}:{item:PlayerForFeedback,sport:SportType, updateItem:(item:PlayerForFeedback) => void}){
    return <div style={{width:"100%", display:"grid"}}>
    <AppSelect 
    View={(isOpen) => <div className='feedback-item-evaluation'><Typography>{strings.Volleyball.SkillsLabelReadOnly}</Typography>
    {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}</div>}
    doNotCloseOnOptionClick={true}
    OptionsView={() => <SkillContext.Provider value={{readonly:false, playerId:item.id, sportType:sport, skills:item.skills,
    updateSkills:(result) =>{
        item.skills.forEach(skill => {
            const exists = result.find(x => x.skill === skill.skill);
            if(exists)
            skill.level = exists.level;
        })
        updateItem({...item});
    }}} >
        <UserFeedbackView />
    </SkillContext.Provider>}
    />
    </div>
}
export default FeedBackPage;