import React, { useState } from 'react';
import Joi from 'joi';
import {SocialAuth} from '../../features/social-auth';
import { Typography } from '@mui/material';
import AppButton from '../../shared/ui/appButton';
import * as _ from '../../shared/ui/AppFormHelper';
import localizationService from '../../services/localizationService';
import { getCookieValue } from '../../shared/cookieToObject';
import Model from './model';
import './index.css'
import '../forms.css'
import { routers } from '../../constants';
import { Link } from 'react-router-dom';
import { isLocalHost } from '../../services/serverServices/http';
import PasswordInput from '../password-input';

const schema = Joi.object({email:Joi.string().required().email({ tlds: { allow: false } }),
password:Joi.string().required()});

function LoginForm(){
    const[state, setState] = useState({email:'', password:''});
    const[errors, setErrors] = useState({email:' ', password:' '});
    const emailConfirmed = getCookieValue("EmailConfirmed") === "True";

    const onChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) =>{
        setState(_.GetUpdatedState(state, e));
        setErrors(_.GetErrorsWithClearedField(errors, e));
    };

    const execute = () =>{
        if(isLocalHost)
        return Model.login({
            email:state.email,
            password:"Play-vo1leyba!l.app-pass"
        })

        return _.Execute({
            errors:errors,
            onFail:setErrors,
            onSucces:() => Model.login(state),
            schema:schema,
            state:state
         })
    }

    return <form className='form'>
        <div className='form-content'>
    {emailConfirmed && <Typography>{localizationService.EmailConfirmed}</Typography>}
    <div style={{ display: "flex", flexDirection: "column" }}>
        <_.InputWithValidation textFieldProps={{name:"email", type:"email",
        placeholder:localizationService.Placeholders.Email,
        value:state.email, onChange:onChange}}
                               errorMessage={errors.email}
                               header={localizationService.InputHeader.email}
                                />
                                <PasswordInput errorMessage={errors.password}
                                password={state.password}
                                onChange={onChange}
                                name='password'
                                />
        <a className='forgot-password' onClick={Model.onForgotPassword}>{localizationService.ForgotPassowrd}</a>
     </div>
     <AppButton disabled={_.HasErrors(errors)} label={localizationService.Login} variant='contained' color='primary' onClick={() => execute()} />
     
    {!emailConfirmed && <SocialAuth />}
    {!emailConfirmed && <div className='link-to'>
        <p>{localizationService.NoAccountYet}</p>
        <Link to={routers.register}>{localizationService.RegisterHere}</Link>
        </div>}
</div>
    </form>
}

export default LoginForm;