import { Button, Fab, MenuItem, Select, TextField, Typography, debounce } from "@mui/material";
import { Edit, Close, VerifiedUser, Check, Cancel } from "@mui/icons-material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GameAutoCancellation } from "../game-auto-cancellation";
import { GameState } from "../game-state";
import { RegisterGuest } from "../registerGuest";
import GameContext from "../../context/gameContext";
import strings from "../../services/localizationService";
import { ClubPayment, ClubScope, GuestsRegistrationStrategy, PaymentType } from "../../entities/enums";
import viewModels from "../../viewModels";
import dialogs from "../../components/dialogs";
import { OpenGuestRegistration } from "../open-guest-registration";
import ViewPayments from "../view-payments";
import { milisecondsInSecond, secondsInMinut } from "../../widgets/count-down";
import { DropDown } from "../../widgets/drop-down";
import AppOptions from "../../widgets/app-options";
import { InputWithValidation } from "../../shared/ui/AppFormHelper";
import { getTimeById } from "../../widgets/time-input";
import { toast } from "react-toastify";

function AdminOptions(){
    const {game} = useContext(GameContext);
    const [open,setOpen] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [canViewPayments, setCanViewPayments] = useState(!!game.fee && game.registeredPlayers.length > 0);
    
    const actionRef = useRef<HTMLDivElement>(null);
    const menuCotainerRef = useRef<HTMLDivElement>(null);

    useEffect(() =>{
        const time = setInterval(() => updateViewPayments(), milisecondsInSecond);
        return () => clearInterval(time);
    }, []);

    const updateViewPayments = () =>{
        const now = Date.now();
        const startGame = new Date(`${game.date.match(/\d{4}-\d{2}-\d{2}/)}T${game.start}`).valueOf();
        const endGame = new Date(`${game.date.match(/\d{4}-\d{2}-\d{2}/)}T${game.end}`).valueOf() + milisecondsInSecond * secondsInMinut * 30;
        setCanViewPayments(now >= startGame && now <= endGame && game.registeredPlayers.length > 0)
    }

    const onAction = () =>{
        if(isExecuting) return;
        setIsExecuting(true);
        menuCotainerRef.current!.style.display ="flex";
        setTimeout(() => {
        const newState = !open;
        setOpen(newState);
        actionRef.current!.classList.toggle("open");
        menuCotainerRef.current!.classList.toggle("open"); 
        }, 100);      
    }
    const onTransionEnded = () =>{
        if(!menuCotainerRef.current!.classList.contains("open"))
        {
            menuCotainerRef.current!.style.display ="none";
            setEditMode(false);
        }
        setIsExecuting(false);
    }



return <div className="admin-menu">
    <div className="action" ref={actionRef} onClick={onAction}>
    {!open && <Edit />}
    {open && <Close />}
    </div>
    <div className="admin-menu container" onTransitionEnd={onTransionEnded}
    ref ={menuCotainerRef}>
        {!editMode && <>{!game.cancelled && <>
        {game.guestsRegistrationStrategy === GuestsRegistrationStrategy.Manual && <OpenGuestRegistration />}
        {canViewPayments && <ViewPayments />}
        <Button variant="contained" color="primary" onClick={(e) => {setEditMode(true)}}>{strings.Modify} {strings.ClubScopes[0]}</Button>
        
        <Button variant="contained" color="primary" onClick={() => viewModels.game.ChargeFee(game)}>{strings.ChargeFee}</Button>
        <GameAutoCancellation />
        </>}
        <GameState /></>}
        {editMode && <ModifyGame closeEdit={() => setEditMode(false)} />}
        </div>
</div>
}

function ModifyGame({closeEdit}:{closeEdit:() => void}){
    const {game, updateGame} = useContext(GameContext);
    const [scope, setScope] = useState(game.scope);
    const [minPlayers, setMinPlayers] = useState(game.minPlayers);
    const [maxPlayers, setMaxPlayers] = useState(game.maxPlayers);
    const [fee, setFee] = useState(game.clubFee);
    const [feeType, setFeeType] = useState(game.paymentType);
    const gameClubType = game.feeIsFixed && (!game.fee || game.fee === 0) ? ClubPayment.Free : (game.feeIsFixed ? ClubPayment.PerPerson : ClubPayment.PerGame);
    const [clubFeeType, setClubFeeType] = useState(gameClubType);
    
    const changed = game.scope !== scope || game.minPlayers !== minPlayers || game.maxPlayers !== maxPlayers
    || game.clubFee !== fee || game.paymentType !== feeType || gameClubType !== clubFeeType;
    const close = async () =>{
        let confirm = !changed || await dialogs.Confirmation("Changes will be discarded - are you sure?");
        if(confirm) closeEdit();
    }

    const update = async () =>{
        const newState = {...game, scope, minPlayers, maxPlayers, fee, paymentType:feeType, feeIsFixed:clubFeeType !== ClubPayment.PerGame};
        await viewModels.game.update(newState, (result) =>{
            closeEdit();
            newState.registeredPlayers = result.registeredPlayers;
            newState.fee = result.fee ?? newState.fee;
            newState.feeOnMaxPlayers = result.feeOnMaxPlayers;
            updateGame({...newState});
            toast.info(strings.Placeholders.OperationCompleted);
        })
    }

    return <>
<Select
    value={scope}
    label={strings.Scope}
style={{width:"100%"}}
    onChange={(e) => setScope(e.target.value as ClubScope)}
>
    {strings.ClubScopes.map((value, id) => <MenuItem key={id} value={id}>{value}</MenuItem>)}
</Select>
<TextField type="number"  defaultValue={minPlayers} inputProps={{min:1, max:maxPlayers}}
label={strings.MinPlayers}
style={{width:"100%"}}
onChange={(e) => setMinPlayers(+e.target.value)}
 />
 <TextField type="number"  defaultValue={maxPlayers} inputProps={{min:4, max:36}}
label={strings.MaxPlayers}

style={{width:"100%"}}
onChange={(e) => setMaxPlayers(+e.target.value)}
 />
 <DropDown title={strings.Fee}>
<><AppOptions defaultValue={clubFeeType} optionType="Payment" onStateChanged={(index) => {
            setClubFeeType(index);
          }} />
          
         {clubFeeType !== ClubPayment.Free && 
         <>
         <InputWithValidation
         errorMessage={''}
         textFieldProps={{ type:"number",
         defaultValue:fee,
        placeholder:clubFeeType === ClubPayment.PerPerson ? strings.FeePerPlayer : strings.FeePerGame,
    name:'fee',
    onChange:(e) =>{
        setFee(+e.currentTarget.value);
    }
 }}
          />
          <AppOptions optionType="PaymentType" defaultValue={feeType} 
          onStateChanged={(index) =>{
            setFeeType(index);
          }}
          />
          </>}
</>
          </DropDown>
 <><div className="edit-buttons-container">
    <Button color="secondary" variant="contained" onClick={close}><Close /></Button>
    <Button color="primary" variant="contained" disabled={!changed} onClick={update}><Check /></Button>
    </div></>
    </>
}

export default AdminOptions;